import { FC } from 'react';
import { commentApi } from '../../../api/comment';
import Quill from '../../ui/components/Quill';
import { IPostCommentsUser } from '../../../api/dtos/comment';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import CaseComment from './CaseComment';
import { TOPBAR_HEIGHT, DASHBOARD_TABS_HEIGHT } from '../../../utils/constants/constants';

interface CaseCommentsProps {
  id: number;
}

const CaseComments: FC<CaseCommentsProps> = ({ id }) => {
  const { data } = useQuery(['commentApi', id, 'getComments'], () =>
    commentApi.getComments({
      id,
      type: 'case',
    })
  );
  const results = data?.data?.results?.slice().reverse();

  const queryClient = useQueryClient();

  const { mutate } = useMutation(commentApi.addComment, {
    onSuccess: () => {
      queryClient.invalidateQueries(['commentApi', id, 'getComments']);
    },
  });

  const onSubmit = (value: string, files: FileList, users: IPostCommentsUser[]) => {
    mutate({
      id,
      type: 'case',
      body: value,
      files,
      context: {
        users,
      },
    });
  };

  // const queryClient = useQueryClient();
  return (
    <div
      className='my-4 mr-2 flex w-1/3 flex-col justify-between rounded-md border'
      style={{
        maxHeight: `calc(100vh - ${TOPBAR_HEIGHT} - ${DASHBOARD_TABS_HEIGHT}`,
      }}>
      <div className='rounded-md bg-gray-50 px-5 py-3.5 text-xs text-gray-500'>
        Comments ({results?.length})
      </div>
      {results?.length ? (
        <div className='mt-auto flex min-h-80 flex-col overflow-auto'>
          {results?.map((c) => <CaseComment comment={c} key={c.id} />)}
        </div>
      ) : (
        <div className='h-80 p-5 text-xs text-gray-500'>No comments added yet</div>
      )}
      <Quill id={id} onSubmit={onSubmit} type={'caseUsers'} />
    </div>
  );
};

export default CaseComments;
