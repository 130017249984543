import { FC } from 'react';
import { useIntegrationsGet } from '../../../../api/integration';
import CompassLoader from '../../../../components/ui/components/Loader/CompassLoader';

import Integration from '../../../../components/Settings/Integrations/Integration';
import { TabHeader } from '../../../../components/ui/components/Header';
import { getIntegrationData } from '../../../../utils/helpers/integrations';
import { useAuth } from '../../../../modules/auth';

const Integrations: FC = () => {
  const { data, isLoading } = useIntegrationsGet();
  const { state } = useAuth();

  const integrations = getIntegrationData(data?.data?.results, state.accountPermissions?.fireblocks_enabled);

  return (
    <div className='text-sm font-normal leading-default'>
      <TabHeader heading='Integrations' />

      <span className='text-gray-500'>
        Connect your tools with Merkle Science to make your workflow easier
      </span>
      {isLoading ? (
        <div>
          <CompassLoader />
        </div>
      ) : (
        <div className='mt-4 flex justify-center gap-6'>
          {integrations.map((integration) => (
            <Integration key={integration.provider} integration={integration} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Integrations;
