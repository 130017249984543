import { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { alertApi } from '../../../../api/alert';
import ResolveAlertModal from './AlertButtonPanel/ResolveAlertModal';
import { IPostCommentsContext } from '../../../../api/dtos/comment';
import { Button } from '../../../../ui';
import { Check } from '@phosphor-icons/react';

interface IResolveAllAlertsProps {
  ids: string[];
  type: 'address' | 'transaction' | 'customer';
  onResolve?: () => void;
}

const ResolveAllAlerts: FC<IResolveAllAlertsProps> = (props) => {
  const { ids, type, onResolve: onResolveProp } = props;

  const [open, setOpen] = useState(false);

  const { mutate } = useMutation(alertApi.updateAlerts);

  const onResolve = (data: { comment: string; context: IPostCommentsContext }) => {
    mutate(
      { ids, comment: data.comment, context: data.context, status: 1, type },
      {
        onSuccess: () => {
          onResolveProp && onResolveProp();
        },
      }
    );
  };

  return (
    <>
      <ResolveAlertModal
        id={ids[0]}
        open={open}
        onResolve={(data) => {
          setOpen(false);
          onResolve(data);
        }}
        type={type}
        count='all'
      />
      <Button onClick={() => setOpen(true)} variant='tertiary' className='mr-2'>
        <Check size={14} className='mr-1' />
        Resolve all alerts
      </Button>
    </>
  );
};

export default ResolveAllAlerts;
