import { FC, useState } from 'react';
import { TabHeader } from '../../../../components/ui/components/Header';
import { Button, Toggle } from '../../../../ui';
import { DownloadSimple, Plus } from '@phosphor-icons/react';
import Table from '../../../../ui/components/Table/Table';
import { Search } from '../../../../components/ui/components/Search';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../../utils/helpers/react-query.helper';
import { customTagsApi } from '../../../../api/settings/customTags';
import { formatDateTime } from '../../../../utils/helpers/date';
import { useAuth } from '../../../../modules/auth';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { getErrorMessage, getNextPageParam } from '../../../../utils/helpers/helperFunctions';
import CustomTagsModal from '../../../../components/Settings/CustomTags/CustomTagsModal';
import { ICustomTagResult } from '../../../../api/dtos/settings/customTags';

const CustomTags: FC = () => {
  const [search, setSearch] = useState<string>('');
  const [modalType, setModalType] = useState<'add' | 'edit' | null>(null);
  const [customTag, setCustomTag] = useState<ICustomTagResult | null>(null);

  const { state } = useAuth();
  const key = ['customTagsApi.getAddresses', search];
  const customTagsQuery = useInfiniteQuery(
    key,
    ({ pageParam }) =>
      customTagsApi.getCustomTags({ q: search, offset: pageParam, is_in_custom_list: false }),
    {
      getNextPageParam,
    } as unknown
  );

  const [count, results] = flattenInfiniteQueryResult(customTagsQuery.data);
  const queryClient = useQueryClient();

  const onError = (error) => {
    toast.error(getErrorMessage(error));
  };
  const { mutate: toggleCustomTag, isLoading: isLoadingToggleTag } = useMutation(
    customTagsApi.toggleCustomTag,
    {
      onSuccess: () => {
        toast.success('Custom Tag has been updated');

        queryClient.invalidateQueries(['customTagsApi.getAddresses']);
      },
      onError,
    }
  );

  const { mutate: exportCustomTags } = useMutation(customTagsApi.exportCustomTags, {
    onSuccess: (data) => {
      toast.success(data.data.message);
    },
  });

  const onToggleTag = (id: number, isActive: boolean) => {
    toggleCustomTag({ id, isActive });
  };

  const rows = count
    ? results?.map((i) => {
        return {
          id: i.id,
          data: [
            i.name,
            i.description,
            i.added_by,
            formatDateTime(i.created_at, state.userProfile.timezone),
            <Toggle
              key={i.id}
              enabled={i.is_active}
              setEnabled={() => {
                onToggleTag(i.id, !i.is_active);
              }}
              labelText={''}
            />,
            <Button
              variant='tertiary'
              key={i.id}
              onClick={() => {
                setCustomTag(i);
                setModalType('edit');
              }}>
              Edit
            </Button>,
          ],
        };
      })
    : [];

  const headerData = ['Name', 'Description', 'Added By', 'Date Added', 'Status', 'Action'];

  return (
    <div className='h-full max-w-6xl'>
      <TabHeader
        heading='Custom Tags'
        subheading='Custom tags can be added to individual addresses via the address detail page. Adding a tag to an address will only run rules created for the tag and bypass all other existing rules.'
      />
      <div className='flex justify-between rounded-t-md border border-gray-200 p-4'>
        <Search
          className='w-2/4 bg-gray-50'
          placeholder='Search for tag'
          value={search}
          setValue={setSearch}
        />
        <div>
          <Button
            iconStart={<Plus size={16} />}
            onClick={() => setModalType('add')}
            className='mr-4'
            variant='primary'>
            Custom Tag
          </Button>
          <Button
            iconStart={<DownloadSimple size={16} />}
            onClick={() => exportCustomTags()}
            variant='secondary'>
            Export Tags
          </Button>
        </div>
      </div>

      <Table
        className={`rounded-b-md border border-gray-200 ${isLoadingToggleTag ? 'pointer-events-none' : ''}`}
        isHeaderVisible={false}
        headerData={headerData}
        rows={rows}
        isLoading={customTagsQuery.isLoading}
        {...buildInfiniteQueryTableProps(customTagsQuery)}
      />
      <CustomTagsModal type={modalType} onClose={() => setModalType(null)} customTag={customTag} />
    </div>
  );
};

export default CustomTags;
