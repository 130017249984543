import { FC, useRef, useState } from 'react';
import { DownloadSimple } from '@phosphor-icons/react';
import { BasicModal, Button } from '../../ui';
import { useForm } from 'react-hook-form';
import { addressApi } from '../../api/address';
import { useMutation } from 'react-query';
// import saveUrlToFile from '../../utils/helpers/saveBlobToFile';
import saveBlobToFile from '../../utils/helpers/saveBlobToFile';
import CheckboxController from '../../ui/components/Form/CheckboxController';
import { capitalize, startCase } from 'lodash';
import { IAddressReportEntity } from '../../api/dtos/address';
import { toast } from '../../ui/components/Toast';
import { ITransactionReportEntity } from '../../api/dtos/transaction';
import { ICustomerReportEntity } from '../../api/dtos/customer';
import { transactionApi } from '../../api/transaction';
import { useAuth } from '../../modules/auth';
import { customerApi } from '../../api/customer';
import { caseApi } from '../../api/case';
import { ICaseReportEntity } from '../../api/dtos/case';

interface ExportReportProps {
  id: number | string;
  type: 'address' | 'transaction' | 'customer' | 'case';
}

type addressInitialDataType = Record<IAddressReportEntity, boolean>;

type transactionInitialDataType = Record<ITransactionReportEntity, boolean>;

type customerInitialDataType = Record<ICustomerReportEntity, boolean>;

type caseInitialDataType = Record<ICaseReportEntity, boolean>;

const addressInitialData: addressInitialDataType = {
  addressDetails: true,
  flowAnalysis: true,
  counterpartySummary: true,
  openAlerts: true,
  resolvedAlerts: true,
  addressComments: true,
  aggregatedTransactions: true,
};

const transactionInitialData: transactionInitialDataType = {
  transactionDetails: true,
  flowAnalysis: true,
  counterpartySummary: true,
  openAlerts: true,
  resolvedAlerts: true,
  transactionComments: true,
};

const customerInitialData: customerInitialDataType = {
  customerDetails: true,
  flowAnalysis: true,
  addressSummary: true,
  deposits: true,
  withdrawals: true,
  customerComments: true,
  associatedEntities: true,
  openAlerts: true,
  resolvedAlerts: true,
};
const caseInitialData: caseInitialDataType = {
  caseDetails: true,
  addressesList: true,
  comments: true,
  customersList: true,
  transactionsList: true,
  associatedCases: true,
};

const ExportReport: FC<ExportReportProps> = ({ id, type }) => {
  const [open, setOpen] = useState(false);
  const { state } = useAuth();

  // const { control, handleSubmit } = useForm<typeof initialData>({
  //   defaultValues: initialData,
  // });

  const initialDataMap = {
    address: addressInitialData,
    transaction: transactionInitialData,
    customer: customerInitialData,
    case: caseInitialData,
  };

  const initialData = initialDataMap[type];

  const { control, handleSubmit, reset } = useForm<
    addressInitialDataType | transactionInitialDataType | customerInitialDataType | caseInitialDataType
  >({
    defaultValues: initialData,
  });

  const toastId = useRef(null);
  const onSuccessApi = (data) => {
    saveBlobToFile(data.data, `Merkle Science - ${capitalize(type)} - ${id}.pdf`);
    toast.update(toastId.current, {
      render: 'Report downloaded successfully.',
      type: 'success',
      autoClose: 3000,
    });
    reset();
    setOpen(false);
  };

  const onErrorApi = () => {
    toast.update(toastId.current, {
      render: 'Error downloading report. Please try again later.',
      type: 'error',
      autoClose: 3000,
    });
  };

  const { mutate: getAddressReport } = useMutation(addressApi.getAddressReport, {
    onSuccess: onSuccessApi,
    onError: onErrorApi,
  });

  const { mutate: getTransactionReport } = useMutation(transactionApi.getTransactionReport, {
    onSuccess: onSuccessApi,
    onError: onErrorApi,
  });

  const { mutate: getCustomerReport } = useMutation(customerApi.getCustomerReport, {
    onSuccess: onSuccessApi,
    onError: onErrorApi,
  });

  const { mutate: getCaseReport } = useMutation(caseApi.getCaseReport, {
    onSuccess: onSuccessApi,
    onError: onErrorApi,
  });

  type ReportEntity = IAddressReportEntity &
    ITransactionReportEntity &
    ICustomerReportEntity &
    ICaseReportEntity;

  const onSubmit = (data: Partial<Record<ReportEntity, boolean>>) => {
    toastId.current = toast.success('Your file is being prepared. Please wait for a few seconds', {
      autoClose: false,
    });
    const selectedEntities = Object.keys(data).filter((key) => data[key]) as ReportEntity[];
    const reportData = { id, entities: selectedEntities };

    // Call the appropriate API endpoint based on the report type
    const reportFunctionMap = {
      address: getAddressReport,
      transaction: getTransactionReport,
      customer: getCustomerReport,
      case: getCaseReport,
    };

    const reportFunction = reportFunctionMap[type];
    reportFunction(reportData);

    // Needed for Development
    if (import.meta.env.DEV) {
      let route = '';
      const query = {
        token: state.access,
        entities: selectedEntities.join(','),
        tz: state.userProfile.timezone,
      };
      if (type === 'address') {
        route = `${window.location.origin}/pdf/address-report/${id}?${new URLSearchParams(query)}`;
      } else if (type === 'transaction') {
        route = `${window.location.origin}/pdf/transaction-report/${id}?${new URLSearchParams(query)}`;
      } else if (type === 'customer') {
        route = `${window.location.origin}/pdf/customer-report/${id}?${new URLSearchParams(query)}`;
      } else if (type === 'case') {
        route = `${window.location.origin}/pdf/case-report/${id}?${new URLSearchParams(query)}`;
      }
      // consoles only in development
      // eslint-disable-next-line no-console
      console.log(route);
    }
    setOpen(false);
  };

  return (
    <>
      <Button variant='tertiary' className='my-auto ml-2 px-3' onClick={() => setOpen(true)}>
        <DownloadSimple size={16} />
      </Button>
      <BasicModal
        open={open}
        onClose={() => setOpen(false)}
        modalTitle={`Export ${capitalize(type)} Report`}
        size='md'
        modalBody={
          <form onSubmit={handleSubmit(onSubmit)} className=''>
            <p className='mb-5 text-gray-500'>Select entities to be included in the report</p>
            <div className='grid grid-cols-2'>
              {Object.keys(initialData)
                .filter((key) => key !== 'aggregatedTransactions')
                .map((key) => (
                  <CheckboxController
                    key={key}
                    name={key as ReportEntity}
                    control={control}
                    labelText={startCase(key)}
                    labelClassName='text-base'
                    className='mb-4'
                  />
                ))}
            </div>

            <div className='flex justify-end'>
              <div className='flex justify-end'>
                <Button variant='tertiary' onClick={() => setOpen(false)}>
                  Cancel
                </Button>
                <Button type='submit' variant='primary' className='ml-2'>
                  Generate Report
                </Button>
              </div>
            </div>
          </form>
        }
        modalFooter={null}
        customFooterClass='hidden'
      />
    </>
  );
};

export default ExportReport;
