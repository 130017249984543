import { FC } from 'react';
import { IAgentPerformance } from '../../api/dtos/dashboard';
import DashboardWidget from './DashboardWidget';
import Table from '../../ui/components/Table/Table';
import { formatDurationHelper } from '../../utils/helpers/date';

interface IAgentPerformanceWidgetProps {
  agentsData: IAgentPerformance[];
  isLoading?: boolean;
  isPdf?: boolean;
}

const AgentPerformanceWidget: FC<IAgentPerformanceWidgetProps> = ({ agentsData, isLoading, isPdf }) => {
  const headers = [
    'Name',
    'Cases Assigned',
    'Cases Closed',
    'Cases Open',
    'Cases Escalated',
    'Avg. Closure Time',
  ];

  const rows = agentsData?.map((a) => ({
    id: a.id,
    data: [
      <div key={a.id}>
        <div className='text-sm font-medium'>{a.name}</div>
        <div className='text-gray-500'>{a.email}</div>
      </div>,
      <div key={a.id} className='text-center text-blue-500'>
        {a.case_status_counts.total}
      </div>,
      <div key={a.id} className='text-center text-blue-500'>
        {a.case_status_counts[2]}
      </div>,
      <div key={a.id} className='text-center text-blue-500'>
        {a.case_status_counts[1]}
      </div>,
      <div key={a.id} className='text-center text-blue-500'>
        {a.case_status_counts[3]}
      </div>,
      formatDurationHelper(a.average_case_closure_time),
    ],
  }));

  return (
    <DashboardWidget
      title={'Analyst Performance (Cases)'}
      // eslint-disable-next-line tailwindcss/enforces-shorthand
      className='px-0 py-0'
      containerClassName={!isPdf ? 'col-span-6' : 'col-span-12'}
      widgetHeader={
        <div className='ml-6 mr-auto rounded-xl bg-gray-100 px-3 py-0.5 text-xs font-medium'>
          Total: {agentsData?.length}
        </div>
      }>
      <div className='mb-2'>
        <Table
          headerData={headers}
          rows={rows}
          height={!isPdf ? 350 : 800}
          className='rounded-b-lg'
          isHeaderVisible={false}
          isLoading={isLoading}
        />
      </div>
    </DashboardWidget>
  );
};

export default AgentPerformanceWidget;
