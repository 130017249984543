const ReportFooter = ({ children }) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer id='footer' className='flex items-center justify-between border-t-2 pt-4 text-gray-600'>
      {children}
      <span>
        <span className='text-3xs'>{`© Merkle Science ${currentYear}`}</span>
      </span>
    </footer>
  );
};

export default ReportFooter;
