import { FC } from 'react';
import { BasicModal } from '../../../../../ui';
import Quill from '../../../../ui/components/Quill';
import { IPostCommentsContext } from '../../../../../api/dtos/comment';

interface ResolveAlertModalProps {
  open: boolean;
  onResolve: ({ comment, context }: { comment: string; context: IPostCommentsContext }) => void;
  count?: number | 'all';
  type?: 'address' | 'transaction' | 'customer';
  id: string | number;
}

const ResolveAlertModal: FC<ResolveAlertModalProps> = (props) => {
  const { open, onResolve, count, type, id } = props;

  const onSubmit = (comment, _file, users) => {
    onResolve({
      comment,
      context: {
        users,
      },
    });
  };

  const getModalTitle = () => {
    if (count === 'all') {
      return 'Resolve all alerts';
    }
    return 'Resolve alert';
  };

  return (
    <BasicModal
      open={open}
      onClose={() => {
        onResolve(null);
      }}
      size='sm'
      modalTitle={getModalTitle()}
      modalBody={
        <>
          <div className='mb-4 px-4 text-sm font-medium'>Add Comment (optional)</div>
          <Quill id={id} onSubmit={onSubmit} isResolveAlert type={type} />
        </>
      }
      customBodyClass='pt-6'
    />
  );
};

export default ResolveAlertModal;
