import { FC } from 'react';
import SettingsTabs from '../../../../components/Settings/Tabs';

const OrganizationSettings: FC = () => {
  // const { state } = useAuth();
  // const canEnableMonitoring = state?.userProfile?.can_enable_continuous_monitoring;

  const tabs = [
    {
      type: 'Organisation Settings',
      tabs: [
        {
          name: '',
          label: 'Organisation Members',
          tabs: [
            {
              name: 'members',
              label: 'Members',
            },
            {
              name: 'workspaces',
              label: 'Workspaces',
            },
          ],
        },
        {
          name: '',
          label: 'Billing',
          isHiddenAgent: true,
          tabs: [
            { name: 'current-plan', label: 'Current Plan' },
            { name: 'usage', label: 'Usage' },
          ],
        },
      ],
    },
  ];

  return <SettingsTabs tabs={tabs} type='organisation' />;
};

export default OrganizationSettings;
