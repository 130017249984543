import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { AxiosError } from 'axios';

import { useAuth } from '../../modules/auth';
import { useRouter } from '../../modules/router/RouterProvider';

import { getCurrencyShortValue, getDigitalAssets, isLiteCurrency } from '../../utils/helpers/currency';

import Sidebar from '../ui/components/Sidebar';
import Tab from '../ui/components/Tab/Tab';
import DigitalAssets from '../ui/components/Currency/DigitalAssets';
import Card from '../ui/components/Card/Card';
import CompassLoader from '../ui/components/Loader/CompassLoader';
import IdentifierEllipsis from '../ui/components/Currency/IdentifierEllipsis';
import EmptyState from '../../ui/components/States/Empty';
import { Button, RiskBadge } from '../../ui';
import { useAddressGetAddressLite } from '../../api/addressPreview';
import CurrencyValue from '../ui/components/Currency/CurrencyValue';
import EntityWithSubtypeBadge from '../ui/components/EntityTypeBadge';
import { useScreenApi } from '../../utils/helpers/apiHelpers';
import AddressSummary from './Overview/AddressSummary';
import AddressTransactions from './AddressTransactions';
import CounterpartySummary from '../AddressTransaction/CounterpartySummary';
import InvestigationTree from '../AddressTransaction/InvestigationTree/InvestigationTree';
import { MonitoringLabel, MonitoringValue } from './AddressMetadata/AddressMonitoring';

interface IAddressPreviewProps {
  id: string;
  currency: number;
  open: boolean;
  setOpen: (val: boolean) => void;
}

const AddressPreview: FC<IAddressPreviewProps> = ({ id, currency, open, setOpen }) => {
  const { state } = useAuth();
  const { navigate, showErrorPage } = useRouter();
  const screenApi = useScreenApi();
  const tabs = [
    { label: 'Overview' },
    {
      label: 'Address Summary',
    },
    {
      label: 'Transactions',
    },
    {
      label: 'Counterparty Summary',
    },
    {
      label: 'Investigation mode',
    },
  ];
  const { data: response, isLoading: detailLoading } = useAddressGetAddressLite(id, currency, {
    onError: (error: AxiosError) => {
      if (error.response.status === 404) {
        if (showErrorPage) showErrorPage({ status: 404 });
      }
    },
    enabled: open,
  });
  const address = response?.data;
  const metaDetails =
    !detailLoading && address && open
      ? [
          { label: 'Risk Level', value: <RiskBadge risk={address.risk_level} /> },
          {
            label: <MonitoringLabel />,
            value: <MonitoringValue address={address} />,
          },
          {
            label: 'Digital Asset',
            value: <DigitalAssets digitalAssets={getDigitalAssets(address)} noBadge />,
          },
          ...(!isLiteCurrency(address.currency)
            ? [
                {
                  label: 'Balance',
                  value: (
                    <CurrencyValue
                      value={address.balance}
                      currency={getCurrencyShortValue(address.currency_short)}
                    />
                  ),
                },
              ]
            : []),
          {
            label: 'Owner',
            value: address.tag_name_verbose_owner,
            isHidden: !address.tag_name_verbose_owner,
          },
          {
            label: 'Owner Type',
            value: (
              <EntityWithSubtypeBadge
                type={address.tag_type_verbose_owner}
                subtype={address.tag_subtype_verbose_owner}
              />
            ),
            isHidden: !address.tag_type_verbose_owner,
          },
          {
            label: 'User',
            value: (
              <div title={address.tag_name_verbose} className='truncate'>
                {address.tag_name_verbose}
              </div>
            ),
            isHidden: !address.tag_name_verbose,
          },
          {
            label: 'User Type',
            value: (
              <EntityWithSubtypeBadge type={address.tag_type_verbose} subtype={address.tag_subtype_verbose} />
            ),
            isHidden: !address.tag_name_verbose,
          },
          {
            label: 'Customer ID',
            value: (
              <div
                className='cursor-pointer break-all text-blue-600 underline'
                onClick={() => navigate(`/customers/${address.customer_id}`)}>
                {address.customer_id}
              </div>
            ),
            isHidden: !address.customer_id,
          },
          {
            label: 'Type',
            value: address.type_verbose,
            isHidden: !address.type_verbose,
          },
        ]
      : [];

  useEffect(() => {
    if (state.screeningLoader) {
      setOpen(false);
    }
  }, [setOpen, state.screeningLoader]);

  const handleScreen = () => {
    screenApi({
      entityType: 'addresses',
      identifier: address.identifier,
      currency: address.currency,
    });
  };

  return (
    <Sidebar
      title='Address Preview'
      isOpen={open}
      onClose={() => setOpen(false)}
      titleClass='!py-2'
      childrenClass='!my-2 !py-0'
      maxWidthClass='max-w-5xl'>
      {detailLoading ? (
        <CompassLoader />
      ) : address ? (
        <Tab
          values={tabs}
          type='secondary'
          listClassName={classNames('border-b border-gray-200 pb-2 sticky z-30 top-0 !mt-0')}
          panelClassName='py-2'>
          <div className='flex h-[400px] flex-col justify-between'>
            <div className='bg-gray-50 px-4 py-2'>
              <div className='flex flex-col justify-between'>
                <IdentifierEllipsis
                  identifier={address?.identifier}
                  limit={16}
                  className='text-xl text-gray-800'
                  copyable
                />
                <div className='flex text-wrap break-all text-sm text-gray-500'>{address?.identifier}</div>
              </div>
              <hr className='mt-4' />
              {metaDetails?.map((metaDetail, i) =>
                metaDetail.isHidden ? null : (
                  <div key={i} className='my-5 grid grid-cols-3'>
                    <span className='my-auto text-2xs uppercase text-gray-500'>{metaDetail.label}</span>
                    <span className='col-span-2 my-auto text-xs font-semibold'>{metaDetail.value}</span>
                  </div>
                )
              )}
            </div>
            <div className='flex flex-col items-center text-sm'>
              <Button
                type='submit'
                variant='primary'
                className='mt-4 w-fit self-center'
                onClick={handleScreen}>
                Screen Address
              </Button>
            </div>
          </div>
          <AddressSummary address={address} isPreview />
          <AddressTransactions address={address} isPreview />
          <CounterpartySummary
            address={address}
            type={'address'}
            popover
            maxHeightClass='max-h-[450px]'
            showModes={false}
          />
          <Card title='INVESTIGATION MODE' containerClassName='border border-gray-200'>
            <InvestigationTree
              originType='address'
              identifier={address?.identifier}
              address={address}
              popover
            />
          </Card>
        </Tab>
      ) : (
        <EmptyState />
      )}
    </Sidebar>
  );
};

export default AddressPreview;
