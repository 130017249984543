import { FC, useState } from 'react';
import CheckboxController from '../../../../ui/components/Form/CheckboxController';
import { TabHeader } from '../../../../components/ui/components/Header';
import { useForm, useWatch } from 'react-hook-form';

import { useMutation, useQueryClient } from 'react-query';

import { AxiosError, AxiosResponse } from 'axios';
import { IErrorResponse } from '../../../../api/dtos/common';
import { toast } from 'react-toastify';
import {
  useUserContinuousMonitoringLevelGet,
  useUserMonitoringPreferenceGet,
  monitoringPreferencesApi,
} from '../../../../api/settings/monitoringPreferences';
import {
  IContinuousMonitoringUpdateRequest,
  IUserMonitoringPreferenceContinuousMonitoringResponse,
  IUserMonitoringPreferenceResponse,
} from '../../../../api/dtos/settings/monitoringPreferences';
import MonitoringTable from '../../../../components/Settings/Monitoring/MonitoringTable';
import RadioButtonsController from '../../../../ui/components/Form/RadioButtonsController';
import { RadioButton } from '../../../../ui';
import CompassLoader from '../../../../components/ui/components/Loader/CompassLoader';
import { getErrorMessage } from '../../../../utils/helpers/helperFunctions';
import SingleSelect, { IOption } from '../../../../components/ui/components/Select/SingleSelect';
import classNames from 'classnames';
import { useAuth } from '../../../../modules/auth';

type ContinuousMonitoringType = 0 | 1 | 2;
interface MonitoringPreferenceProps {}
const initialData = {
  monitoring: 1 as ContinuousMonitoringType,
  riskLevels: {
    '1': false,
    '2': false,
    '3': false,
    '4': false,
    '5': false,
  },
};

const MonitoringPreference: FC<MonitoringPreferenceProps> = () => {
  const queryClient = useQueryClient();
  const [currentMonitoringLevel, setCurrentMonitoringLevel] = useState<0 | 1 | 2>();
  const [isMonitoringTableLoading, setIsMonitoringTableLoading] = useState(false);

  const [duration, setDuration] = useState<IOption>(null);

  const { control, setValue } = useForm<typeof initialData>({
    defaultValues: initialData,
  });

  const { state } = useAuth();
  const canEnableMonitoring = state?.userProfile?.can_enable_continuous_monitoring;

  const monitoringLevel = useWatch({
    control,
    name: 'monitoring',
  });

  const { data: autoResolveAlertsData, isFetching: isPreferenceLoading } = useUserMonitoringPreferenceGet();
  const autoResolveAlerts = autoResolveAlertsData?.data?.levels || [];
  const { isFetching: isMonitoringLoading } = useUserContinuousMonitoringLevelGet({
    onSuccess: (data: AxiosResponse<IUserMonitoringPreferenceContinuousMonitoringResponse>) => {
      setValue('monitoring', data?.data?.continuous_monitoring_level);
      const monitoringDays = data?.data?.continuous_monitoring_days;
      const days = !monitoringDays ? 'Indefinitely' : `${monitoringDays} Days`;
      setDuration({
        label: days,
        value: monitoringDays ? monitoringDays.toString() : null,
      });
      setCurrentMonitoringLevel(data.data.continuous_monitoring_level);
    },
  });

  const { mutate } = useMutation(monitoringPreferencesApi.updateAutoResolveAlerts, {
    onSettled: () => {
      queryClient.invalidateQueries(['ProfileApi.getMonitoringPreference']);
    },
    onSuccess: () => {
      toast.success('Auto resolve alerts preferences updated successfully');
    },
    onError: (error: AxiosError<IErrorResponse>) => {
      toast.error(error.response.data.detail);
    },
  });
  const continuousMonitoring = useMutation(monitoringPreferencesApi.updateContinuousMonitoringSettings);

  const onChangeMonitoring = (e) => {
    const request: IContinuousMonitoringUpdateRequest = {
      continuous_monitoring_level: e.target.value,
    };

    setIsMonitoringTableLoading(true);

    continuousMonitoring.mutate(request, {
      onSuccess: (data) => {
        toast.success('Continuous monitoring preferences updated successfully');
        setCurrentMonitoringLevel(data.data.continuous_monitoring_level);
        setIsMonitoringTableLoading(false);
      },
      onError: (error: AxiosError<IErrorResponse>) => {
        toast.error(getErrorMessage(error) || 'Error updating continuous monitoring level');
        queryClient.refetchQueries(['ProfileApi.getMonitoringPreference']);
        setIsMonitoringTableLoading(false);
      },
    });
  };

  const toggleSelection = (data: number) => {
    const newAutoResolved = autoResolveAlerts.includes(data)
      ? autoResolveAlerts.filter((item: number) => item !== data)
      : [...(autoResolveAlerts || []), data];

    const request: IUserMonitoringPreferenceResponse = {
      levels: newAutoResolved,
      __typename: 'AutoResolve',
    };
    mutate(request);
  };

  const durationOptions = [
    {
      label: '30 Days',
      value: '30',
    },
    {
      label: '60 Days',
      value: '60',
    },
    {
      label: '90 Days',
      value: '90',
    },
    {
      label: 'Indefinitely',
      value: null,
    },
  ];

  const handleDurationChange = (value: IOption) => {
    setDuration(value);
    setIsMonitoringTableLoading(true);
    const request: IContinuousMonitoringUpdateRequest = {
      continuous_monitoring_days: Number(value.value) || null,
    };
    continuousMonitoring.mutate(request, {
      onSuccess: () => {
        toast.success('Continuous monitoring duration updated successfully');
        setIsMonitoringTableLoading(false);
      },
      onError: (error: AxiosError<IErrorResponse>) => {
        setIsMonitoringTableLoading(false);
        toast.error(getErrorMessage(error) || 'Error updating continuous monitoring duration');
      },
    });
  };

  return (
    <>
      <div className='h-full max-w-6xl'>
        <TabHeader heading='Monitoring Config' />
        {isPreferenceLoading || isMonitoringLoading ? (
          <CompassLoader />
        ) : (
          <>
            <div>
              <h4 className='mt-5'>Auto Resolve Alerts</h4>
              <TabHeader heading='' subheading='Alerts of these alert levels will be auto resolved.' />
              <div className='flex gap-4 pt-3'>
                <CheckboxController
                  control={control}
                  name='riskLevels.1'
                  checked={autoResolveAlerts.includes(5)}
                  onChange={() => toggleSelection(5)}
                  labelNode={
                    <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                      <div className='text-sm font-medium'>Critical</div>
                    </div>
                  }
                />
                <CheckboxController
                  control={control}
                  name='riskLevels.2'
                  checked={autoResolveAlerts.includes(4)}
                  onChange={() => toggleSelection(4)}
                  labelNode={
                    <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                      <div className='text-sm font-medium'>High</div>
                    </div>
                  }
                />
                <CheckboxController
                  control={control}
                  name='riskLevels.3'
                  checked={autoResolveAlerts.includes(3)}
                  onChange={() => toggleSelection(3)}
                  labelNode={
                    <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                      <div className='text-sm font-medium'>Medium</div>
                    </div>
                  }
                />
                <CheckboxController
                  control={control}
                  name='riskLevels.4'
                  checked={autoResolveAlerts.includes(2)}
                  onChange={() => toggleSelection(2)}
                  labelNode={
                    <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                      <div className='text-sm font-medium'>Caution</div>
                    </div>
                  }
                />
                <CheckboxController
                  control={control}
                  name='riskLevels.5'
                  checked={autoResolveAlerts.includes(1)}
                  onChange={() => toggleSelection(1)}
                  labelNode={
                    <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                      <div className='text-sm font-medium'>Info</div>
                    </div>
                  }
                />
              </div>
            </div>
            <div className='mt-6 pt-3'>
              <hr />
            </div>
            <div className='mb-8'>
              <h4 className='mt-5'>Continuous Monitoring</h4>
              <div className='mb-5 w-1/2'>
                <TabHeader
                  heading=''
                  subheading='When Enabled, risk levels for previously searched addresses are continuously updated in real time as transactions are seen on the blockchain.'
                />
              </div>
              <div className='mb-6'>
                <RadioButtonsController
                  control={control}
                  name='monitoring'
                  onClick={onChangeMonitoring}
                  disabled={!canEnableMonitoring}>
                  <RadioButton labelText='Enable' value={1} />
                  <RadioButton labelText='Disable' value={0} />
                  <RadioButton labelText='Custom' value={2} />
                </RadioButtonsController>
              </div>
              {canEnableMonitoring && (
                <>
                  <div className='mb-5 w-1/2'>
                    <TabHeader
                      heading=''
                      subheading='Address will be monitored for the set period from the date of last screening of the address'
                    />
                  </div>
                  <div
                    className={classNames('w-72', monitoringLevel, {
                      'pointer-events-none opacity-50': monitoringLevel == 0,
                    })}>
                    <SingleSelect
                      placeholder='Select Duration'
                      name={'duration'}
                      options={durationOptions}
                      value={duration}
                      handleChange={handleDurationChange}
                      isCloseHidden
                    />
                  </div>
                </>
              )}
            </div>
            {isMonitoringTableLoading ? (
              <CompassLoader />
            ) : (
              !!currentMonitoringLevel &&
              canEnableMonitoring && <MonitoringTable monitoringLevel={currentMonitoringLevel} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MonitoringPreference;
