import React, { FC, HTMLAttributeAnchorTarget } from 'react';
import { useRouter } from '../../../modules/router/RouterProvider';

interface LinkClassProps {
  isActive: boolean;
}

interface CustomLinkProps {
  to: string;
  params?: Record<string, string>;
  className?: string | ((props: LinkClassProps) => string);
  target?: HTMLAttributeAnchorTarget;
  children: React.ReactNode;
}

const CustomLink: FC<CustomLinkProps> = ({ to = '', params, className, children, target }) => {
  const { navigate } = useRouter();
  const isActive = window.location.pathname === to;
  const handleClick = (event) => {
    event.preventDefault();
    navigate(to, params);
  };

  const href = to + (params ? '?' + new URLSearchParams(params).toString() : '');

  const finalClassName = typeof className === 'function' ? className({ isActive }) : className;

  if (!href) return <>{children}</>;

  return (
    <a href={href} target={target} className={finalClassName} onClick={target ? null : handleClick}>
      {children}
    </a>
  );
};

export default CustomLink;
