import * as React from 'react';
import { PlusCircle } from '@phosphor-icons/react/dist/ssr';
import { TrashSimple } from '@phosphor-icons/react';

import { getErrorMessage } from '../../../utils/helpers/helperFunctions';
import { useRouter } from '../../../modules/router/RouterProvider';

import { ICustomerCryptoAddresses } from '../../../api/dtos/customer';
import { customerApi } from '../../../api/customer';

import { BasicModal, Button, Input } from '../../../ui';
import { toast } from '../../../ui/components/Toast';
import { useAuth } from '../../../modules/auth';
import { IOption } from '../../Filters/CustomSelect';
import CurrencyDropdown from '../../ui/components/Select/CurrencyDropdown';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useWorkspace } from '../../../utils/helpers/common';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface ICustomerDepositAddress {
  currency: IOption;
  identifier: string;
}

const AddCustomerModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { navigate } = useRouter();
  const { state } = useAuth();
  const isAnyEnabled = useWorkspace()?.is_address_screening_type_any_enabled;

  const [customerId, setCustomerId] = React.useState('');

  const [customerDepositAddresses, setCustomerDepositAddresses] = React.useState<ICustomerDepositAddress[]>(
    []
  );

  const addDepositAddress = () => {
    setCustomerDepositAddresses([
      ...customerDepositAddresses,
      {
        currency: {
          label: 'BTC',
          value: '0',
        },
        identifier: '',
      },
    ]);
  };

  const removeCustomerDepositAddress = (index: number) => {
    setCustomerDepositAddresses((cda) => cda.filter((c, i) => i !== index));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateCustomerDepositAddress = (i: number, field: string, value: any) => {
    setCustomerDepositAddresses((currentCustomerDepositAddress) => {
      return currentCustomerDepositAddress.map((cda, index) => {
        if (index === i) {
          return {
            ...cda,
            [field]: value,
          };
        } else {
          return cda;
        }
      });
    });
  };
  const { mutate } = useMutation(customerApi.addCustomer, {
    onSuccess: (res) => {
      navigate(`/customers/${res.data.customer_id}`);

      setCustomerId('');
      onClose();
    },
    onError: (error: AxiosError) => {
      toast.error(getErrorMessage(error));
    },
  });
  const addCustomer = async () => {
    mutate({
      customer_id: customerId,
      customer_crypto_addresses: customerDepositAddresses.map((cda): ICustomerCryptoAddresses => {
        return {
          currency: Number(cda.currency?.value || 0),
          identifier: cda.identifier,
          type: 1,
        };
      }),
    });
  };

  return (
    <BasicModal
      size='sm'
      open={isOpen}
      onClose={onClose}
      modalTitle='Add Customer'
      modalBody={
        <div className='z-[100000]'>
          <form
            className='flex flex-col'
            onSubmit={(e) => {
              e.preventDefault();
              addCustomer();
            }}>
            <Input
              id='customer-id'
              type='text'
              value={customerId}
              labelText='Customer ID'
              placeholder='Enter Customer Id'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomerId(e.target.value)}
              helpText='Customer Id should be of at least 5 characters'
              required
            />
            {!state.userProfile?.skip_alert_creation_modes?.includes(1) && !isAnyEnabled && (
              <div>
                <label
                  htmlFor='customer-deposit-address'
                  className='mb-3 mt-5 block text-sm font-medium leading-default text-gray-800'>
                  Customer Deposit Address
                </label>
                <div className='flex flex-col gap-2'>
                  {customerDepositAddresses.map((customerDepositAddress, i) => (
                    <div key={i} className='flex w-full gap-1'>
                      <div className='flex w-full rounded-md border p-0.5'>
                        <CurrencyDropdown
                          currency={customerDepositAddress.currency}
                          setCurrency={(val) => updateCustomerDepositAddress(i, 'currency', val)}
                          addAllChainsOption={false}
                        />
                        <input
                          type='text'
                          className='my-auto ml-1 w-full border-0'
                          placeholder='Enter Address'
                          value={customerDepositAddress.identifier}
                          onChange={(e) => updateCustomerDepositAddress(i, 'identifier', e.target.value)}
                          required
                        />
                      </div>
                      <button
                        type='button'
                        className='rounded-md border px-3.5'
                        onClick={() => removeCustomerDepositAddress(i)}>
                        <TrashSimple />
                      </button>
                    </div>
                  ))}
                </div>
                <div className='mt-3'>
                  <Button
                    variant='tertiary'
                    onClick={() => addDepositAddress()}
                    iconStart={<PlusCircle size={17} weight='duotone' />}>
                    Add Customer Deposit Address
                  </Button>
                </div>
              </div>
            )}
            <div className='mt-4 flex justify-end gap-2'>
              <Button variant='tertiary' onClick={onClose}>
                Cancel
              </Button>
              <Button type='submit'>Add Customer</Button>
            </div>
          </form>
        </div>
      }
    />
  );
};

export default AddCustomerModal;
