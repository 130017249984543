import { useState } from 'react';
import { ICustomerResponse } from '../../../api/dtos/customer';
import Tab, { ITabValue } from '../../ui/components/Tab/Tab';
import EmptyState from '../../../ui/components/States/Empty';
import AddCustomerAddress from './AddCustomerAddress';
import {
  useCustomerGetAnyAddresses,
  useCustomerGetDepositAddresses,
  useCustomerGetTokenSummary,
  useCustomerGetWithdrawalAddresses,
} from '../../../api/customer';
import DigitalAssetSummary from './DigitalAssetSummary';
import CustomerAddressSummary from './CustomerAddressSummary';
import { Dropdown } from '../../../ui';
import { ArrowsClockwise } from '@phosphor-icons/react';
import Popover from '../../../ui/components/Popover/Popover';

interface ICustomerSummaryProps {
  customer: ICustomerResponse;
}

const CustomerSummary: React.FC<ICustomerSummaryProps> = ({ customer }) => {
  const [tab, setTab] = useState<number>(0);
  const [valueType, setValueType] = useState<'digital' | 'usd'>('digital');
  const [isNewAddressAdded, setIsNewAddressAdded] = useState<boolean>(false);

  const customerCryptoAddresses = customer?.customer_crypto_addresses;

  const encodedCustomerId = encodeURIComponent(customer.customer_id);

  const tokenAddressQuery = useCustomerGetTokenSummary(encodedCustomerId);

  const depositAddressesQuery = useCustomerGetDepositAddresses({
    customerId: encodedCustomerId,
  });

  const withdrawalAddressesQuery = useCustomerGetWithdrawalAddresses({
    customerId: encodedCustomerId,
  });

  const anyAddressesQuery = useCustomerGetAnyAddresses({
    customerId: encodedCustomerId,
  });

  const tabs: ITabValue[] = [
    { label: 'Digital Asset Summary', hidden: false },
    {
      label: 'Deposit Address Summary',
      hidden: !depositAddressesQuery?.data?.pages[0]?.data?.results?.length,
    },
    {
      label: 'Withdrawal Address Summary',
      hidden: !withdrawalAddressesQuery?.data?.pages[0]?.data?.results?.length,
    },
    { label: 'User Address Summary', hidden: !anyAddressesQuery?.data?.pages[0]?.data?.results?.length },
  ];

  const onRefresh = () => {
    tokenAddressQuery.refetch();
    depositAddressesQuery.refetch();
    withdrawalAddressesQuery.refetch();
    anyAddressesQuery.refetch();
  };

  const isAddresses =
    customerCryptoAddresses?.length ||
    depositAddressesQuery?.data?.pages[0]?.data?.results?.length ||
    withdrawalAddressesQuery?.data?.pages[0]?.data?.results?.length ||
    anyAddressesQuery?.data?.pages[0]?.data?.results?.length ||
    anyAddressesQuery?.data?.pages[0]?.data?.results?.length;

  return isAddresses ? (
    <div className='rounded-md border'>
      <Tab
        values={tabs}
        type={'primary'}
        onChange={setTab}
        listClassName='border-b p-3 rounded-md'
        headerActions={
          <div className='flex'>
            <Popover
              containerClassName='my-auto'
              referenceContent={
                <ArrowsClockwise
                  onClick={onRefresh}
                  size={20}
                  weight='bold'
                  className='mx-2 cursor-pointer transition hover:rotate-180 hover:text-blue-600'
                />
              }
              isBasic
              popoverContent={'Refresh now'}
            />
            {tab === 0 && (
              <Dropdown
                value={{
                  label: (
                    <span>
                      Value in <strong>{valueType === 'digital' ? 'Digital Asset' : 'USD'}</strong>
                    </span>
                  ),
                  value: valueType,
                }}
                options={[
                  { label: 'Digital Asset', value: 'digital' },
                  { label: 'USD', value: 'usd' },
                ]}
                onChange={(val) => setValueType(val.value as 'digital' | 'usd')}
                optionsContainerClass='right-0'
                customClass='z-20 pl-3 pr-8'
                optionsCustomClass='px-3'
              />
            )}
            <AddCustomerAddress customerId={encodedCustomerId} type='add' />
          </div>
        }>
        <DigitalAssetSummary
          assets={tokenAddressQuery?.data?.data?.results}
          isLoading={tokenAddressQuery.isLoading || tokenAddressQuery.isRefetching}
          valueType={valueType}
        />
        <CustomerAddressSummary addressQuery={depositAddressesQuery} type='deposit' />
        <CustomerAddressSummary addressQuery={withdrawalAddressesQuery} type='withdrawal' />
        <CustomerAddressSummary addressQuery={anyAddressesQuery} type='any' />
      </Tab>
    </div>
  ) : (
    <>
      <EmptyState message='No Addresses associated with this customer' />
      {isNewAddressAdded && (
        <div
          className='group flex cursor-pointer justify-center text-sm hover:text-blue-600'
          onClick={onRefresh}>
          <ArrowsClockwise
            size={20}
            weight='bold'
            className='mx-2 my-auto transition group-hover:rotate-180'
          />
          Refresh
        </div>
      )}
      <AddCustomerAddress
        customerId={encodedCustomerId}
        type='link'
        onSuccess={() => setIsNewAddressAdded(true)}
      />
    </>
  );
};

export default CustomerSummary;
