import { FC } from 'react';
import SettingsTabs from '../../../../components/Settings/Tabs';
import { useAuth } from '../../../../modules/auth';
import { useWorkspace } from '../../../../utils/helpers/common';

const WorkspaceSettings: FC = () => {
  const { state } = useAuth();

  const workspace = useWorkspace();

  const tabs = [
    {
      type: `Workspace Settings - ${workspace.name}`,
      tabs: [
        {
          name: 'api-keys',
          label: 'API Keys',
          isHiddenAgent: true,
        },
        {
          name: 'integrations',
          label: 'Integrations',
        },
        {
          name: 'members',
          label: 'Workspace Members',
        },
        {
          name: 'product',
          label: 'Product Configurations',
          tabs: [
            {
              name: 'workflow',
              label: 'Assignment Workflow',
            },
            {
              name: 'case',
              label: 'Case Preferences',
              isHiddenAgent: true,
            },
            {
              name: 'config',
              label: 'Monitoring Config',
            },
            {
              name: 'smart-contract-watchlist',
              label: 'Smart Contract Watchlist',
            },
            {
              name: 'custom-lists',
              label: 'Custom Lists',
              isHiddenAgent: true,
              isHidden: state.userProfile?.is_custom_tags_enabled,
            },
            {
              name: 'algo-settings',
              label: 'Algo Settings',
              isHiddenAgent: true,
            },
          ],
        },
        {
          name: 'risk',
          label: 'Risk Management',
          tabs: [
            {
              name: 'policies',
              label: 'Risk Policies',
            },
            {
              name: 'rules',
              label: 'Rules',
            },
            {
              name: 'custom-tags',
              label: 'Custom Tags (Rules)',
              isHiddenAgent: true,
              isHidden: state.userProfile?.is_custom_tags_enabled,
            },
          ],
        },
      ],
    },
  ];

  return <SettingsTabs tabs={tabs} type='workspace' />;
};

export default WorkspaceSettings;
