import { FC, Fragment, ReactNode } from 'react';
import { Label, Listbox, ListboxButton, Transition } from '@headlessui/react';
import { CaretDown } from '@phosphor-icons/react';
import classNames from 'classnames';

export interface DropdownOption {
  id?: unknown;
  value: string | number;
  label: string | ReactNode;
  hidden?: boolean;
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
}

export interface DropdownProps {
  // Listbox props
  placeholder?: ReactNode;
  value: DropdownOption;
  onChange: (value: DropdownOption) => void;
  disabled?: boolean | undefined;
  horizontal?: boolean | undefined;
  name?: string | undefined;
  multiple?: boolean | undefined;

  options: DropdownOption[];
  labelText?: ReactNode;
  customClass?: string;
  optionsCustomClass?: string;
  optionsContainerClass?: string;
  customCaretClass?: string;
  error?: string | undefined;
  icon?: ReactNode;
  widthClass?: string;
}

const Dropdown: FC<DropdownProps> = ({
  value,
  options,
  labelText,
  customClass,
  optionsCustomClass,
  optionsContainerClass,
  customCaretClass,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  error,
  onChange,
  disabled,
  placeholder,
  icon,
  widthClass,
  ...rest
}) => {
  return (
    <div>
      <Listbox value={value} onChange={onChange} disabled={disabled} {...rest}>
        {({ open }) => (
          <>
            {labelText && (
              <Label className='mb-1 block text-sm font-medium leading-default text-gray-800'>
                {labelText}
              </Label>
            )}
            <div className='relative text-xs'>
              <ListboxButton
                className={classNames(
                  `relative rounded-md border border-gray-300 bg-white py-2 text-left leading-default shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500`,
                  widthClass ? widthClass : 'w-full',
                  customClass
                )}>
                {!value ? (
                  <span className='flex truncate px-2 text-gray-500'>
                    {icon}
                    {placeholder || 'Select...'}
                  </span>
                ) : (
                  <span className='flex truncate px-2'>
                    {icon}
                    {value?.label}
                  </span>
                )}
                <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                  <CaretDown
                    weight='bold'
                    size={15}
                    className={classNames('mr-0.5 text-blue-300', customCaretClass)}
                  />
                </span>
              </ListboxButton>

              <Transition
                show={open}
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'>
                <Listbox.Options
                  className={classNames(
                    'ring-1/5 absolute z-40 mt-1 max-h-60 min-w-full list-none overflow-auto rounded-md bg-white p-0 py-1 text-sm font-medium leading-default shadow-lg ring-black focus:outline-none',
                    optionsContainerClass
                  )}>
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ selected, focus }) =>
                        classNames(
                          selected || focus ? 'bg-blue-500 text-white' : 'text-gray-800',
                          'relative select-none py-2 pr-4 text-xs font-medium leading-default',
                          optionsCustomClass
                        )
                      }
                      value={option}>
                      {({ selected, focus }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate px-2'
                            )}>
                            {option.label}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                focus ? 'text-white' : 'text-blue-500',
                                'absolute inset-y-0 left-0 flex items-center pl-1.5'
                              )}></span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default Dropdown;
