import CustomSelect, { IOption } from './CustomSelect';

import { FC, useState } from 'react';
import { useStagesListLiteGet } from '../../api/stages';
import { IStageListLiteResponse } from '../../api/dtos/stages';
import StageBadge from '../../ui/components/Badges/StageBadge/StageBadge';
import { Stage } from '../../ui/utils/constants';
import { getColorMappingStageOptions } from '../../utils/helpers/stageOptionsColor';

interface StageNameFilterProps {
  value: (number | string)[];
  setValue: (stages: (number | string)[]) => void;
}

const StageNameFilter: FC<StageNameFilterProps> = (props) => {
  const { value, setValue } = props;
  const stageData = useStagesListLiteGet();

  interface IStageOptions extends IOption {
    id?: Stage | string;
  }

  const getStageDataOptions = (stageData: IStageListLiteResponse): IStageOptions[] => {
    const stageOptionsData = stageData?.results?.map((r) => {
      return {
        id: r.id,
        value: r.name,
        label: r.name,
      };
    });
    if (stageData?.results) {
      if (stageOptionsData.every((item) => item.id !== 'None')) {
        stageOptionsData.push({
          id: 'None',
          value: '-NA-',
          label: '-NA-',
        });
      }
    }
    return stageOptionsData;
  };
  const stageNames = getStageDataOptions(stageData?.data?.data);
  let selectedStages = [];
  if (value && value.length) {
    selectedStages = stageNames?.filter((r) => value?.includes(r.id));
  }
  const [stages, setStage] = useState<IOption[]>(selectedStages);
  const stageColorMap = getColorMappingStageOptions(stageData?.data?.data?.results);

  const handleChange = (stages: IStageOptions[]) => {
    setValue(stages.map((r) => r.id));
    setStage(stages);
  };

  const getCustomOptionLabel = (option: IStageOptions) => {
    if (typeof option.id === 'string') {
      return <StageBadge role={7} label={option.label} />;
    }
    return <StageBadge role={stageColorMap[option.id]} label={option.label} />;
  };

  return (
    <>
      <CustomSelect
        label='Stage Name'
        placeholder='Select Stage Name'
        options={stageNames}
        handleChange={handleChange}
        value={stages}
        getCustomLabel={getCustomOptionLabel}
        getCustomOptionLabel={getCustomOptionLabel}
      />
    </>
  );
};

export default StageNameFilter;
