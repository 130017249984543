import { FC, useRef } from 'react';
import { TimeSeriesByRiskLevel } from '../../api/dtos/dashboard';
import DashboardWidget from './DashboardWidget';
import { getPercentageValue } from '../../utils/helpers/dashboard';
import { ArrowUp, ArrowDown } from '@phosphor-icons/react';
import { GoogleCharts } from 'google-charts';
import { RiskLevel } from '../../utils/constants/constants';
import { createTooltip } from './WidgetGraphTooltip';

interface ICaseDistributionWidgetProps {
  casesCount: number;
  previousCasesCount: number;
  timeSeriesData: TimeSeriesByRiskLevel[];
  isLoading?: boolean;
  isPdf?: boolean;
}

const CaseDistributionWidget: FC<ICaseDistributionWidgetProps> = ({
  casesCount,
  previousCasesCount,
  timeSeriesData,
  isLoading,
  isPdf,
}) => {
  const percentValue = getPercentageValue(casesCount, previousCasesCount);

  const riskTickData = () => {
    const data = [];
    timeSeriesData?.forEach((value) => {
      const date = new Date(value.date);
      date.setHours(0, 0, 0, 0);
      data.push(date);
    });
    return data;
  };

  const risks = [...RiskLevel]?.sort((a, b) => a.value - b.value);
  const colors = risks.slice(1).map((risk) => risk.color);

  const riskLevelDistributionChart = () => {
    const data = [];

    if (timeSeriesData) {
      timeSeriesData.forEach((value) => {
        const date = new Date(value.date);
        date.setHours(0, 0, 0, 0);
        const rowData: (string | number | Date)[] = [date];
        for (let i = 1; i <= 5; i++) {
          rowData.push(
            value.case_level_counts[i],
            'fill-opacity: 1;',
            createTooltip(risks[i].label, value.case_level_counts[i], casesCount, risks[i].color)
          );
        }

        data.push(rowData);
      });
    }

    const headers: (string | { type: string; role: string; p?: { html: boolean } })[] = ['Date'];
    for (let i = 1; i <= 5; i++) {
      headers.push(
        'Count',
        { type: 'string', role: 'style' },
        { type: 'string', role: 'tooltip', p: { html: true } }
      );
    }

    return [headers, ...data];
  };

  const options = {
    title: '',
    fontName:
      'Inter, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    isStacked: true,
    titleTextStyle: {
      bold: false,
      color: '#111827',
      fontSize: 16,
    },
    hAxis:
      riskTickData().length <= 10
        ? {
            title: '',
            titleTextStyle: { color: '#6B7280' },
            textStyle: {
              color: '#6B7280',
              fontSize: 12,
            },
            gridlines: { color: '#E5E7EB' },
            ticks: riskTickData(),
            format: 'dd MMM',
          }
        : {
            title: '',
            titleTextStyle: { color: '#6B7280' },
            textStyle: {
              color: '#6B7280',
              fontSize: 12,
            },
            gridlines: { color: '#E5E7EB' },
            format: 'dd MMM',
          },
    vAxis: {
      minValue: casesCount === 0 ? 1 : 0,
      viewWindowMode: 'explicit',
      viewWindow: { min: casesCount === 0 ? 1 : 0 },
      textPosition: 'none',
      gridlines: {
        color: 'transparent',
      },
    },
    legend: { position: 'none' },
    chartArea: {
      width: '94%',
      top: '10%',
    },
    tooltip: { isHtml: true },
    colors,
    width: isPdf ? 450 : null,
  };
  const noData = !(timeSeriesData?.length > 0);
  if (!noData) GoogleCharts.load(drawChart);

  const chartRef = useRef(null);

  function drawChart() {
    const data = GoogleCharts.api.visualization.arrayToDataTable(riskLevelDistributionChart());

    if (chartRef?.current) {
      const chart = new GoogleCharts.api.visualization.AreaChart(chartRef?.current);
      chart.draw(data, options);
    }
  }

  return (
    <DashboardWidget
      title='Case Distribution'
      subtitle='By Risk Level'
      className='pb-0'
      containerClassName={!isPdf ? 'col-span-4' : 'col-span-6'}
      isLoading={isLoading}
      noData={noData}
      tooltipTitle='Case Distribution'
      tooltipDescription='Case Distribution by risk level provides a breakdown by volume over time of the Critical, High, Medium, and Caution risk levels in the selected period of time.'>
      <div className='flex justify-between'>
        <div className='text-2xl flex'>
          <div className='mr-2 font-semibold'>{casesCount?.toLocaleString()}</div>
          <div>cases</div>
        </div>
        <div className='my-auto rounded-xl bg-green-100 px-2.5 text-sm'>
          <div className='flex text-sm'>
            {percentValue.value > 0 ? (
              <ArrowUp size={20} className='my-auto pl-1 text-emerald-500' weight='bold' />
            ) : (
              <ArrowDown size={20} className='my-auto pl-1 text-red-500' weight='bold' />
            )}
            <span className='font-medium text-green-800'>{percentValue.percent}</span>
          </div>
        </div>
      </div>
      <div>
        <div ref={chartRef} />
      </div>
    </DashboardWidget>
  );
};

export default CaseDistributionWidget;
