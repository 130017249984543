import { FC, useEffect, useMemo, useState } from 'react';
import Tab from '../../ui/components/Tab/Tab';
import { useAlertGetAlerts } from '../../../api/alert';
import { flattenInfiniteQueryResult } from '../../../utils/helpers/react-query.helper';
import Pagination from '../../../containers/Pagination';
import Alert from './Alert/Alert';
import AlertFilter, { defaultStateFilters } from './Alert/AlertFilter';
import AlertAppliedFilters from './Alert/AlertAppliedFilters';
import ResolveAllAlerts from './Alert/ResolveAllAlerts';
import { UseInfiniteQueryResult, useQueryClient } from 'react-query';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { IAlertFilters, IAlertGetAlertsResponse } from '../../../api/dtos/alert';
import { every, isEmpty } from 'lodash';
import { AxiosResponse } from 'axios';
import { Tooltip } from '../../../ui/components/Tooltip';

interface AlertsProps {
  type: 'address' | 'transaction' | 'customer';
  id: string | number;
  // custId is numeric id of customer used for resolving all alerts
  custId?: number;
  setAlerts?: (alerts: number) => void;
  setAlertDetails?: (alert: number) => void;
}

const Alerts: FC<AlertsProps> = ({ type, id, setAlerts, custId, setAlertDetails }) => {
  const [tab, setTab] = useState<number>(0);
  const [openFilters, setOpenFilters] = useState(defaultStateFilters as IAlertFilters);
  const [resolvedFilters, setResolvedFilters] = useState(defaultStateFilters as IAlertFilters);
  const [escalatedFilters, setEscalatedFilters] = useState(defaultStateFilters as IAlertFilters);
  const [staleFilters, setStaleFilters] = useState(defaultStateFilters as IAlertFilters);
  const [isResolvingAll, setIsResolvingAll] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const openAlertsQuery = useAlertGetAlerts({ id, type, status: [0], filters: openFilters });
  const resolvedAlertsQuery = useAlertGetAlerts({
    id,
    type,
    status: [1],
    filters: resolvedFilters,
  });
  const escalatedAlertsQuery = useAlertGetAlerts({ id, type, status: [2], filters: escalatedFilters });
  const staleAlertsQuery = useAlertGetAlerts({ id, type, is_stale: true, filters: staleFilters });

  const [openAlertsCount] = flattenInfiniteQueryResult(openAlertsQuery.data);
  const [resolvedAlertsCount] = flattenInfiniteQueryResult(resolvedAlertsQuery.data);
  const [escalatedAlertsCount] = flattenInfiniteQueryResult(escalatedAlertsQuery.data);
  const [staleAlertsCount] = flattenInfiniteQueryResult(staleAlertsQuery.data);

  const isLoading =
    openAlertsQuery.isLoading ||
    resolvedAlertsQuery.isLoading ||
    escalatedAlertsQuery.isLoading ||
    staleAlertsQuery.isLoading;

  const onApplyFilter = (filters: typeof defaultStateFilters) => {
    if (tab === 0) setOpenFilters(filters);
    else if (tab === 1) setEscalatedFilters(filters);
    else if (tab === 2) setResolvedFilters(filters);
    else if (tab === 3) setStaleFilters(filters);
  };

  const currentFilters = useMemo(() => {
    if (tab === 0) return openFilters;
    else if (tab === 1) return escalatedFilters;
    else if (tab === 2) return resolvedFilters;
    else if (tab === 3) return staleFilters;
  }, [escalatedFilters, openFilters, resolvedFilters, staleFilters, tab]);

  useEffect(() => {
    setAlerts(openAlertsCount);
  }, [openAlertsCount, setAlerts]);

  const isOpenFilterApplied = !every(openFilters, (f) => isEmpty(f));
  const isResolvedFilterApplied = !every(resolvedFilters, (f) => isEmpty(f));
  const isEscalatedFilterApplied = !every(escalatedFilters, (f) => isEmpty(f));
  const isStaleFilterApplied = !every(staleFilters, (f) => isEmpty(f));

  const tabs = [
    { label: 'Open Alerts', count: openAlertsCount, hidden: !openAlertsCount && !isOpenFilterApplied },
    {
      label: 'Escalated Alerts',
      count: escalatedAlertsCount,
      hidden: !escalatedAlertsCount && !isEscalatedFilterApplied,
    },
    {
      label: 'Resolved Alerts',
      count: resolvedAlertsCount,
      hidden: !resolvedAlertsCount && !isResolvedFilterApplied,
    },
    {
      label: (
        <div>
          <Tooltip
            place='bottom'
            className='-ml-1 mr-1 bg-slate-300/100 leading-3 text-gray-900'
            content='Inactive alerts are alerts created in the past but are no longer active due to reasons indicated in the audit trail for the respective alert'
            id='inactive-alert-tooltip'
            label='i'
          />
          Inactive Alerts
        </div>
      ),
      count: staleAlertsCount,
      hidden: !staleAlertsCount && !isStaleFilterApplied,
    },
  ];

  const onChange = (tab: number) => setTab(tab);

  const onResolveAll = () => {
    setIsResolvingAll(true);
    // wait for 10s for BE to resolve all alerts
    setTimeout(() => {
      const key = ['alertApi.getAlerts', id];
      queryClient.invalidateQueries(key);
      setIsResolvingAll(false);
      toast.success('Resolved all open alerts');
    }, 10000);
  };

  const isNoAlerts =
    !openAlertsCount &&
    !resolvedAlertsCount &&
    !escalatedAlertsCount &&
    !staleAlertsCount &&
    !isLoading &&
    !isOpenFilterApplied &&
    !isResolvedFilterApplied &&
    !isEscalatedFilterApplied &&
    !isStaleFilterApplied;

  const renderPagination = (query: UseInfiniteQueryResult<AxiosResponse<IAlertGetAlertsResponse>>) => (
    <Pagination query={query} className='max-h-80 text-sm' errorMessage='No alerts available'>
      {(alert) => (
        <Alert
          alert={alert}
          id={id}
          key={alert.id}
          type={type}
          setAlertDetails={setAlertDetails}
          isStale={tab === 3}
        />
      )}
    </Pagination>
  );

  return (
    <div className='relative'>
      <div
        className={classNames('rounded-md border border-gray-200', {
          'pointer-events-none opacity-50': isResolvingAll,
        })}>
        <Tab
          values={tabs}
          type='primary'
          listClassName='border-b-2 p-4 rounded-t-md'
          inactiveClassName='text-gray-800/30 border-gray-200 cursor-not-allowed'
          panelClassName='px-4'
          headerActions={
            !isNoAlerts && (
              <div className='flex'>
                {tab === 0 && (
                  <ResolveAllAlerts ids={[(custId || id) as string]} type={type} onResolve={onResolveAll} />
                )}
                <AlertFilter filters={currentFilters} onApply={onApplyFilter} type={type} />
              </div>
            )
          }
          isLoading={isLoading}
          subHeader={<AlertAppliedFilters filters={currentFilters} setFilters={onApplyFilter} />}
          onChange={onChange}>
          {renderPagination(openAlertsQuery)}
          {renderPagination(escalatedAlertsQuery)}
          {renderPagination(resolvedAlertsQuery)}
          {renderPagination(staleAlertsQuery)}
        </Tab>
      </div>
      {isResolvingAll && (
        <div className='absolute inset-x-0 top-1/2 mx-auto flex w-80 text-center text-sm opacity-100'>
          Bulk resolve alerts will complete in few seconds
        </div>
      )}
    </div>
  );
};

export default Alerts;
