import React from 'react';
import { FilterPanel } from '../FilterPanel';
import RiskFilter from '../Filters/RiskFilter';
import CalendarFilter, { IDateRangeString } from '../Filters/CalendarFilter';
import RuleNameFilter from '../Filters/RuleNameFilter';
import SingleSelect from '../ui/components/Select/SingleSelect';

export interface IFilters {
  level: number[];
  start_time: string;
  end_time: string;
  rule_name: string[];
  policy_category: string;
  policy_type: number;
}

export const defaultAlertListFilters: IFilters = {
  level: [],
  start_time: null,
  end_time: null,
  rule_name: [],
  policy_category: null,
  policy_type: null,
};

export const policyTypeCategoryOptions = [
  { label: 'Address > Source of Funds', value: '1,1' },
  { label: 'Address > Behaviour', value: '2,1' },
  { label: 'Address > Source of Funds Advanced', value: '3,1' },
  { label: 'Transaction > Source of Funds', value: '1,0' },
  { label: 'Customer > Source of Funds', value: '1,2' },
  { label: 'Customer > Behaviour', value: '2,2' },
  { label: 'Customer > Source of Funds Advanced', value: '3,2' },
];

interface Props {
  filters: IFilters;
  onApply: (filters: IFilters) => void;
  onReset: () => void;
  disabled?: boolean;
}

const AlertListFilter: React.FC<Props> = ({ filters, onApply, onReset, disabled }) => {
  const [level, setLevel] = React.useState<number[]>();
  const [transactionDate, setTransactionDate] = React.useState<IDateRangeString>();
  const [ruleName, setRuleName] = React.useState<string[]>();
  const [category, setCategory] = React.useState<string>();
  const [policyType, setPolicyType] = React.useState<number>();

  const handleApply = () => {
    onApply({
      ...filters,
      level,
      start_time: transactionDate?.from,
      end_time: transactionDate?.to,
      rule_name: ruleName,
      policy_category: category,
      policy_type: policyType,
    });
  };

  const handleReset = () => {
    setLevel([]);
    setTransactionDate(null);
    onReset();
    setRuleName([]);
    setCategory(null);
    setPolicyType(null);
  };

  React.useEffect(() => {
    setLevel(filters.level);
    setTransactionDate({
      from: filters.start_time,
      to: filters.end_time,
    });
    setRuleName(filters.rule_name);
    setCategory(filters.policy_category);
    setPolicyType(filters.policy_type);
  }, [filters]);

  return (
    <FilterPanel onApply={handleApply} onReset={handleReset} disabled={disabled}>
      <RiskFilter value={level} setValue={setLevel} />
      <CalendarFilter label='Created At' value={transactionDate} setValue={setTransactionDate} />
      <RuleNameFilter value={ruleName} setValue={setRuleName} type={'all'} isSingle />
      <SingleSelect
        options={policyTypeCategoryOptions}
        handleChange={(value: (typeof policyTypeCategoryOptions)[0]) => {
          setCategory(value?.value.split(',')[0]);
          setPolicyType(Number(value?.value.split(',')[1]));
        }}
        value={policyTypeCategoryOptions.find(
          (o) => o.value.split(',')[0] === category && Number(o.value.split(',')[1]) === policyType
        )}
        label='Policy Type & Category'
        labelClassName='text-sm'
      />
    </FilterPanel>
  );
};

export default AlertListFilter;
