/* eslint-disable tailwindcss/enforces-shorthand */
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ForceGraph2D, { ForceGraphMethods, LinkObject, NodeObject } from 'react-force-graph-2d';
import * as d3 from 'd3-force';
import classNames from 'classnames';
import html2canvas from 'html2canvas';
import { useMutation, useQuery } from 'react-query';
import Skeleton from 'react-loading-skeleton';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import Select from 'react-select';
import {
  ArrowsInSimple,
  ArrowsOutSimple,
  CaretRight,
  CornersOut,
  X,
  Funnel,
  ImageSquare,
  ListBullets,
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
} from '@phosphor-icons/react';

import {
  colorLinkIncoming,
  colorLinkOutgoing,
  colorsDark,
  createLink,
  drawLink,
  drawNode,
  findAllPaths,
  getLinkWidth,
} from '../../../utils/helpers/drawCanvasElements';
import { investigationTreeApi } from '../../../api/investigationTree/investigationTree';
import { IInvestigationTreeResponse } from '../../../api/dtos/investigationTree';

import { Button } from '../../../ui/components/Button';
import { Dropdown, DropdownOption } from '../../../ui/components/Dropdown';
import CopyToClipboardBtn from '../../../ui/components/Copy/Copy';
import Input from '../../../ui/components/Input/Input';

import {
  EntityOption,
  IAddressDetailsPanelData,
  IDetailsPanelData,
  ILinkObject,
  INodeType,
  InvestigationTreeProps,
  ITxnDetailsPanelData,
} from './types';
import {
  colorStyles,
  createNode,
  formatAmountAsString,
  getAvgLinkVal,
  truncateAddress,
  truncateName,
} from './helper';

import _style from './investigationTree.module.scss';
import 'react-tooltip/dist/react-tooltip.css';
import './index.scss';
import { ITransactionResponse } from '../../../api/dtos/transaction';
import Search from '../../ui/components/Search/Search';
import { formatDateShort } from '../../../utils/helpers/date';
import { AxiosError } from 'axios';
import EmptyState from '../../../ui/components/States/Empty';
import { useAuth } from '../../../modules/auth';
import { getEntityTypeBySubType } from '../../../utils/helpers/entity';
import { getErrorMessage } from '../../../utils/helpers/helperFunctions';

const InvestigationTree: FC<InvestigationTreeProps> = (props) => {
  const {
    originType,
    address,
    transaction,
    defaultEntities,
    defaultEntitySubTypes,
    defaultEntityName,
    popover,
  } = props;

  const hash = originType === 'address' ? address.identifier : transaction.identifier;
  const currency = originType === 'address' ? address.currency : transaction.currency;
  const identifierDetails = originType === 'address' ? address : transaction;
  const entityTypes = useMemo(() => {
    return { ...colorsDark };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const forceGraphContainerRef: React.MutableRefObject<any> = useRef(null);
  const forceGraphRef: React.MutableRefObject<ForceGraphMethods> = useRef(null);

  const [firstRenderFlag, setFirstRenderFlag] = useState<boolean>(true);
  const [isForceSet, setIsForceSet] = useState<boolean>(false);
  const [investigationTree, setInvestigationTree] = useState<IInvestigationTreeResponse | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [graphData, setGraphData] = useState<any>();
  const [entityTypesSet, setEntityTypesSet] = useState<Set<string>>(new Set());
  const [entitySubTypeSet, setEntitySubTypeSet] = useState<Set<string>>(new Set());
  const [isFiltersApplied, setIsFiltersApplied] = useState<boolean>(false);

  const entityTypesList: EntityOption[] = useMemo(
    () =>
      Array.from(entityTypesSet).map((entityType: string) => {
        return { label: entityType, value: entityType, color: entityTypes[entityType] };
      }),
    [entityTypesSet, entityTypes]
  );

  const entitySubTypeList = useMemo(
    () =>
      Array.from(entitySubTypeSet).map((entitySubType: string) => {
        return {
          label: getEntityTypeBySubType(entitySubType) + '->' + entitySubType,
          value: entitySubType,
          color: entityTypes[getEntityTypeBySubType(entitySubType)],
        };
      }),
    [entitySubTypeSet, entityTypes]
  );

  const [entityTypeFilters, setEntityTypeFilters] = useState<EntityOption[]>([]);
  const [entitySubTypeFilters, setEntitySubTypeFilters] = useState<EntityOption[]>([]);
  const [entityNameFilter, setEntityNameFilter] = useState<string>('');
  const [transactionTypeFilter, setTransactionTypeFilter] = useState<'Incoming' | 'Outgoing' | 'All'>('All');
  const transactionTypesList: DropdownOption[] = [
    { value: 'All', label: 'All' },
    { value: 'Incoming', label: 'Incoming' },
    { value: 'Outgoing', label: 'Outgoing' },
  ];

  const [disableZoomIn, setDisableZoomIn] = useState<boolean>(false);
  const [disableZoomOut, setDisableZoomOut] = useState<boolean>(false);

  const [showDetailsPanel, setShowDetailsPanel] = useState<'' | 'address' | 'transaction'>('');
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [showLegends, setShowLegends] = useState<boolean>(false);
  const [showAddressesTab, setShowAddressesTab] = useState<boolean>(false);

  const [selectedDirection, setSelectedDirection] = useState<'from' | 'to'>('from');

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const [hoverNode, setHoverNode] = useState<NodeObject | null>(null);
  const [hoverLink, setHoverLink] = useState<LinkObject | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [highlightNodes, setHighlightNodes] = useState<Set<any>>(new Set());
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [highlightLinks, setHighlightLinks] = useState<Set<any>>(new Set());
  const [detailsPanelData, setDetailsPanelData] = useState<IDetailsPanelData>(null);

  const [maxDepth, setMaxDepth] = useState<number>(99999);
  const [depthFilter, setDepthFilter] = useState<number | ''>('');

  const [txnValueUsdFilterMin, setTxnValueUsdFilterMin] = useState<number>(0);
  const [txnValueUsdFilterMax, setTxnValueUsdFilterMax] = useState<number | ''>('');

  const [error, setError] = useState<string>('');

  const controlsRef = useRef(null);
  const detailsTableRef = useRef(null);

  const graphHeight = useMemo(() => {
    if (!forceGraphContainerRef?.current) {
      return 0;
    }
    return forceGraphContainerRef.current.clientWidth / 2;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceGraphContainerRef?.current]);

  const graphWidth = useMemo(() => {
    if (!forceGraphContainerRef?.current) {
      return 0;
    }
    return forceGraphContainerRef.current.clientWidth;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceGraphContainerRef?.current]);

  const maxZoom = 2;
  const minZoom = useMemo(() => {
    if (graphData?.nodes?.length) {
      return Math.min(10 / graphData?.nodes?.length, 0.5);
    }
  }, [graphData?.nodes?.length]);

  const isCurrentIdentifier = useCallback(
    (paramId) => {
      return hash?.toLowerCase() === paramId?.toLowerCase();
    },
    [hash]
  );

  const timezone = useAuth()?.state?.userProfile?.timezone;

  const drawChart = () => {
    const newGraphData = { nodes: [], links: [] };

    const newEntityTypesSet = new Set<string>();
    const newEntitySubTypeSet = new Set<string>();

    const avgLinkVal = getAvgLinkVal(investigationTree);

    investigationTree?.nodes.forEach((node) => {
      if (node.tag_type_verbose) {
        newEntityTypesSet.add(node.tag_type_verbose);
      }
      if (node.tag_subtype_verbose) {
        newEntitySubTypeSet.add(node.tag_subtype_verbose);
      }
      newGraphData.nodes.push(createNode(node, node.node, hash));
    });

    let newMaxDepth = 0;

    investigationTree?.incoming_edges.forEach((edge) => {
      const linkWidth = getLinkWidth(edge.value, avgLinkVal);
      newGraphData.links.push(createLink(edge, edge.sender, edge.receiver, colorLinkIncoming, linkWidth));
      if (Number(edge.depth) > newMaxDepth) {
        newMaxDepth = Number(edge.depth);
      }
    });

    investigationTree?.outgoing_edges.forEach((edge) => {
      const linkWidth = getLinkWidth(edge.value, avgLinkVal);
      newGraphData.links.push(createLink(edge, edge.sender, edge.receiver, colorLinkOutgoing, linkWidth));
      if (Number(edge.depth) > newMaxDepth) {
        newMaxDepth = Number(edge.depth);
      }
    });

    setGraphData(newGraphData);
    setEntityTypesSet(newEntityTypesSet);
    setEntitySubTypeSet(newEntitySubTypeSet);
    setMaxDepth(newMaxDepth);
  };

  useEffect(() => {
    // If default entity is already given
    if (graphData) {
      if (defaultEntities && !entityTypeFilters.length) {
        setEntityTypeFilters(
          defaultEntities.map((entity) => {
            return { label: entity, value: entity, color: entityTypes[entity] };
          })
        );
      }
      if (defaultEntitySubTypes && !entitySubTypeFilters.length) {
        setEntitySubTypeFilters(
          defaultEntitySubTypes.map((entitySubType) => ({
            label: getEntityTypeBySubType(entitySubType) + '->' + entitySubType,
            value: entitySubType,
            color: entityTypes[getEntityTypeBySubType(entitySubType)],
          }))
        );
      }
      if (defaultEntityName && !entityNameFilter) {
        setEntityNameFilter(defaultEntityName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData]);

  useEffect(() => {
    if (
      (entityTypeFilters.length && defaultEntities) ||
      (entitySubTypeFilters.length && defaultEntitySubTypes) ||
      (entityNameFilter && defaultEntityName)
    ) {
      setTimeout(applyExistingFilters, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultEntities,
    defaultEntitySubTypes,
    defaultEntityName,
    entityTypeFilters.length,
    entitySubTypeFilters.length,
    entityNameFilter.length,
  ]);

  const onZoom = () => {
    if (forceGraphRef?.current?.zoom() >= maxZoom) {
      setDisableZoomIn(true);
      setDisableZoomOut(false);
    } else if (forceGraphRef?.current?.zoom() <= minZoom) {
      setDisableZoomIn(false);
      setDisableZoomOut(true);
    } else {
      setDisableZoomIn(false);
      setDisableZoomOut(false);
    }
  };

  const fitGraph = () => {
    forceGraphRef?.current.zoomToFit(400);
  };

  const zoomIn = () => {
    forceGraphRef?.current.zoom(forceGraphRef?.current.zoom() + 0.25);
  };

  const zoomOut = () => {
    forceGraphRef?.current.zoom(forceGraphRef?.current.zoom() - 0.25);
  };

  const toggleShowLegends = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (showFilters) {
      setShowFilters(false);
    }
    setShowLegends(!showLegends);
  };

  const toggleShowFilters = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (showLegends) {
      setShowLegends(false);
    }
    setShowFilters(!showFilters);
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const downloadCanvasAsPng = () => {
    const el = forceGraphContainerRef.current;
    // as we don't have access to forceGraph canvas, and it doesn't have an id
    // we are converting the parent div to canvas and then converting it to a png
    html2canvas(el)
      .then((canvas) => {
        // create an "off-screen" anchor tag
        const link = document.createElement('a');
        let e;

        // the key here is to set the download attribute of the a tag
        link.download = `${hash}.png`;

        // Convert canvas content to data-uri for link.
        // When download attribute is set the content pointed to by link will be
        // pushed as "download" in HTML5 capable browsers
        link.href = canvas.toDataURL('image/png;base64');

        /// create a "fake" click-event to trigger the download
        if (document.createEvent) {
          e = document.createEvent('MouseEvents');
          e.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);

          link.dispatchEvent(e);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } else if ((link as any).fireEvent) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (link as any).fireEvent('onclick');
        }
      })
      .catch(() => {});
  };

  const applyExistingFilters = () => {
    let filteredNodes = [];
    // check entity filters
    if (entityTypeFilters.length) {
      const entityTypeFiltersValueArr = entityTypeFilters.map((filter) => filter.value);
      filteredNodes = graphData.nodes.filter((node) =>
        entityTypeFiltersValueArr.includes(node.tag_type_verbose)
      );
    } else {
      filteredNodes = graphData.nodes;
    }
    if (entitySubTypeFilters.length) {
      const entitySubTypeFiltersValueArr = entitySubTypeFilters.map((filter) => filter.value);
      filteredNodes = filteredNodes.filter((node) =>
        entitySubTypeFiltersValueArr.includes(node.tag_subtype_verbose)
      );
    }
    if (entityNameFilter) {
      filteredNodes = filteredNodes.filter((node) => {
        return node.tag_name_verbose.toLowerCase().includes(entityNameFilter.toLowerCase());
      });
    }
    filteredNodes = filteredNodes.map((node) => node.id);
    // if Incoming or Outgoing is selected
    if (transactionTypeFilter !== 'All') {
      if (transactionTypeFilter === 'Incoming') {
        filteredNodes = filteredNodes.filter((nodeId) => {
          return nodeId.charAt(0) === '-';
        });
      } else {
        filteredNodes = filteredNodes.filter((nodeId) => {
          return nodeId.charAt(0) === '+';
        });
      }
    }

    // now check path to origin and add the nodes in between to filtered nodes
    const filteredNodesInPath = new Set();
    filteredNodes.forEach((nodeId) => {
      if (!filteredNodesInPath.has(nodeId)) {
        const nodesInPath = findAllPaths(nodeId, hash, graphData);
        // this will usually be a small array
        nodesInPath?.forEach((nodeInPathId) => {
          if (!isCurrentIdentifier(nodeInPathId) && !filteredNodes.includes(nodeInPathId)) {
            filteredNodesInPath.add(nodeInPathId);
          }
        });
      }
    });

    filteredNodes = filteredNodes.concat([...filteredNodesInPath]);

    const newHighlightNodes = new Set();
    const newHighlightLinks = new Set();

    const filteredLinks = graphData.links.filter((link) => {
      let flag = true;
      if (depthFilter && Number(link.depth) > depthFilter) {
        flag = false;
      }
      if (flag) {
        if (
          Number(txnValueUsdFilterMin) >= 0 &&
          Number(link.value.replace(/,/g, '')) < Number(txnValueUsdFilterMin)
        ) {
          flag = false;
        }
        if (
          Number(txnValueUsdFilterMax) > 0 &&
          Number(link.value.replace(/,/g, '')) > Number(txnValueUsdFilterMax)
        ) {
          flag = false;
        }
      }
      return flag;
    });
    const nodesAssociatedWithFilteredLinks = new Set();
    filteredLinks.forEach((link) => {
      if (!nodesAssociatedWithFilteredLinks.has(link.target.id) && filteredNodes.includes(link.target.id)) {
        nodesAssociatedWithFilteredLinks.add(link.target.id);
      }
      if (!nodesAssociatedWithFilteredLinks.has(link.source.id) && filteredNodes.includes(link.source.id)) {
        nodesAssociatedWithFilteredLinks.add(link.source.id);
      }
      if (
        (isCurrentIdentifier(link.target.id) && filteredNodes.includes(link.source.id)) ||
        (isCurrentIdentifier(link.source.id) && filteredNodes.includes(link.target.id)) ||
        (filteredNodes.includes(link.target.id) && filteredNodes.includes(link.source.id))
      ) {
        newHighlightLinks.add(link);
      }
    });

    filteredNodes.forEach((nodeId) => {
      if (nodesAssociatedWithFilteredLinks.has(nodeId)) {
        newHighlightNodes.add(nodeId);
      }
    });

    setHighlightNodes(newHighlightNodes);
    setHighlightLinks(newHighlightLinks);
    setShowFilters(false);
    setIsFiltersApplied(
      !!(
        entityTypeFilters.length > 0 ||
        entitySubTypeFilters.length > 0 ||
        entityNameFilter ||
        transactionTypeFilter !== 'All' ||
        Number(txnValueUsdFilterMin) > 0 ||
        Number(txnValueUsdFilterMax) > 0 ||
        Number(depthFilter) > 0
      )
    );
  };

  const resetFilterValues = () => {
    setEntityTypeFilters([]);
    setEntitySubTypeFilters([]);
    setEntityNameFilter('');
    setTransactionTypeFilter('All');
    setHighlightNodes(new Set());
    setHighlightLinks(new Set());
    setShowFilters(false);
    setTxnValueUsdFilterMax('');
    setTxnValueUsdFilterMin(0);
    setDepthFilter('');
    setIsFiltersApplied(false);
  };

  const handleControlsFocusOut = useCallback(() => {
    setShowLegends(false);
    setShowFilters(false);
  }, []);

  const linkClicked = (link: ILinkObject) => {
    const isLinkConnectedToOrigin =
      isCurrentIdentifier(link.source.id) || isCurrentIdentifier(link.target.id);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let newDetailsPanelData: any = {};

    if (originType === 'transaction' && isLinkConnectedToOrigin) {
      newDetailsPanelData = {
        type: 'Transaction Details',
        id: link.id,
        from: isCurrentIdentifier(link.source.addresses[0]) ? { addresses: [''] } : link.source,
        to: isCurrentIdentifier(link.target.addresses[0]) ? { addresses: [''] } : link.target,
        txnCount: 1,
        totalAmt: link.numberedValue,
        payload: {
          canLoadMore: false,
        },
        tableData: [
          {
            txnHash: hash,
            amt: link.numberedValue,
            date: null,
          },
        ],
      };
      setDetailsPanelData(newDetailsPanelData);
      setShowDetailsPanel('transaction');
    } else {
      setShowDetailsPanel('transaction');
      const sender = link.source.addresses;
      const receiver = link.target.addresses;
      investigatedTxnMutation.mutate(
        { sender, receiver, currency, limit: 20, offset: 0 },
        {
          onSuccess: (data) => {
            if (!data?.data) {
              return;
            }
            newDetailsPanelData = {
              type: 'Transaction Details',
              id: link.id,
              from: link.source,
              to: link.target,
              txnCount: data?.data.count,
              totalAmt: link.numberedValue,
              payload: {
                canLoadMore: data?.data.count > Number(data?.data.offset) + Number(data?.data.limit),
                count: data?.data.count,
                sender,
                receiver,
                offset: Number(data?.data.offset) + Number(data?.data.limit),
                limit: data?.data.limit,
              },
              tableData: data?.data.results.map((txn) => {
                return {
                  txnHash: txn.txn_id,
                  amt: Number(txn.output_value_usd) + Number(txn?.fee_usd || 0),
                  date: formatDateShort(txn.block_date_time * 1000, timezone),
                };
              }),
            };
            setDetailsPanelData(newDetailsPanelData);
          },
          onError: () => {
            failedToFetchData();
          },
        }
      );
    }
  };

  const nodeClicked = async (node) => {
    const isNodeOrigin = isCurrentIdentifier(node.id);
    const directLinkWithOrigin = graphData.links.find(
      (link) =>
        (isCurrentIdentifier(link.source.id) && link.target.id === node.id) ||
        (isCurrentIdentifier(link.target.id) && link.source.id === node.id)
    );
    if (originType === 'transaction' && (isNodeOrigin || directLinkWithOrigin)) {
      if (isNodeOrigin) {
        const totalAmt =
          Number((identifierDetails as ITransactionResponse).value_usd) +
          Number((identifierDetails as ITransactionResponse)?.fee_usd || 0);
        setDetailsPanelData({
          id: node.id,
          name: node.id,
          type: 'Transaction Details',
          from: null,
          to: null,
          totalAmt,
          payload: {
            canLoadMore: false,
          },
          tableData: [
            {
              txnHash: node.id,
              amt: totalAmt,
            },
          ],
        });

        setShowDetailsPanel('transaction');
      } else {
        const fromReceiversSet = new Set<string>();
        const toSendersSet = new Set<string>();
        let incomingAmt = 0;
        let outgoingAmt = 0;
        let toPayload;
        let fromPayload;

        const originTransactionToResponse = {
          results: [
            {
              txn_id: hash,
              output_value_usd: directLinkWithOrigin.numberedValue,
              fee_usd: 0,
              block_date_time:
                new Date((identifierDetails as ITransactionResponse).block_date_time).getTime() / 1000,
            },
          ],
        };

        graphData.links.forEach((link) => {
          if (link.source.id === node.id) {
            link.target.addresses.forEach((address) => {
              fromReceiversSet.add(address);
            });
            outgoingAmt += link.numberedValue;
          }
          if (link.target.id === node.id) {
            link.source.addresses.forEach((address) => {
              toSendersSet.add(address);
            });
            incomingAmt += link.numberedValue;
          }
        });

        setShowDetailsPanel('address');

        if (directLinkWithOrigin.target.id === node.id) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let fromResponse: any = {};
          try {
            const fromResponseApiData = await investigatedTxnMutation.mutateAsync({
              sender: node.addresses,
              receiver: Array.from(fromReceiversSet),
              currency,
              limit: 20,
              offset: 0,
            });
            fromResponse = fromResponseApiData.data;
            if (!fromResponse) {
              return;
            }
          } catch {
            failedToFetchData();
            return;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let toResponse: any = { ...originTransactionToResponse };

          if (Array.from(toSendersSet).length > 1) {
            toSendersSet.delete(hash);
            const toSenders = Array.from(toSendersSet);
            try {
              const toResponseApiData = await investigatedTxnMutation.mutateAsync({
                sender: toSenders,
                receiver: node.addresses,
                currency,
                limit: 20,
                offset: 0,
              });
              toResponse = toResponseApiData.data;
              if (!toResponse) {
                return;
              }
            } catch {
              failedToFetchData();
              return;
            }

            toResponse.results.unshift(originTransactionToResponse.results[0]);
            toResponse.results.sort((a, b) => b.output_value_usd - a.output_value_usd);
            toResponse.count += 1;

            toPayload = {
              canLoadMore: toResponse.count > Number(toResponse.offset) + 1 + Number(toResponse.limit),
              count: toResponse.count,
              sender: toSenders,
              receiver: node.addresses,
              offset: Number(toResponse.offset) + Number(toResponse.limit),
              limit: toResponse.limit,
            };
          } else {
            // changing the incoming value to the link value
            incomingAmt = directLinkWithOrigin.numberedValue;
            toPayload = { canLoadMore: false };
          }

          fromPayload = {
            canLoadMore: fromResponse.count > Number(fromResponse.offset) + Number(fromResponse.limit),
            count: fromResponse.count,
            sender: node.addresses,
            receiver: Array.from(fromReceiversSet),
            offset: Number(fromResponse.offset) + Number(fromResponse.limit),
            limit: fromResponse.limit,
          };

          setNodeDetailsPanelData(
            node,
            fromResponse,
            toResponse,
            incomingAmt,
            outgoingAmt,
            toPayload,
            fromPayload
          );
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let toResponse: any = {};
          try {
            const toResponseApiData = await investigatedTxnMutation.mutateAsync({
              sender: Array.from(toSendersSet),
              receiver: node.addresses,
              currency,
              offset: 0,
              limit: 20,
            });
            toResponse = toResponseApiData.data;
            if (!toResponse) {
              return;
            }
          } catch {
            failedToFetchData();
            return;
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let fromResponse: any = { ...originTransactionToResponse };

          if (Array.from(fromReceiversSet).length > 1) {
            fromReceiversSet.delete(hash);
            const fromReceivers = Array.from(fromReceiversSet);

            try {
              const fromResponseApiData = await investigatedTxnMutation.mutateAsync({
                sender: node.addresses,
                receiver: Array.from(fromReceivers),
                currency,
                offset: 0,
                limit: 20,
              });

              fromResponse = fromResponseApiData.data;

              if (!fromResponse) {
                return;
              }
            } catch {
              failedToFetchData();
              return;
            }

            fromResponse.results.unshift(originTransactionToResponse.results[0]);
            fromResponse.results.sort((a, b) => b.output_value_usd - a.output_value_usd);
            fromResponse.count += 1;

            fromPayload = {
              canLoadMore: fromResponse.count > Number(fromResponse.offset) + 1 + Number(fromResponse.limit),
              count: fromResponse.count,
              sender: node.addresses,
              receiver: fromReceivers,
              offset: Number(fromResponse.offset) + Number(fromResponse.limit),
              limit: fromResponse.limit,
            };
          } else {
            // changing the incoming value to the link value
            outgoingAmt = directLinkWithOrigin.numberedValue;
            fromPayload = { canLoadMore: false };
          }

          toPayload = {
            canLoadMore: toResponse.count > Number(toResponse.offset) + Number(toResponse.limit),
            count: toResponse.count,
            sender: node.addresses,
            receiver: Array.from(fromReceiversSet),
            offset: toResponse.offset + toResponse.limit,
            limit: toResponse.limit,
          };

          setNodeDetailsPanelData(
            node,
            fromResponse,
            toResponse,
            incomingAmt,
            outgoingAmt,
            toPayload,
            fromPayload
          );
        }
      }
    } else {
      setShowDetailsPanel('address');

      const fromReceiversSet = new Set<string>();
      const toSendersSet = new Set<string>();
      let incomingAmt = 0;
      let outgoingAmt = 0;
      graphData.links.forEach((link) => {
        if (link.source.id === node.id) {
          link.target.addresses.forEach((address) => {
            fromReceiversSet.add(address);
          });
          outgoingAmt += Number(link.numberedValue);
        }
        if (link.target.id === node.id) {
          link.source.addresses.forEach((address) => {
            toSendersSet.add(address);
          });
          incomingAmt += Number(link.numberedValue);
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let fromResponse: any = {};
      try {
        const fromResponseApiData = await investigatedTxnMutation.mutateAsync({
          sender: node.addresses,
          receiver: Array.from(fromReceiversSet),
          currency,
          offset: 0,
          limit: 20,
        });
        fromResponse = fromResponseApiData?.data;
        if (!fromResponse) {
          return;
        }
      } catch {
        failedToFetchData();
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let toResponse: any = {};
      try {
        const toResponseApiData = await investigatedTxnMutation.mutateAsync({
          sender: Array.from(toSendersSet),
          receiver: node.addresses,
          currency,
          offset: 0,
          limit: 20,
        });
        toResponse = toResponseApiData.data;
        if (!toResponse) {
          return;
        }
      } catch {
        failedToFetchData();
        return;
      }

      const toPayload = {
        canLoadMore: toResponse.count > Number(toResponse.offset) + Number(toResponse.limit),
        count: toResponse.count,
        sender: Array.from(toSendersSet),
        receiver: node.addresses,
        offset: toResponse.offset + toResponse.limit,
        limit: toResponse.limit,
      };

      const fromPayload = {
        canLoadMore: fromResponse.count > Number(fromResponse.offset) + Number(fromResponse.limit),
        count: fromResponse.count,
        sender: node.addresses,
        receiver: Array.from(fromReceiversSet),
        offset: fromResponse.offset + fromResponse.limit,
        limit: fromResponse.limit,
      };

      setNodeDetailsPanelData(
        node,
        fromResponse,
        toResponse,
        incomingAmt,
        outgoingAmt,
        toPayload,
        fromPayload
      );
    }

    forceGraphRef.current.centerAt(node.x, node.y, 1000);
  };

  const onNodeHover = (node: NodeObject) => {
    forceGraphContainerRef.current.children[0].firstElementChild.style.cursor = node ? 'pointer' : null;
    // set hover node
    setHoverNode(node || null);
  };

  const onLinkHover = (link: LinkObject) => {
    forceGraphContainerRef.current.children[0].firstElementChild.style.cursor = link ? 'pointer' : null;
    // set hover link
    setHoverLink(link || null);
  };

  const getNodeType = (node): INodeType => {
    return isCurrentIdentifier(node.id) ? 'Origin' : node.addresses.length > 1 ? 'Cluster' : 'Address';
  };

  const setNodeDetailsPanelData = (
    node,
    fromResponse,
    toResponse,
    incomingAmt = null,
    outgoingAmt = null,
    toPayload,
    fromPayload
  ) => {
    const newDetailsPanelData = {
      type: getNodeType(node),
      id: node.id,
      name: node.tag_name_verbose ? node.tag_name_verbose : node.name,
      entityType: node.tag_type_verbose,
      entitySubType: node.tag_subtype_verbose,
      noOfAddresses: node.addresses.length,
      incomingAmt:
        incomingAmt ||
        toResponse.results.map((txn) => txn.output_value_usd).reduce((a, b) => Number(a) + Number(b), 0),
      outgoingAmt:
        outgoingAmt ||
        fromResponse.results.map((txn) => txn.output_value_usd).reduce((a, b) => Number(a) + Number(b), 0),
      addresses: node.addresses,
      toTableData: toResponse.results.map((txn) => ({
        txnHash: txn.txn_id,
        amt: Number(txn.output_value_usd) + Number(txn?.fee_usd || 0),
        date: txn.block_date_time ? formatDateShort(txn.block_date_time * 1000, timezone) : '---',
      })),
      toPayload,
      fromTableData: fromResponse.results.map((txn) => ({
        txnHash: txn.txn_id,
        amt: txn.output_value_usd,
        date: txn.block_date_time ? formatDateShort(txn.block_date_time * 1000, timezone) : '---',
      })),
      fromPayload,
    };
    setDetailsPanelData(newDetailsPanelData);
    if (newDetailsPanelData.fromTableData.length <= 0) {
      setSelectedDirection('to');
    }
  };

  const resetDetailsPanelData = () => {
    setDetailsPanelData(null);
    setShowDetailsPanel('');
    setSelectedDirection('from');
    setShowAddressesTab(false);
  };

  const onTxnTableScroll = () => {
    if (showAddressesTab) {
      return;
    }
    if (
      detailsTableRef.current.scrollTop + detailsTableRef.current.clientHeight >=
      detailsTableRef.current.scrollHeight
    ) {
      loadMoreTableData();
    }
  };

  const failedToFetchData = () => {
    const title = 'Failed to load data.';
    toast.error(title);
    resetDetailsPanelData();
  };

  const loadMoreTableData = async () => {
    if (investigatedTxnMutation.isLoading) {
      return;
    }

    const payload =
      showDetailsPanel === 'transaction'
        ? { ...(detailsPanelData as ITxnDetailsPanelData).payload }
        : selectedDirection === 'to'
          ? { ...(detailsPanelData as IAddressDetailsPanelData).toPayload }
          : { ...(detailsPanelData as IAddressDetailsPanelData).fromPayload };

    if (payload.canLoadMore) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let response: any = {};
      try {
        const responseApiData = await investigatedTxnMutation.mutateAsync({
          sender: payload.sender,
          receiver: payload.receiver,
          offset: payload.offset,
          limit: payload.limit,
          currency,
        });
        response = responseApiData.data;
        if (!response) {
          return;
        }
      } catch {
        failedToFetchData();
        return;
      }

      if (showDetailsPanel === 'address') {
        if (selectedDirection === 'to') {
          const newToTableData = (detailsPanelData as IAddressDetailsPanelData).toTableData.concat(
            response.results.map((txn) => ({
              txnHash: txn.txn_id,
              amt: Number(txn.output_value_usd) + Number(txn?.fee_usd || 0),
              date: txn.block_date_time ? formatDateShort(txn.block_date_time * 1000, timezone) : '---',
            }))
          );

          const canLoadMore = response.count > Number(response.offset) + Number(response.limit);
          const newToPayload = {
            ...(detailsPanelData as IAddressDetailsPanelData).toPayload,
            canLoadMore,
            offset: Number(response.offset) + Number(response.limit),
          };

          setDetailsPanelData({
            ...detailsPanelData,
            toTableData: newToTableData,
            toPayload: newToPayload,
          } as IAddressDetailsPanelData);
        } else {
          const newFromTableData = (detailsPanelData as IAddressDetailsPanelData).fromTableData.concat(
            response.results.map((txn) => ({
              txnHash: txn.txn_id,
              amt: Number(txn.output_value_usd) + Number(txn?.fee_usd || 0),
              date: txn.block_date_time ? formatDateShort(txn.block_date_time * 1000, timezone) : '---',
            }))
          );
          const canLoadMore = response.count > Number(response.offset) + Number(response.limit);
          const newFromPayload = {
            ...(detailsPanelData as IAddressDetailsPanelData).fromPayload,
            canLoadMore,
            offset: Number(response.offset) + Number(response.limit),
          };

          setDetailsPanelData({
            ...detailsPanelData,
            fromTableData: newFromTableData,
            fromPayload: newFromPayload,
          } as IAddressDetailsPanelData);
        }
      } else {
        const newTableData = (detailsPanelData as ITxnDetailsPanelData).tableData.concat(
          response.results.map((txn) => ({
            txnHash: txn.txn_id,
            amt: Number(txn.output_value_usd) + Number(txn.fee_usd),
            date: txn.block_date_time ? formatDateShort(txn.block_date_time * 1000, timezone) : '---',
          }))
        );
        const canLoadMore = response.count > Number(response.offset) + Number(response.limit);
        const newPayload = {
          ...(detailsPanelData as ITxnDetailsPanelData).payload,
          canLoadMore,
          offset: Number(response.offset) + Number(response.limit),
        };
        setDetailsPanelData({
          ...detailsPanelData,
          tableData: newTableData,
          payload: newPayload,
        } as ITxnDetailsPanelData);
      }
    }
  };

  const onEngineStop = () => {
    if (!graphData?.nodes?.length) {
      return;
    }
    if (firstRenderFlag && forceGraphRef?.current) {
      setFirstRenderFlag(false);
      graphData.nodes.forEach((node, index) => {
        // fix node positions and stop the random movement
        graphData.nodes[index] = { ...node, fx: node.x, fy: node.y };
      });
      forceGraphRef?.current?.zoomToFit();
    }
  };

  useEffect(() => {
    if (!isFullscreen) {
      window.scrollTo(forceGraphContainerRef.current);
    }
  }, [isFullscreen]);

  const investigationTreeGetQuery = useQuery(
    ['investigationTreeGet', identifierDetails.id],
    () =>
      investigationTreeApi.getInvestigationTree({
        id: popover ? identifierDetails.identifier : identifierDetails.id,
        currency: identifierDetails.currency,
        originType: originType === 'address' ? 'addresses' : 'transactions',
        isPreview: popover,
      }),
    {
      enabled: false,
      onSuccess: (data) => {
        data?.data
          ? setInvestigationTree(data?.data)
          : setError('Error while loading the investigation tree');
      },
      onError: (err: AxiosError<{ error: string }>) => {
        const errorMessage = getErrorMessage(err);
        if (err.response?.status === 400 && typeof errorMessage === 'string') {
          setError(errorMessage);
        } else {
          setError('Error while loading the investigation tree');
        }
      },
    }
  );

  const investigatedTxnMutation = useMutation(
    !popover
      ? investigationTreeApi.fetchInvestigatedTxnDetails
      : investigationTreeApi.fetchInvestigatedTxnDetailsLite
  );

  useEffect(() => {
    if (hash) {
      investigationTreeGetQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  useEffect(() => {
    if (investigationTree) {
      drawChart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investigationTree]);

  useEffect(() => {
    if (graphData) {
      // for testing
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any)['graphData'] = graphData;
    }
  }, [graphData]);

  useEffect(() => {
    if (forceGraphRef?.current && !isForceSet) {
      forceGraphRef.current.d3Force('collide', d3.forceCollide(100));
      setIsForceSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceGraphRef?.current]);

  useEffect(() => {
    const handleLegendsClickOutside = (event) => {
      if (controlsRef.current && !controlsRef.current.contains(event.target)) {
        handleControlsFocusOut && handleControlsFocusOut();
      }
    };
    document.addEventListener('click', handleLegendsClickOutside, true);
    return () => {
      document.removeEventListener('click', handleLegendsClickOutside, true);
    };
  }, [handleControlsFocusOut]);

  const currGraphHeight = useMemo(() => {
    if (isFullscreen) {
      return window.innerHeight;
    }
    return graphHeight;
  }, [isFullscreen, graphHeight]);

  const currGraphWidth = useMemo(() => {
    if (isFullscreen) {
      return window.innerWidth;
    }
    return graphWidth;
  }, [isFullscreen, graphWidth]);

  const cooldownTime = useMemo(() => {
    let multiplier = 2;
    if (graphData?.nodes?.length) {
      multiplier = graphData?.nodes?.length;
    }
    return Math.max(1000, 50 * multiplier);
  }, [graphData?.nodes?.length]);

  const dagLevelDistance = useMemo(() => {
    let multiplier = 2;
    if (graphData?.nodes?.length) {
      multiplier = graphData?.nodes?.length;
    }
    return Math.max(10 * multiplier, 200);
  }, [graphData?.nodes?.length]);

  const currTableData = useMemo(
    () =>
      (detailsPanelData as ITxnDetailsPanelData)?.tableData
        ? (detailsPanelData as ITxnDetailsPanelData)?.tableData
        : selectedDirection === 'from'
          ? (detailsPanelData as IAddressDetailsPanelData)?.fromTableData
          : (detailsPanelData as IAddressDetailsPanelData)?.toTableData,
    [detailsPanelData, selectedDirection]
  );

  if (
    !investigationTreeGetQuery.isFetching &&
    !investigationTree?.incoming_edges?.length &&
    !investigationTree?.outgoing_edges?.length &&
    !investigationTree?.nodes?.length &&
    !error
  ) {
    return <EmptyState />;
  }

  //TODO: update Error Component for Investigation Graph
  if (error !== '') {
    return <div className='flex h-52 items-center justify-center text-xs text-gray-500'>{error}</div>;
  }

  return (
    <div
      className={classNames(
        _style.container,
        'overflow-hidden transition-all',
        error || investigationTreeGetQuery.isFetching ? 'pointer-events-none h-full w-full opacity-50' : null,
        isFullscreen ? 'fixed left-0 top-0 z-[9999999999] h-screen w-screen bg-white' : 'relative'
      )}>
      {investigationTreeGetQuery.isLoading ? (
        <Skeleton height={'100%'} count={10} containerClassName='p-2 w-full h-full block' />
      ) : (
        <div className='w-full' ref={forceGraphContainerRef}>
          <ForceGraph2D
            ref={forceGraphRef}
            graphData={graphData}
            height={currGraphHeight}
            width={currGraphWidth}
            maxZoom={maxZoom}
            minZoom={minZoom}
            autoPauseRedraw={false}
            nodeId='id'
            nodeVal={10}
            onNodeHover={onNodeHover}
            onZoom={onZoom}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            nodeColor={(node: any) => node.nodeColor}
            nodeCanvasObject={(node, ctx) =>
              drawNode(
                node,
                ctx,
                forceGraphRef?.current?.zoom() | 1,
                graphData,
                hash,
                originType,
                node.id === hoverNode?.id || node.id === detailsPanelData?.id ? 'highlight' : 'normal',
                highlightNodes,
                isFiltersApplied
              )
            }
            nodeCanvasObjectMode={() => 'replace'}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            nodeLabel={(node: any) => {
              if (forceGraphRef?.current.zoom() < 1.3 || (!node.tag_type_verbose && !node.tag_name_verbose)) {
                return node.addresses.length > 1
                  ? `Cluster(${node.addresses.length}): ${node.name}`
                  : node.name;
              }
            }}
            onNodeClick={(node, event) => {
              event.preventDefault();
              resetDetailsPanelData();
              nodeClicked(node);
            }}
            onLinkHover={onLinkHover}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            linkColor={(link: any) => {
              if (link === hoverLink || link.id === detailsPanelData?.id) {
                return (
                  link.color +
                  ((highlightNodes.size === 0 && !isFiltersApplied) || highlightLinks.has(link) ? '' : '10')
                );
              }
              return (
                link.color +
                ((highlightNodes.size === 0 && !isFiltersApplied) || highlightLinks.has(link) ? '80' : '10')
              );
            }}
            linkCurvature={0}
            linkDirectionalArrowLength={0}
            linkDirectionalParticles={0}
            linkDirectionalArrowRelPos={1}
            linkHoverPrecision={4}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            linkWidth={(link: any) => link.width * (forceGraphRef?.current?.zoom() | 1)}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            linkCanvasObject={(link: any, ctx) =>
              drawLink(
                link,
                ctx,
                link === hoverLink || link.id === detailsPanelData?.id ? 'highlight' : 'normal',
                highlightLinks,
                highlightNodes,
                isFiltersApplied
              )
            }
            linkCanvasObjectMode={() => 'after'}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            linkLabel={(link: any) => `$${link.value}`}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onLinkClick={(link: any, event) => {
              event.preventDefault();
              resetDetailsPanelData();
              linkClicked(link);
            }}
            onBackgroundClick={(event) => {
              event.preventDefault();
              resetDetailsPanelData();
            }}
            dagMode='lr'
            dagLevelDistance={dagLevelDistance}
            onEngineStop={onEngineStop}
            cooldownTime={cooldownTime}
            d3VelocityDecay={0.4}
            d3AlphaDecay={0.8}
            onNodeDrag={(node) => {
              const index = graphData.nodes.findIndex((listNode) => listNode.id === node.id);
              graphData.nodes[index].fx = node.x;
              graphData.nodes[index].fy = node.y;
            }}
            onNodeDragEnd={(node) => {
              const index = graphData.nodes.findIndex((listNode) => listNode.id === node.id);
              graphData.nodes[index].fx = node.x;
              graphData.nodes[index].fy = node.y;
            }}
          />
        </div>
      )}
      <div ref={controlsRef}>
        <div className='canvas-controls-container absolute right-4 top-4 z-10 flex border-spacing-px items-center rounded-lg border-gray-400 bg-white p-2 shadow-md'>
          <Button
            variant='tertiary'
            className='mx-1 border-none pb-1 pl-1 pr-1 pt-1 shadow-none'
            data-tooltip-id='investigation-graph-tooltip'
            data-tooltip-content='Fit to screen'
            data-tooltip-place='bottom'
            // className='icon-btn target mx-2'
            onClick={fitGraph}>
            <CornersOut size={20} />
          </Button>
          <Button
            variant='tertiary'
            className='mx-1 border-none pb-1 pl-1 pr-1 pt-1 shadow-none'
            data-tooltip-id='investigation-graph-tooltip'
            data-tooltip-content='Toggle show legends'
            data-tooltip-place='bottom'
            onClick={toggleShowLegends}>
            <ListBullets size={20} />
          </Button>
          <Button
            variant='tertiary'
            className='mx-1 border-none pb-1 pl-1 pr-1 pt-1 shadow-none'
            data-tooltip-id='investigation-graph-tooltip'
            data-tooltip-content='Toggle Fullscreen'
            data-tooltip-place='bottom'
            onClick={toggleFullscreen}>
            {isFullscreen ? <ArrowsInSimple size={20} /> : <ArrowsOutSimple size={20} />}
          </Button>
          <span className='mx-2 inline-block h-[30px] border border-gray-200'></span>
          <Button
            variant='tertiary'
            className='mx-1 border-none pb-1 pl-1 pr-1 pt-1 shadow-none'
            data-tooltip-id='investigation-graph-tooltip'
            data-tooltip-content='Zoom out'
            data-tooltip-place='bottom'
            disabled={disableZoomOut}
            onClick={zoomOut}>
            <MagnifyingGlassMinus size={20} />
          </Button>
          <Button
            variant='tertiary'
            className='mx-1 border-none pb-1 pl-1 pr-1 pt-1 shadow-none'
            data-tooltip-id='investigation-graph-tooltip'
            data-tooltip-content='Zoom in'
            data-tooltip-place='bottom'
            disabled={disableZoomIn}
            onClick={zoomIn}>
            <MagnifyingGlassPlus size={20} />
          </Button>
          <span className='mx-2 inline-block h-[30px] border border-gray-200'></span>
          <Button
            variant='tertiary'
            className='mx-1 border-none pb-1 pl-1 pr-1 pt-1 shadow-none'
            data-tooltip-id='investigation-graph-tooltip'
            data-tooltip-content='Take snapshot'
            data-tooltip-place='bottom'
            onClick={downloadCanvasAsPng}>
            <ImageSquare size={20} />
          </Button>
          <span className='mx-2 inline-block h-[30px] border border-gray-200'></span>
          <Button
            data-tooltip-id='investigation-graph-tooltip'
            data-tooltip-content='Add filters'
            data-tooltip-place='bottom'
            iconStart={<Funnel size={18} weight='fill' />}
            onClick={toggleShowFilters}>
            Filter
          </Button>
        </div>
        {showLegends ? (
          <div
            className={
              'absolute right-3 top-20 flex w-[500px] animate-fadeIn flex-wrap items-center justify-start rounded-md bg-white p-3 text-[11px]'
            }>
            {Object.keys(entityTypes).map((entityType, i) => {
              return (
                <span key={'entityType' + i} className='mb-[5px] mr-3 flex items-center'>
                  <>
                    <div
                      className='mr-1.5 inline-block h-[10px] w-[10px] rounded-sm'
                      style={{ background: entityTypes[entityType] }}
                    />
                    {entityType}
                  </>
                </span>
              );
            })}
          </div>
        ) : (
          ''
        )}
        {showFilters ? (
          <div className='absolute right-[30px] top-20 z-99 w-[40vw] border-spacing-px origin-top-right animate-scaleIn rounded-lg border-gray-100 bg-white p-5 shadow-md'>
            <Search
              label='Entity name'
              placeholder='Enter Entity Name ...'
              value={entityNameFilter}
              setValue={setEntityNameFilter}
            />
            <h3 className='mb-1 mt-2 text-sm font-medium text-gray-500'>Entity types</h3>
            <Select
              closeMenuOnSelect={false}
              isMulti
              options={entityTypesList}
              styles={colorStyles}
              value={entityTypeFilters}
              onChange={(newVal: ReadonlyArray<EntityOption>) => {
                setEntityTypeFilters([...newVal]);
              }}
              placeholder='All entities'
              className='select mb-5'
            />
            {!!defaultEntitySubTypes?.length && (
              <>
                <h3 className='mb-1 mt-2 text-sm font-medium text-gray-500'>Entity subtypes</h3>
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  options={entitySubTypeList}
                  styles={colorStyles}
                  value={entitySubTypeFilters}
                  onChange={(newVal: ReadonlyArray<EntityOption>) => {
                    setEntitySubTypeFilters([...newVal]);
                  }}
                  placeholder='Entity subtypes'
                  className='select mb-5'
                />
              </>
            )}
            <h3 className='text-sm font-medium text-gray-500'>Transaction type</h3>
            <Dropdown
              value={{ value: transactionTypeFilter, label: transactionTypeFilter }}
              options={transactionTypesList}
              onChange={(newVal) => setTransactionTypeFilter(newVal.value as 'Incoming' | 'Outgoing')}
              customClass='border-gray-200'
            />
            <h3 className='mt-4 text-sm font-medium text-gray-500'>Number of hops</h3>
            <Input
              id='depth-filter-input'
              type='number'
              placeholder={`Enter number of hops, min: 1, max: ${maxDepth}`}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(event: any) => {
                if (Number(event?.target?.value) > 0 && Number(event.target.value) <= maxDepth) {
                  setDepthFilter(event.target.value);
                }
              }}
              value={depthFilter}
            />
            <h3 className='mt-4 text-sm font-medium text-gray-500'>Transaction Value</h3>
            <div className='mt-2 flex w-full'>
              <Input
                id='txn-value-filter-min'
                labelText='Minimum'
                labelClassNames='text-gray-500'
                className='mr-2 grow'
                type='number'
                placeholder='Enter min USD value'
                value={txnValueUsdFilterMin}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(event: any) => {
                  if (Number(event?.target?.value) > 0) {
                    setTxnValueUsdFilterMin(event.target.value);
                  }
                }}
              />
              <Input
                id='txn-value-filter-max'
                labelText='Maximum'
                labelClassNames='text-gray-500'
                className='grow'
                type='number'
                placeholder='Enter max USD value'
                value={txnValueUsdFilterMax}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(event: any) => {
                  if (Number(event?.target?.value) > (txnValueUsdFilterMin || 0)) {
                    setTxnValueUsdFilterMax(event.target.value);
                  }
                }}
              />
            </div>
            <div className='mt-4 flex w-full items-center justify-end gap-3'>
              <Button variant='secondary' onClick={resetFilterValues}>
                Reset Filters
              </Button>
              <Button variant='primary' onClick={applyExistingFilters}>
                Apply Filters
              </Button>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {showDetailsPanel ? (
        <div className='absolute right-4 top-32 min-h-[150px] w-[33vw] border-spacing-px rounded-[8px] border-gray-200 bg-gray-50 shadow-md'>
          <button
            className='absolute right-4 top-4 rounded-full bg-gray-300 p-1'
            onClick={() => setShowDetailsPanel('')}>
            <X />
          </button>
          {detailsPanelData && (
            <div className='border-b border-gray-200 p-5'>
              <div className='text-xs text-gray-500'>{detailsPanelData.type}</div>
              {showDetailsPanel === 'address' ? (
                <div>
                  {detailsPanelData.type === 'Cluster' ? (
                    <h3 className='mx-0 mb-3 mt-1.5 text-base font-medium text-gray-600'>
                      {detailsPanelData.name}
                    </h3>
                  ) : (
                    <h3 className='mx-0 mb-3 mt-1.5 flex items-center gap-1.5 text-base font-medium text-gray-600'>
                      {truncateAddress((detailsPanelData as IAddressDetailsPanelData).addresses[0], 21, 9)}
                      <CopyToClipboardBtn
                        text={(detailsPanelData as IAddressDetailsPanelData).addresses[0]}
                        size={17}
                      />
                    </h3>
                  )}
                  <div className='info-container flex w-full flex-col gap-2'>
                    {(detailsPanelData as IAddressDetailsPanelData).entityType &&
                      detailsPanelData.type !== 'Cluster' && (
                        <div className='flex w-full'>
                          <div className='w-[130px] text-xs text-gray-500'>Entity Name</div>
                          <div className='flex grow text-[13px] text-gray-700'>
                            {(detailsPanelData as IAddressDetailsPanelData).name}
                          </div>
                        </div>
                      )}
                    {(detailsPanelData as IAddressDetailsPanelData).entityType && (
                      <div className='flex w-full'>
                        <div className='w-[130px] text-xs text-gray-500'>Entity Info</div>
                        <div className='flex grow items-center text-[13px] text-gray-700'>
                          {(detailsPanelData as IAddressDetailsPanelData).entityType}
                          <CaretRight size={15} />
                          {(detailsPanelData as IAddressDetailsPanelData).entitySubType}
                        </div>
                      </div>
                    )}
                    {Number((detailsPanelData as IAddressDetailsPanelData).noOfAddresses) > 1 && (
                      <div className='flex w-full'>
                        <div className='w-[130px] text-xs text-gray-500'>No of addresses</div>
                        <div className='flex grow text-[13px] text-gray-700'>
                          {(detailsPanelData as IAddressDetailsPanelData).noOfAddresses}
                        </div>
                      </div>
                    )}
                    <div className='flex w-full'>
                      <div className='w-[130px] text-xs text-gray-500'>Incoming Amt</div>
                      <div className='flex grow text-[13px] text-gray-700'>
                        ${formatAmountAsString((detailsPanelData as IAddressDetailsPanelData).incomingAmt, 4)}
                      </div>
                    </div>
                    <div className='flex w-full'>
                      <div className='w-[130px] text-xs text-gray-500'>Outgoing Amt</div>
                      <div className='flex grow text-[13px] text-gray-700'>
                        ${formatAmountAsString((detailsPanelData as IAddressDetailsPanelData).outgoingAmt, 4)}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {!isCurrentIdentifier((detailsPanelData as IAddressDetailsPanelData).name) ? (
                    <h3 className='mx-0 my-[10px] flex items-center text-base font-medium text-gray-600'>
                      {isCurrentIdentifier((detailsPanelData as ITxnDetailsPanelData).from.id)
                        ? 'Origin'
                        : (detailsPanelData as ITxnDetailsPanelData).from.tag_name_verbose
                          ? truncateAddress((detailsPanelData as ITxnDetailsPanelData).from.tag_name_verbose)
                          : truncateAddress((detailsPanelData as ITxnDetailsPanelData).from.addresses[0])}
                      <CaretRight size={15} />
                      {isCurrentIdentifier((detailsPanelData as ITxnDetailsPanelData).to.id)
                        ? 'Origin'
                        : (detailsPanelData as ITxnDetailsPanelData).to.tag_name_verbose
                          ? truncateAddress((detailsPanelData as ITxnDetailsPanelData).to.tag_name_verbose)
                          : truncateAddress((detailsPanelData as ITxnDetailsPanelData).to.addresses[0])}
                    </h3>
                  ) : (
                    <h3 className='mx-0 my-[10px] text-base font-medium text-gray-600'>Origin</h3>
                  )}
                  <div className='info-container w-full'>
                    {(detailsPanelData as ITxnDetailsPanelData).txnCount > 1 ? (
                      <div className='mb-[5px] flex w-full'>
                        <div className='w-[130px] text-xs text-gray-500'>Txn Count</div>
                        <div className='grow text-[13px] text-gray-700'>
                          {(detailsPanelData as ITxnDetailsPanelData).txnCount}
                        </div>
                      </div>
                    ) : (detailsPanelData as ITxnDetailsPanelData).txnCount === 1 ? (
                      <div className='mb-[5px] flex w-full items-center'>
                        <div className='w-[130px] text-xs text-gray-500'>Transaction Hash</div>
                        <div className='flex grow items-center gap-1 text-[13px] text-gray-700'>
                          {truncateAddress((detailsPanelData as ITxnDetailsPanelData).tableData[0].txnHash)}
                          <CopyToClipboardBtn
                            text={(detailsPanelData as ITxnDetailsPanelData).tableData[0].txnHash}
                            size={17}
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='mb-[5px] flex w-full items-center'>
                      <div className='w-[130px] text-xs text-gray-500'>Total Amount</div>
                      <div className='grow text-[13px] text-gray-700'>
                        ${formatAmountAsString((detailsPanelData as ITxnDetailsPanelData).totalAmt, 4)}
                      </div>
                    </div>
                    {(detailsPanelData as ITxnDetailsPanelData).tableData.length === 1 &&
                      (detailsPanelData as ITxnDetailsPanelData).tableData[0].date && (
                        <div className='mb-[5px] flex w-full items-center'>
                          <div className='w-[130px] text-xs text-gray-500'>Date</div>
                          <div className='grow text-[13px] text-gray-700'>
                            {(detailsPanelData as ITxnDetailsPanelData).tableData[0].date}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          )}
          {!investigatedTxnMutation.isLoading && detailsPanelData && showDetailsPanel === 'address' && (
            <div>
              {(detailsPanelData as IAddressDetailsPanelData).addresses.length > 1 && (
                <div className='flex w-full border-spacing-px border-gray-200 bg-white text-sm'>
                  <div
                    className={classNames(
                      'px-5 py-[10px] hover:cursor-pointer active:border-spacing-[2px] active:border-blue-600 active:text-blue-600',
                      !showAddressesTab ? 'active' : ''
                    )}
                    onClick={() => setShowAddressesTab(false)}>
                    Transactions
                  </div>
                  <div
                    className={classNames(
                      'px-5 py-[10px] hover:cursor-pointer active:border-spacing-[2px] active:border-blue-600 active:text-blue-600',
                      showAddressesTab ? 'active' : ''
                    )}
                    onClick={() => setShowAddressesTab(true)}>
                    Addresses
                  </div>
                </div>
              )}
              {showDetailsPanel === 'address' && !showAddressesTab && (
                <div className='w-full bg-gray-50 px-[20px] py-[10px]'>
                  <div className='flex w-full border-spacing-px rounded-full border-gray-300 bg-white p-px'>
                    <button
                      className={classNames(
                        'flex flex-grow border-spacing-0 justify-center rounded-3xl bg-transparent px-3 py-1.5 text-sm outline-none hover:cursor-pointer focus:outline-none disabled:text-gray-300 disabled:hover:cursor-not-allowed',
                        selectedDirection === 'to' ? 'bg-blue-500 text-white' : 'text-gray-600',
                        'disabled:hover:cursor-not-allowed'
                      )}
                      disabled={(detailsPanelData as IAddressDetailsPanelData).toTableData.length <= 0}
                      onClick={() => setSelectedDirection('to')}>
                      To{' '}
                      {isCurrentIdentifier(detailsPanelData.id)
                        ? 'Origin'
                        : (detailsPanelData as IAddressDetailsPanelData).entityType
                          ? truncateName((detailsPanelData as IAddressDetailsPanelData).name)
                          : truncateAddress((detailsPanelData as IAddressDetailsPanelData).addresses[0])}
                    </button>
                    <button
                      className={classNames(
                        'flex flex-grow border-spacing-0 justify-center rounded-3xl bg-transparent px-3 py-1.5 text-sm outline-none hover:cursor-pointer focus:outline-none disabled:text-gray-300 disabled:hover:cursor-not-allowed',
                        selectedDirection === 'from' ? 'bg-blue-500 text-white' : 'text-gray-600',
                        'disabled:hover:cursor-not-allowed'
                      )}
                      disabled={(detailsPanelData as IAddressDetailsPanelData).fromTableData.length <= 0}
                      onClick={() => setSelectedDirection('from')}>
                      From{' '}
                      {isCurrentIdentifier(detailsPanelData.id)
                        ? 'Origin'
                        : (detailsPanelData as IAddressDetailsPanelData).entityType
                          ? truncateName((detailsPanelData as IAddressDetailsPanelData).name)
                          : truncateAddress((detailsPanelData as IAddressDetailsPanelData).addresses[0])}
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          {!investigatedTxnMutation.isLoading &&
            detailsPanelData &&
            (((detailsPanelData as ITxnDetailsPanelData).tableData &&
              (detailsPanelData as ITxnDetailsPanelData).tableData.length > 1) ||
              ((detailsPanelData as IAddressDetailsPanelData).toTableData &&
                (detailsPanelData as IAddressDetailsPanelData).toTableData.length) ||
              ((detailsPanelData as IAddressDetailsPanelData).fromTableData &&
                (detailsPanelData as IAddressDetailsPanelData).fromTableData.length)) && (
              <div
                ref={detailsTableRef}
                className={classNames(
                  'table-container overflow-y-auto bg-white',
                  showAddressesTab ? 'max-h-[20rem]' : 'max-h-[13rem]'
                )}
                onScroll={onTxnTableScroll}>
                <table className='w-full'>
                  <thead className='sticky top-0 z-[9] border-spacing-px border-gray-200 bg-gray-100 text-xs text-gray-500'>
                    {!showAddressesTab ? (
                      <tr className='text-left'>
                        <th className='px-5 py-2.5'>TXN. HASH</th>
                        <th className='px-5 py-2.5'>Amount</th>
                        <th className='px-5 py-2.5'>Date</th>
                      </tr>
                    ) : (
                      <tr className='text-left'>
                        <th className='px-5 py-2.5'>ADDRESSES</th>
                      </tr>
                    )}
                  </thead>
                  {!showAddressesTab ? (
                    <tbody className='text-xs'>
                      {currTableData.map((data, i) => {
                        return (
                          <tr key={i} className='even:bg-gray-50'>
                            <td className='px-5 py-2.5'>
                              <code className='flex items-center gap-1.5'>
                                {truncateAddress(data.txnHash, 15, 6)}
                                <CopyToClipboardBtn text={data.txnHash} size={17} />
                              </code>
                            </td>
                            <td className='px-5 py-2.5'>${formatAmountAsString(data.amt, 4)}</td>
                            <td className='px-5 py-2.5'>{data.date}</td>
                          </tr>
                        );
                      })}
                      {investigatedTxnMutation.isLoading && (
                        <tr className='text-center even:bg-gray-50'>
                          <td className='px-5 py-2' colSpan={3}>
                            Loading...
                          </td>
                        </tr>
                      )}
                    </tbody>
                  ) : (
                    <tbody className='text-xs'>
                      {(detailsPanelData as IAddressDetailsPanelData).addresses.map((data, i) => {
                        return (
                          <tr key={i}>
                            <td className='flex items-center gap-1.5 px-5 py-2.5'>
                              {truncateAddress(data, 19, 8)}
                              <CopyToClipboardBtn text={data} size={17} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            )}

          {investigatedTxnMutation.isLoading && (
            <div className='p-3'>
              <Skeleton count={1} height={25} className='mb-3' />
              <div className='w-5/12'>
                <Skeleton count={3} height={15} />
              </div>
            </div>
          )}
        </div>
      ) : (
        ''
      )}
      <Tooltip id='investigation-graph-tooltip' />
    </div>
  );
};

export default InvestigationTree;
