import { FC, useEffect, useState } from 'react';
import { TabHeader } from '../../../../components/ui/components/Header';
import CheckboxController from '../../../../ui/components/Form/CheckboxController';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useAutoAssignWorkflowGet, workflowSettings } from '../../../../api/settings/workflowSettings';
import {
  IAutoAssignWorkflowRequest,
  IWorkflowStageResult,
} from '../../../../api/dtos/settings/workflowSettings';
import { Role, Stage } from '../../../../ui/utils/constants';
import RoleBadge from '../../../../ui/components/Badges/RoleBadge/RoleBadge';
import StageBadge from '../../../../ui/components/Badges/StageBadge/StageBadge';
import SingleSelect, { IOption } from '../../../../components/ui/components/Select/SingleSelect';
import { useStagesListGet } from '../../../../api/stages';
import AssignmentWorkflowMatrix from './AssignmentMatrix';
import { getColorMappingStageOptions } from '../../../../utils/helpers/stageOptionsColor';
import Loader from '@/assets/loader.gif';

interface AssignmentWorkflowProps {}

export interface IStageOptions extends IOption {
  id?: Stage;
}

const initialData = {
  riskLevels: {
    '1': false,
    '2': false,
    '3': false,
    '4': false,
    '5': false,
  },
};

const AssignmentWorkflow: FC<AssignmentWorkflowProps> = () => {
  const { control } = useForm({
    defaultValues: initialData,
  });
  const { data: responseAutoAssign, isLoading: isAutoAssignLoading } = useAutoAssignWorkflowGet();
  const [AutoAssignAlerts, setAutoAssignAlerts] = useState(
    responseAutoAssign?.data.auto_assign_workflow_risk_levels || []
  );
  const { data: stageData, isLoading } = useStagesListGet();
  const stageColorMap = getColorMappingStageOptions(stageData?.data?.results);
  const [currentStage, setCurrentStage] = useState(
    responseAutoAssign?.data.auto_assign_workflow_stage
      ? {
          id: responseAutoAssign?.data?.auto_assign_workflow_stage as Stage,
          label: responseAutoAssign?.data?.auto_assign_workflow_stage_name,
          value: responseAutoAssign?.data?.auto_assign_workflow_stage_name,
        }
      : null
  );
  const getAssigneeToOptions = (assigneeToData: IWorkflowStageResult[]) => {
    return assigneeToData
      ?.map((r) => {
        if (r.is_final_stage === false) {
          return {
            id: r.id,
            value: r.name,
            label: r.name,
          };
        }
      })
      .filter(Boolean);
  };

  const autoAssignStageOptions = getAssigneeToOptions(stageData?.data?.results);

  useEffect(() => {
    setAutoAssignAlerts(responseAutoAssign?.data?.auto_assign_workflow_risk_levels);
    setCurrentStage(
      responseAutoAssign?.data.auto_assign_workflow_stage
        ? {
            id: responseAutoAssign?.data?.auto_assign_workflow_stage as Stage,
            label: responseAutoAssign?.data?.auto_assign_workflow_stage_name,
            value: responseAutoAssign?.data?.auto_assign_workflow_stage_name,
          }
        : null
    );
  }, [responseAutoAssign]);

  const mutateUpdateAutoAssignAlerts = useMutation(workflowSettings.updateAutoAssignWorkflow, {
    onSuccess: (data) => {
      setAutoAssignAlerts(data?.data?.auto_assign_workflow_risk_levels);
      if (data?.data?.auto_assign_workflow_stage) {
        const foundStage = stageData?.data?.results?.find(
          (item) => item.id === data?.data?.auto_assign_workflow_stage
        );
        setCurrentStage(
          data?.data?.auto_assign_workflow_stage
            ? {
                id: foundStage?.id,
                label: foundStage?.name,
                value: foundStage?.name,
              }
            : null
        );
      }
      toast.success('Assignment Workflow updated Successfully');
    },
    onError: () => {
      toast.error('Unable to update Auto Assign');
    },
  });

  const handleChangeStage = (stage: IStageOptions) => {
    const request: IAutoAssignWorkflowRequest = {
      auto_assign_workflow_stage: stage?.id,
    };
    mutateUpdateAutoAssignAlerts.mutate(request);
  };

  const toggleSelection = (data: number) => {
    const newAutoAssignedAlerts = AutoAssignAlerts?.includes(data)
      ? AutoAssignAlerts?.filter((item) => item !== data)
      : [...AutoAssignAlerts, data];
    setAutoAssignAlerts(newAutoAssignedAlerts);
    const request: IAutoAssignWorkflowRequest = {
      auto_assign_workflow_risk_levels: newAutoAssignedAlerts,
    };

    mutateUpdateAutoAssignAlerts.mutate(request);
  };

  const getCustomOptionLabel = (option: IStageOptions) => {
    return <StageBadge role={stageColorMap[option.id]} label={option.label} />;
  };

  return (
    <div className='w-75'>
      <div className='mb-6 flex items-center gap-2'>
        <TabHeader heading='Product Preferences > Assignment Workflow' />
        {(isLoading || isAutoAssignLoading || mutateUpdateAutoAssignAlerts.isLoading) && (
          <img src={Loader} alt='Loader' className='h-12' />
        )}
      </div>
      <div>
        <div>
          <h4 className='mt-5 text-base'>Auto Assign</h4>
          <TabHeader
            heading=''
            subheading='Configure auto-assignment to stages based on object’s (address, transaction or customer) risk level'
          />

          <div className='flex gap-4 pt-3'>
            <CheckboxController
              control={control}
              name='riskLevels.1'
              checked={AutoAssignAlerts?.includes(5)}
              onChange={() => toggleSelection(5)}
              labelNode={
                <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                  <div className='text-sm font-medium'>Critical</div>
                </div>
              }
            />
            <CheckboxController
              control={control}
              name='riskLevels.2'
              onChange={() => toggleSelection(4)}
              checked={AutoAssignAlerts?.includes(4)}
              labelNode={
                <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                  <div className='text-sm font-medium'>High</div>
                </div>
              }
            />
            <CheckboxController
              control={control}
              name='riskLevels.3'
              checked={AutoAssignAlerts?.includes(3)}
              onChange={() => toggleSelection(3)}
              labelNode={
                <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                  <div className='text-sm font-medium'>Medium</div>
                </div>
              }
            />
            <CheckboxController
              control={control}
              name='riskLevels.4'
              checked={AutoAssignAlerts?.includes(2)}
              onChange={() => toggleSelection(2)}
              labelNode={
                <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                  <div className='text-sm font-medium'>Caution</div>
                </div>
              }
            />
            <CheckboxController
              control={control}
              name='riskLevels.5'
              onChange={() => toggleSelection(1)}
              checked={AutoAssignAlerts?.includes(1)}
              labelNode={
                <div className='flex w-full max-w-screen-sm flex-col pl-3'>
                  <div className='text-sm font-medium'>Info</div>
                </div>
              }
            />
          </div>
          <div>
            <h4 className='mt-5 text-base'>Auto Assign To</h4>
            <div className='w-1/4'>
              <SingleSelect
                placeholder='Assign to'
                options={autoAssignStageOptions}
                handleChange={handleChangeStage}
                isLoading={false}
                getCustomLabel={getCustomOptionLabel}
                getCustomOptionLabel={getCustomOptionLabel}
                value={currentStage}
                isCloseHidden={true}
                showRadioButton={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='mt-6 w-3/4'>
        <hr />
      </div>
      <AssignmentWorkflowMatrix
        isLoading={isLoading}
        stageData={stageData?.data?.results}
        currentStage={currentStage}
      />
      {!isLoading && stageData?.data?.results?.length !== 0 && (
        <div className='flex flex-col gap-4'>
          <div className='flex gap-4 text-base'>
            <h4 className='mt-5 w-1/3'>Current Stage</h4>
            <h4 className='mt-5 w-1/3'>Next Stage(s)</h4>
            <h4 className='mt-5 w-1/3'>Assignee Role(s)</h4>
          </div>
          {currentStage?.label && (
            <div className='flex gap-4'>
              <div className='flex w-1/3 items-center rounded-lg border border-gray-200 bg-gray-50 p-2 text-xs font-medium'>
                <StageBadge role={7} label='-NA-' />
              </div>
              <div className='flex w-1/3 items-center rounded-lg border border-gray-200 bg-gray-50 p-2 text-xs font-medium'>
                <StageBadge role={stageColorMap[currentStage?.id]} label={currentStage?.label} />
              </div>
              <div className='flex w-1/3 items-center rounded-lg border border-gray-200 bg-gray-50 p-2 text-xs font-medium'></div>
            </div>
          )}
          {stageData?.data?.results?.map((item) => {
            return (
              <div key={item.id} className='flex gap-4'>
                <div className='flex w-1/3 items-center rounded-lg border border-gray-200 bg-gray-50 p-2 text-xs font-medium'>
                  <StageBadge role={stageColorMap[item.id]} label={item.name} />
                </div>
                {!item.is_final_stage && (
                  <div className='flex w-1/3 items-center gap-1 rounded-lg border border-gray-200 bg-gray-50 p-2 text-xs font-medium'>
                    {item.next_stages.map((next_stage) => {
                      return (
                        <StageBadge
                          key={next_stage.id}
                          role={stageColorMap[next_stage.id]}
                          label={next_stage.name}
                        />
                      );
                    })}
                  </div>
                )}
                {!item.is_final_stage && (
                  <div className='flex w-1/3 items-center gap-1 rounded-lg border border-gray-200 bg-gray-50 p-2 text-xs font-medium'>
                    {item.assignee_roles.map((assignee: Role) => {
                      return <RoleBadge key={assignee} role={assignee} />;
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      <footer id='footer' className='text-gray flex items-center justify-between pt-4 text-sm'>
        <span className=''>
          Note : Reach{' '}
          <a href='mailto:support@merklescience.com' className='text-blue-600'>
            support@merklescience.com
          </a>{' '}
          for any modifications in assignment workflows
        </span>
      </footer>
    </div>
  );
};

export default AssignmentWorkflow;
