import { FC } from 'react';
import { IAlert } from '../../../api/dtos/alert';
import { BasicBadge, RiskBadge } from '../../../ui';
import Rule from '../../Risks/Rule';
import { formatDateTime } from '../../../utils/helpers/date';
import { useRouter } from '../../../modules/router/RouterProvider';

interface IAlertReportProps {
  alert: IAlert;
}

const AlertReport: FC<IAlertReportProps> = ({ alert }) => {
  const { getQueryParams } = useRouter();
  const timezone = getQueryParams().tz;
  return (
    <div className='break-inside-avoid border pl-2 pr-4'>
      <div className='my-2 flex'>
        <RiskBadge risk={alert.level} />
        <div className='my-auto ml-2 flex text-xs font-bold'>{alert.alert_body}</div>
      </div>
      {alert.rule_register.rule_conditions.map((r, i) => (
        <div key={i} className='my-2 text-xs leading-6 text-gray-500'>
          <Rule ruleType={r.rule_condition_type} type='address' {...r} />
          {i !== alert.rule_register.rule_conditions.length - 1 && <div className='text-gray-500'>AND</div>}
        </div>
      ))}
      <div className='mt-4 grid grid-cols-8 text-xs'>
        <div>
          Escalated <div>{alert.alert_items_meta.count.escalated}</div>
        </div>
        <div>
          Open <div>{alert.alert_items_meta.count.open}</div>
        </div>
        <div>
          Resolved <div>{alert.alert_items_meta.count.resolved}</div>
        </div>
        <div className='col-span-2'>
          Category
          <div>
            <BasicBadge className='mb-2 mr-auto bg-gray-200 px-4'>
              {alert.rule_register.policy_category_verbose}{' '}
            </BasicBadge>
          </div>
        </div>
        <div>
          Risk Type
          <div className='flex flex-col'>
            {alert.rule_register.risk_types.map((risk) => (
              <BasicBadge className='mb-2 mr-auto bg-gray-200 px-4' key={risk.risk_type}>
                {risk.risk_type_verbose}
              </BasicBadge>
            ))}
          </div>
        </div>
        <div className='col-span-2'>
          Latest Alert Time <div>{formatDateTime(alert.alert_items_meta.last_alert, timezone)}</div>
        </div>
      </div>
    </div>
  );
};

export default AlertReport;
