import { UseQueryOptions, useInfiniteQuery, useQuery } from 'react-query';
import { Api } from './api';
import {
  IAddAddressRequest,
  IAddCustomerRequest,
  IAddTransactionRequest,
  IArchiveEntityRequest,
  ICaseAddressResponse,
  ICaseAuditTrailRequest,
  ICaseAuditTrailResponse,
  ICaseCustomerResponse,
  ICaseEntityRequest,
  ICaseReportRequest,
  ICaseResponse,
  ICaseResult,
  ICaseTransactionResponse,
  ICaseUpdateStatusRequest,
  ICaseAssignAgentRequest,
  ICaseUpdateRequest,
  ICreateCaseRequest,
  IGetCaseRequest,
  ICaseResolveAlerts,
} from './dtos/case';
import { getNextPageParam } from '../utils/helpers/helperFunctions';
import { getTypeApi } from '../utils/helpers/apiHelpers';
import { ICaseBulkActionFilters } from '../views/dashboard/cases/CaseList';

export class CaseApi extends Api {
  readonly baseUrl: string = '/resolutioncenter/api/v4/cases/';

  readonly getCaseList = (args: IGetCaseRequest) => {
    const { q, offset = 0, filters } = args;
    return this.http.get<ICaseResult>(this.route('/'), { params: { q, offset, ...filters } });
  };

  readonly getCase = (caseId: number) => {
    return this.http.get<ICaseResponse>(this.route(`/${caseId}/`));
  };

  readonly updateCase = (args: ICaseUpdateRequest) => {
    const { caseId, caseName, status, assigned_to } = args;
    return this.http.put<ICaseResponse>(this.route(`/${caseId}/`), { name: caseName, status, assigned_to });
  };

  readonly updateCaseStatus = (args: ICaseUpdateStatusRequest, filters: ICaseBulkActionFilters) => {
    const { cases, status, comment } = args;
    return this.http.put<{ message: string }>(
      this.route('bulk/'),
      {
        ids: cases,
        status,
        comment: {
          body: comment,
          context: {
            alert_items: [],
            alerts: [],
            users: [],
          },
        },
      },
      { params: { ...filters } }
    );
  };

  readonly assignAgent = (args: ICaseAssignAgentRequest, filters: ICaseBulkActionFilters) => {
    const { cases, assigned_to } = args;
    return this.http.put<{ message: string }>(
      this.route('bulk/'),
      {
        ids: cases,
        assigned_to,
      },
      { params: { ...filters } }
    );
  };

  readonly deleteCases = (cases: number[], filters: ICaseBulkActionFilters) => {
    return this.http.delete<{ message: string }>(this.route('/bulk/'), {
      data: {
        ids: cases,
      },
      params: { ...filters },
    });
  };

  readonly createCase = (args: ICreateCaseRequest) => {
    const {
      name,
      assigned_to,
      addresses,
      transactions,
      customers,
      filters,
      workflow_stage,
      closed,
      entity_type,
      add_assignee_payload,
    } = args;

    if (add_assignee_payload) {
      return this.http.post(this.route(`/`), {
        name,
        addresses,
        transactions,
        customers,
        assigned_to,
      });
    } else if (closed) {
      return this.http.post(
        this.route(`/`),
        {
          name,
          addresses,
          transactions,
          customers,
        },
        {
          params: { ...filters, workflow_stage, entity_type },
        }
      );
    }
    return this.http.post(
      this.route(`/`),
      {
        name,
        addresses,
        transactions,
        customers,
      },
      {
        params: { assigned_to, workflow_stage, ...filters, entity_type },
      }
    );
  };

  readonly exportCaseList = (ids: number[], filters?: ICaseBulkActionFilters) => {
    const idsString = ids.length ? `?ids=${ids.join(',')}` : ``;
    return this.http.get<{
      status: string;
      message: string;
    }>(this.route(`/export/${idsString}`), { params: { ...filters } });
  };

  readonly updateCaseAlerts = (params: ICaseResolveAlerts) => {
    const { filters, status, ids, comment } = params;
    if (closed) {
      return this.http.put<{
        status: string;
        message: string;
      }>(
        this.route(`/update-alerts/?ids=${ids.join(',')}`),
        {
          status: 1,
          ...(comment && { comment: { body: comment, context: { alerts: [], alert_items: [], users: [] } } }),
        },
        {
          params: { ...filters, status },
        }
      );
    }
    return this.http.put<{
      status: string;
      message: string;
    }>(
      this.route(`/update-alerts/?ids=${ids.join(',')}`),
      {
        status: 1,
        ...(comment && { comment: { body: comment, context: { alerts: [], alert_items: [], users: [] } } }),
      },
      {
        params: { status, ...filters },
      }
    );
  };

  readonly addAddress = (params: IAddAddressRequest) => {
    const { case_id, addresses, filters, assigned_to, workflow_stage, closed, entity_type } = params;

    if (closed) {
      return this.http.post(
        this.route(`/${case_id}/addresses/`),
        { addresses },
        {
          params: { assigned_to, ...filters, workflow_stage, entity_type },
        }
      );
    }
    return this.http.post(
      this.route(`/${case_id}/addresses/`),
      { addresses },
      {
        params: { assigned_to, workflow_stage, ...filters, entity_type },
      }
    );
  };

  readonly addTransaction = (params: IAddTransactionRequest) => {
    const { case_id, transactions, filters, assigned_to, workflow_stage, closed, entity_type } = params;

    if (closed) {
      return this.http.post(
        this.route(`/${case_id}/transactions/`),
        { transactions },
        {
          params: { assigned_to, ...filters, workflow_stage, entity_type },
        }
      );
    }
    return this.http.post(
      this.route(`/${case_id}/transactions/`),
      { transactions },
      {
        params: { assigned_to, workflow_stage, ...filters, entity_type },
      }
    );
  };

  readonly addCustomer = (params: IAddCustomerRequest) => {
    const { case_id, customers, filters, assigned_to, workflow_stage, closed, entity_type } = params;

    if (closed) {
      return this.http.post(
        this.route(`/${case_id}/customers/`),
        { customers },
        {
          params: { assigned_to, ...filters, workflow_stage, entity_type },
        }
      );
    }
    return this.http.post(
      this.route(`/${case_id}/customers/`),
      { customers },
      {
        params: { assigned_to, workflow_stage, ...filters, entity_type },
      }
    );
  };

  readonly getAddresses = (args: ICaseEntityRequest) => {
    const { caseId, offset = 0, limit = 25 } = args;
    return this.http.get<ICaseAddressResponse>(this.route(`/${caseId}/addresses/`), {
      params: { offset, limit },
    });
  };

  readonly getTransactions = (args: ICaseEntityRequest) => {
    const { caseId, offset = 0, limit = 25 } = args;
    return this.http.get<ICaseTransactionResponse>(this.route(`/${caseId}/transactions/`), {
      params: { offset, limit },
    });
  };

  readonly getCustomers = (args: ICaseEntityRequest) => {
    const { caseId, offset = 0, limit = 25 } = args;
    return this.http.get<ICaseCustomerResponse>(this.route(`/${caseId}/customers/`), {
      params: { offset, limit },
    });
  };

  readonly archiveEntity = (args: IArchiveEntityRequest) => {
    const { caseId, entityId, type } = args;
    return this.http.delete(this.route(`/${caseId}/${getTypeApi(type)}/${entityId}/archive/`));
  };

  readonly getCaseReport = (params: ICaseReportRequest) => {
    const { id, entities } = params;
    return this.http.get(this.route(`${id}/report/`), {
      params: { entities },
      responseType: 'blob',
    });
  };

  readonly deleteCase = (caseId: string) => {
    return this.http.delete(this.route(`/${caseId}/`));
  };

  readonly getAuditTrail = (params: ICaseAuditTrailRequest) => {
    const { caseId, offset, limit, filters } = params;
    return this.http.get<ICaseAuditTrailResponse>(this.route(`/${caseId}/audit-trail/`), {
      params: {
        offset,
        limit,
        type: filters.type,
        created_start_date: filters.start_date,
        created_end_date: filters.end_date,
      },
    });
  };
}

export const caseApi = new CaseApi();

export const useCaseGetCaseList = (params: IGetCaseRequest, options?: UseQueryOptions) => {
  const key = ['caseApi.getCustomerList', params.filters];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => caseApi.getCaseList({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );

  return result;
};

export const useCaseSearch = ({ query }: { query: string }, options?: UseQueryOptions) => {
  const key = ['caseApi', 'searchCustomers', query];
  const result = useInfiniteQuery(
    key,
    ({ pageParam }) => caseApi.getCaseList({ offset: pageParam, q: query }),
    {
      getNextPageParam,
      ...options,
    } as unknown
  );
  return {
    key,
    ...result,
  };
};

export const useCaseGetCase = (caseId: number, options?: UseQueryOptions) => {
  const key = ['caseApi', caseId, 'getCase'];
  const result = useQuery(key, () => caseApi.getCase(caseId), { ...options } as unknown);
  return {
    key,
    ...result,
  };
};

export const useCaseGetAddresses = (caseId: number, options?: UseQueryOptions) => {
  const key = ['caseApi', caseId, 'getAddresses'];
  const result = useInfiniteQuery(
    key,
    ({ pageParam }) => caseApi.getAddresses({ caseId, offset: pageParam }),
    {
      getNextPageParam,
      ...options,
    } as unknown
  );
  return {
    key,
    ...result,
  };
};

export const useCaseGetTransactions = (caseId: number, options?: UseQueryOptions) => {
  const key = ['caseApi', caseId, 'getTransactions'];
  const result = useInfiniteQuery(
    key,
    ({ pageParam }) => caseApi.getTransactions({ caseId, offset: pageParam }),
    {
      getNextPageParam,
      ...options,
    } as unknown
  );
  return {
    key,
    ...result,
  };
};

export const useCaseGetCustomers = (caseId: number, options?: UseQueryOptions) => {
  const key = ['caseApi', caseId, 'getCustomers'];
  const result = useInfiniteQuery(
    key,
    ({ pageParam }) => caseApi.getCustomers({ caseId, offset: pageParam }),
    {
      getNextPageParam,
      ...options,
    } as unknown
  );
  return {
    key,
    ...result,
  };
};
