import { useQuery, UseQueryOptions, useInfiniteQuery } from 'react-query';
import { Api } from './api';
import {
  IAddressResponse,
  IAddressListParams,
  IAddressListResponse,
  IAggregatedResponse,
  IAddressTransactionsRequest,
  IAddressTransactionsResponse,
  IAddressTransactionsExportRequest,
  IAddressTransactionsExportResponse,
  ISentimentSummaryResponse,
  ISentimentListRequest,
  ISentimentListResponse,
  IAddressScreenRequest,
  IAddressReportRequest,
  IAddressMonitoringRequest,
  IAddKnowThisAddressCommentRequest,
  ITokenSummaryResponse,
  ICustomTagUpdateRequest,
  IAddressIOTransactionRequest,
  IAddressIOTransactionResponse,
  IAddressIOTransactionsExportRequest,
  IAddressTransactionsExportCSVRequest,
  IAddressExportList,
  IAddressUpdateAlerts,
} from './dtos/address';
import { getNextPageParam, getTransactionTypeValue } from '../utils/helpers/helperFunctions';
import { IMessageResponse } from './dtos/common';
import { getNextPageParamByOffsetTs } from '../utils/helpers/react-query.helper';

export class AddressApi extends Api {
  readonly baseUrl: string = '/risk/api/v4/addresses';

  readonly getAddressList = (params: IAddressListParams) => {
    const { offset, filters, assigned_to, workflow_stage, closed } = params;

    if (closed) {
      return this.http.get<IAddressListResponse>(this.route(`/`), {
        params: { assigned_to, limit: 20, offset, ...filters, workflow_stage },
      });
    }
    return this.http.get<IAddressListResponse>(this.route(`/`), {
      params: { assigned_to, workflow_stage, limit: 20, offset, ...filters },
    });
  };

  readonly getAddressNameVerboses = () => {
    return this.http.get<IAddressListResponse>(this.route(`/direct-tag-data/name-verboses/`));
  };

  readonly getAddress = (address: number) => {
    return this.http.get<IAddressResponse>(this.route(address + '/'));
  };

  readonly getAddressTagIncomingValues = (address: number) => {
    return this.http.get<IAggregatedResponse>(this.route(`${address}/tag-aggregated-incoming-values/`));
  };

  readonly getAddressTagOutgoingValues = (address: number) => {
    return this.http.get<IAggregatedResponse>(this.route(`${address}/tag-aggregated-outgoing-values/`));
  };

  readonly screenAddress = (params: IAddressScreenRequest) => {
    const { identifier, currency, customer_id = null, type = null, create_case = true } = params;
    return this.http.post<IAddressResponse>(this.route('/'), {
      identifier,
      currency,
      // Only pass create_case when value is false
      ...(!create_case && { create_case }),
      ...(customer_id && { customer_id, type }),
    });
  };

  readonly updateAddressAlerts = (params: IAddressUpdateAlerts) => {
    const { ids, filters, assigned_to, workflow_stage, closed, comment } = params;

    if (closed) {
      return this.http.put<IAddressTransactionsExportResponse>(
        this.route(`/update-alerts/?ids=${ids.join(',')}`),
        {
          status: 1,
          ...(comment && {
            comment: { body: comment, context: { alerts: [], alert_items: [], users: [] } },
          }),
        },
        {
          params: {
            assigned_to,
            ...filters,
            workflow_stage,
          },
        }
      );
    }
    return this.http.put<IAddressTransactionsExportResponse>(
      this.route(`/update-alerts/?ids=${ids.join(',')}`),
      {
        status: 1,
        ...(comment && { comment: { body: comment, context: { alerts: [], alert_items: [], users: [] } } }),
      },
      {
        params: {
          assigned_to,
          workflow_stage,
          ...filters,
        },
      }
    );
  };

  readonly getIOTransactions = (params: IAddressIOTransactionRequest) => {
    const { address, type, offset = 0, filters } = params;
    return this.http.get<IAddressIOTransactionResponse>(this.route(`${address}/${type}-transactions/`), {
      params: { offset, limit: 20, ...filters },
    });
  };

  readonly getTransactions = (params: IAddressTransactionsRequest) => {
    const { address, filters, offset_ts } = params;
    const { type, ...restFilters } = filters;

    return this.http.get<IAddressTransactionsResponse>(this.route(`${address}/transactions/`), {
      params: { offset_ts, limit: 20, transaction_type: getTransactionTypeValue(type), ...restFilters },
    });
  };

  readonly exportIOTransactions = ({ address, type }: IAddressIOTransactionsExportRequest) => {
    return this.http.get<IAddressTransactionsExportResponse>(
      this.route(`${address}/${type}-transactions/export/`)
    );
  };

  readonly exportTransactions = ({ id, type }: IAddressTransactionsExportRequest) => {
    return this.http.get<IAddressTransactionsExportResponse>(this.route(`${id}/transactions/export/`), {
      params: { transaction_type: type },
    });
  };

  readonly exportAddressList = (params: IAddressExportList) => {
    const { ids, filters, assigned_to, workflow_stage, closed } = params;

    if (closed) {
      return this.http.get<IAddressTransactionsExportResponse>(this.route(`/export/?ids=${ids.join(',')}`), {
        params: { assigned_to, ...filters, workflow_stage },
      });
    }
    return this.http.get<IAddressTransactionsExportResponse>(this.route(`/export/?ids=${ids.join(',')}`), {
      params: { assigned_to, workflow_stage, ...filters },
    });
  };

  readonly exportTransactionsCSV = (params: IAddressTransactionsExportCSVRequest) => {
    const { address, filters } = params;
    const { type, ...restFilters } = filters;

    return this.http.get(this.route(`${address}/transactions/direct-export/`), {
      params: { transaction_type: getTransactionTypeValue(type), ...restFilters },
      responseType: 'blob',
    });
  };

  readonly getSentimentSummary = (id: string | number) => {
    return this.http.get<ISentimentSummaryResponse>(this.route(`${id}/sentiment-summary/`));
  };
  readonly getSentimentList = (params: ISentimentListRequest) => {
    const { id, offset = 0, limit = 15 } = params;
    return this.http.get<ISentimentListResponse>(this.route(`${id}/sentiments/`), {
      params: { offset, limit },
    });
  };
  readonly getAddressReport = (params: IAddressReportRequest) => {
    const { id, entities } = params;
    return this.http.get(this.route(`${id}/report/`), {
      params: { entities },
      responseType: 'blob',
    });
  };
  readonly archiveAddress = (address: number) => {
    return this.http.delete(this.route(`${address}/`));
  };
  readonly setMonitorAddress = (params: IAddressMonitoringRequest) => {
    const { address, isMuted } = params;
    return this.http.put(this.route(`${address}/monitoring/`), { is_muted: isMuted });
  };
  readonly addKnowThisAddressComment = (params: IAddKnowThisAddressCommentRequest) => {
    const { identifier, currency, comment, support_file } = params;
    const formData = new FormData();
    formData.append('identifier', identifier.toString());
    formData.append('currency', currency.toString());
    formData.append('comment', comment);
    support_file && formData.append('support_file', support_file[0]);
    return this.http.post('/risk/api/v4/address-comments/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
  readonly getTokenSummary = (address: number) => {
    return this.http.get<ITokenSummaryResponse>(this.route(`${address}/token-summary/`));
  };
  readonly updateCustomTag = (params: ICustomTagUpdateRequest) => {
    const { id, custom_tag_ids } = params;
    return this.http.post<IMessageResponse>(this.route(`${id}/update-custom-tags/`), { custom_tag_ids });
  };
}

export const addressApi = new AddressApi();

export const useAddressGetAddressList = (params: IAddressListParams, options?: UseQueryOptions) => {
  const key = [
    'addressApi.getAddressList',
    params.filters,
    params.assigned_to,
    params.workflow_stage,
    params?.closed,
  ];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => addressApi.getAddressList({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );

  return result;
};

export const useAddressGetAddress = (address: number, options?: UseQueryOptions) => {
  const key = ['addressApi.getAddress', address];
  const result = useQuery(key, () => addressApi.getAddress(address), { ...options } as unknown);

  return result;
};

export const useAddressGetAddressNameVerboses = (options?: UseQueryOptions) => {
  const key = ['addressApi.getAddressNameVerboses'];
  const result = useQuery(key, () => addressApi.getAddressNameVerboses(), { ...options } as unknown);

  return result;
};

export const useAddressGetAddressTagAggregatedIncomingValues = (
  address: number,
  options?: UseQueryOptions
) => {
  const key = 'addressApi.getAddressTagIncomingValues' + address;
  const result = useQuery(key, () => addressApi.getAddressTagIncomingValues(address), {
    ...options,
  } as unknown);

  return result;
};

export const useAddressGetAddressTagAggregatedOutgoingValues = (
  address: number,
  options?: UseQueryOptions
) => {
  const key = 'addressApi.getAddressTagOutgoingValues' + address;
  const result = useQuery(key, () => addressApi.getAddressTagOutgoingValues(address), {
    ...options,
  } as unknown);

  return result;
};

export const useAddressGetTransactions = (params: IAddressTransactionsRequest, options?: UseQueryOptions) => {
  const key = ['addressApi.getTransactions', params.address, params.filters];
  const result = useInfiniteQuery(
    key,
    ({ pageParam }) => addressApi.getTransactions({ ...params, offset_ts: pageParam }),
    {
      ...options,
      getNextPageParam: getNextPageParamByOffsetTs,
    } as unknown
  );
  return result;
};

export const useAddressGetIOTransactions = (
  params: IAddressIOTransactionRequest,
  options?: UseQueryOptions
) => {
  const key = ['addressApi.getIOTransactions', params.type, params.address, params.filters];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => addressApi.getIOTransactions({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};

export const useAddressGetSentimentSummary = (address: number, options?: UseQueryOptions) => {
  const key = 'addressApi.getSentimentSummary.' + address;
  const result = useQuery(key, () => addressApi.getSentimentSummary(address), { ...options } as unknown);

  return result;
};

export const useAddressGetSentimentList = (address: number, options?: UseQueryOptions) => {
  const key = 'addressApi.getSentimentList.' + address;
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => addressApi.getSentimentList({ id: address, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};
