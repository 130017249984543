import { FC } from 'react';
import { IAlertAgentPerformance } from '../../api/dtos/dashboard';
import DashboardWidget from './DashboardWidget';
import Table from '../../ui/components/Table/Table';
import { formatDurationHelper } from '../../utils/helpers/date';

interface IAgentAlertPerformanceWidgetProps {
  agentsData: IAlertAgentPerformance[];
  isLoading?: boolean;
  isPdf?: boolean;
}

const AgentAlertPerformanceWidget: FC<IAgentAlertPerformanceWidgetProps> = ({
  agentsData,
  isLoading,
  isPdf,
}) => {
  const headers = ['Name', 'Resolved', 'Escalated', 'Avg. Resolution Time'];

  const rows = agentsData?.map((a, i) => ({
    id: i,
    data: [
      <div key={i}>
        <div className='text-sm font-medium'>{a.name}</div>
        <div className='text-gray-500'>{a.email}</div>
      </div>,
      <div key={i} className='text-center text-blue-500'>
        {a.resolved_alert_count}
      </div>,
      <div key={i} className='text-center text-blue-500'>
        {a.escalated_alert_count}
      </div>,
      formatDurationHelper(a.average_alert_resolution_time),
    ],
  }));

  return (
    <DashboardWidget
      title={'Analyst Performance (Alerts)'}
      // eslint-disable-next-line tailwindcss/enforces-shorthand
      className='px-0 py-0'
      containerClassName={!isPdf ? 'col-span-6' : 'col-span-12'}
      widgetHeader={
        <div className='ml-6 mr-auto rounded-xl bg-gray-100 px-3 py-0.5 text-xs font-medium'>
          Total: {agentsData?.length}
        </div>
      }>
      <div className='mb-2'>
        <Table
          headerData={headers}
          rows={rows}
          height={!isPdf ? 350 : 800}
          className='rounded-b-lg'
          isHeaderVisible={false}
          isLoading={isLoading}
        />
      </div>
    </DashboardWidget>
  );
};

export default AgentAlertPerformanceWidget;
