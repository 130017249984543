import { FC, Children, useState, ReactNode, useEffect } from 'react';
import { Tab as HeadlessTab, TabGroup, TabList, TabPanel, TabPanels, Transition } from '@headlessui/react';
import { SpinnerGap } from '@phosphor-icons/react/dist/ssr';

export interface ITabValue {
  label: React.ReactNode;
  count?: number;
  hidden?: boolean;
}
export interface TabProps {
  values: ITabValue[];
  children?: React.ReactNode;
  className?: string;
  listClassName?: string;
  panelClassName?: string;
  inactiveClassName?: string;
  type: 'primary' | 'secondary' | 'tertiary';
  onChange?: (value: number) => void;
  unmount?: boolean;
  isLoading?: boolean;
  subHeader?: ReactNode;
  headerActions?: ReactNode;
  defaultIndex?: number;
  changeTab?: number;
  disableAllTabs?: boolean;
  showCountLoading?: boolean;
}
const Tab: FC<TabProps> = (props) => {
  const {
    values,
    className,
    listClassName,
    panelClassName,
    type,
    onChange,
    unmount = false,
    headerActions,
    isLoading = false,
    subHeader,
    inactiveClassName,
    defaultIndex = 0,
    changeTab = -1,
    disableAllTabs = false,
    showCountLoading = false,
  } = props;
  const [selected, setSelected] = useState(defaultIndex);
  // Change tab if selected tab is hidden
  if (!isLoading && values[selected]?.hidden) {
    const nextSelected = values.findIndex((v) => !v.hidden);
    if (nextSelected !== -1) {
      setSelected(nextSelected);
      if (onChange) onChange(nextSelected);
    }
  }
  useEffect(() => {
    if (changeTab >= 0) {
      setSelected(changeTab);
    }
  }, [changeTab]);
  const handleChange = (value: number) => {
    setSelected(value);
    if (onChange) onChange(value);
  };
  interface ITabClassName {
    [key: string]: {
      default: string;
      active?: string;
      inactive?: string;
      countActive?: string;
      countInactive?: string;
    };
  }
  const tabClassName: ITabClassName = {
    primary: {
      default: 'group items-center flex rounded-3xl border px-3 py-1 font-medium',
      active: 'border-blue-600 bg-blue-600 text-white',
      inactive: 'border-gray-200 text-gray-800',
      countActive: 'bg-blue-50 text-blue-600',
      countInactive: 'bg-gray-100',
    },
    secondary: {
      default: 'group items-center flex rounded-lg px-3 py-2 font-medium',
      active: 'bg-blue-50 text-blue-600',
      inactive: 'text-gray-600 hover:bg-blue-50',
      countActive: 'bg-gray-50 p-1 text-black',
      countInactive: 'bg-gray-50 p-1 text-black',
    },
    tertiary: {
      default: 'group items-center flex px-2 py-2 text-xs font-medium justify-center',
      active: 'border-b-2 border-blue-600 text-blue-500',
      inactive: '',
    },
    tertiaryFileUpload: {
      default: 'group items-center flex px-2 py-2 text-xs font-medium justify-center',
      active: 'border-b-2 border-blue-600 text-blue-500',
      inactive: '',
    },
  };
  const children = Children.toArray(props.children);
  return (
    <TabGroup selectedIndex={isLoading ? -1 : selected} onChange={handleChange}>
      <TabList className={`bg-white ${listClassName}`}>
        <div className='flex justify-between'>
          <div>
            {values.map((value, i) => (
              <HeadlessTab
                key={i}
                disabled={isLoading || disableAllTabs}
                className={`mr-3 text-2xs outline-none ${className} ${
                  value.hidden && !inactiveClassName && 'hidden'
                }`}>
                {({ selected }) => (
                  <div
                    className={`${
                      selected
                        ? tabClassName[type].active
                        : inactiveClassName && value.hidden
                          ? inactiveClassName
                          : tabClassName[type].inactive
                    } ${tabClassName[type].default}`}>
                    {value.label}
                    {(value.count != null || showCountLoading) &&
                      (value.count == null && showCountLoading ? (
                        <SpinnerGap
                          className={`ml-1 size-4 animate-spin ${selected ? 'text-white' : 'text-blue-600'}`}
                        />
                      ) : (
                        <span
                          className={`-mr-1 ml-1 rounded-full px-2 ${
                            selected ? tabClassName[type].countActive : tabClassName[type].countInactive
                          }`}>
                          {value.count}
                        </span>
                      ))}
                  </div>
                )}
              </HeadlessTab>
            ))}
          </div>
          {headerActions}
        </div>
        {subHeader}
      </TabList>
      <TabPanels>
        {children.map((child, index) => (
          // Scale transition
          <Transition
            as='div'
            key={index}
            show={selected === index}
            enter='transition duration-300 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-300 ease-in'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
            unmount={unmount}>
            <TabPanel className={panelClassName}>{child}</TabPanel>
          </Transition>
        ))}
      </TabPanels>
    </TabGroup>
  );
};
export default Tab;
