import { FC } from 'react';
import { Button } from '../../../../../ui';
import { alertApi } from '../../../../../api/alert';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from '../../../../../ui/components/Toast';
// import { useParams } from 'react-router-dom';
import { IAlert } from '../../../../../api/dtos/alert';
import { getDetailApiKey } from '../../../../../utils/helpers/apiHelpers';

interface IReopenAlertButtonProps {
  alert: IAlert;
  type: 'address' | 'transaction' | 'customer';
  id: string | number;
}

const ReopenAlertButton: FC<IReopenAlertButtonProps> = ({ alert, type, id }) => {
  const queryClient = useQueryClient();

  const onSuccessMutate = () => {
    toast.success('Alert status has been updated');
    const key = ['alertApi.getAlerts', id];
    queryClient.invalidateQueries(key);
    queryClient.invalidateQueries(['alertApi.getAlert', id, alert.id]);
    queryClient.invalidateQueries(getDetailApiKey(type, id));
  };

  const { mutate: mutateAlert } = useMutation(alertApi.updateAlert, {
    onSuccess: onSuccessMutate,
  });

  const onResolve = () => {
    mutateAlert({
      id,
      alertId: alert.id,
      status: 0,
      type,
    });
  };
  return (
    <Button onClick={onResolve} variant='tertiary' className='mr-2 bg-gray-300 text-black'>
      Reopen
    </Button>
  );
};

export default ReopenAlertButton;
