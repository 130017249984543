import { FC, useRef } from 'react';
import { getEntityColor, getEntityDescription } from '../../../utils/helpers/entity';
import { CaretRight, Info } from '@phosphor-icons/react';
import { Popover } from '@headlessui/react';
import { debounce } from 'lodash';

interface EntityWithSubtypeBadgeProps {
  type: string;
  subtype: string;
  isPopoverHidden?: boolean;
}

const EntityWithSubtypeBadge: FC<EntityWithSubtypeBadgeProps> = (props) => {
  const { type, subtype, isPopoverHidden = false } = props;
  const entityDescription = getEntityDescription(type, subtype);

  const popoverRef = useRef<HTMLDivElement>(null);
  const popoverButtonRef = useRef<HTMLButtonElement>(null);

  const handleOpenPopover = debounce(() => {
    const triggerElement = popoverRef.current;
    const { left } = triggerElement.getBoundingClientRect();
    const { bottom: popoverButtonBottom } = popoverButtonRef.current.getBoundingClientRect();
    const popoverWidth = triggerElement.offsetWidth;
    triggerElement.style.top = `${popoverButtonBottom}px`;
    const innerWidth = window.innerWidth;

    if (left + popoverWidth > innerWidth) {
      triggerElement.style.right = '0';
    }
  }, 10);

  return (
    <Popover className='relative whitespace-nowrap'>
      {({ open }) => (
        <>
          <Popover.Button as='div' onClick={handleOpenPopover} ref={popoverButtonRef}>
            <div
              className={`my-1 inline-block max-w-full cursor-pointer truncate whitespace-nowrap rounded-full px-2.5 py-1 text-center align-baseline text-xs leading-none text-white ${
                getEntityColor(type).bgColor
              } ${open ? `ring ring-offset-2 ${getEntityColor(type).ringColor}` : ''}`}>
              <span className='my-auto'>
                {type}
                <CaretRight size={12} className='mx-1 inline-block opacity-50' />
                {subtype}
              </span>
            </div>
          </Popover.Button>

          <Popover.Panel>
            <div
              ref={popoverRef}
              className={`fixed z-30 w-[540px] whitespace-normal bg-white p-2 shadow ${
                entityDescription && !isPopoverHidden ? '' : 'hidden'
              }`}>
              <div
                className={`my-1 inline-block cursor-pointer truncate whitespace-nowrap rounded-full px-2.5 py-1 text-center align-baseline text-xs leading-none text-white ${
                  getEntityColor(type).bgColor
                }`}>
                <span className='my-auto'>{type}</span>
                <CaretRight size={12} className='mx-1 inline-block opacity-50' />
                <span className='my-auto'>{subtype}</span>
              </div>
              <div className='flex'>
                <div className='my-auto mr-2 rounded-full bg-blue-100 p-2'>
                  <Info size={25} className='my-auto rounded-full bg-blue-100 text-blue-600' />
                </div>
                <div className='text-sm text-gray-500'>{entityDescription}</div>
              </div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default EntityWithSubtypeBadge;
