import { DotsThreeOutline } from '@phosphor-icons/react';
import { FC, useState } from 'react';
import { Button, DropdownOption } from '../../../ui';
import AddToCase from '../../AddressTransaction/AddToCase';
import SimpleDropdown from '../../ui/components/Dropdown/SimpleDropdown';
import { useRouter } from '../../../modules/router/RouterProvider';
import ExportReport from '../../common/ExportReport';
import { ICustomerResponse } from '../../../api/dtos/customer';
import ArchiveButton from '../../common/ArchiveButton';
import EditCustomerModal from './EditCustomerModal';
import CopyToClipboardBtn from '../../../ui/components/Copy/Copy';

interface CustomerMetadataHeaderProps {
  customer: ICustomerResponse;
}

const CustomerMetadataHeader: FC<CustomerMetadataHeaderProps> = ({ customer }) => {
  const { getQueryParams } = useRouter();
  const { entityId, refCase } = getQueryParams();

  const moreDropdownOptions = [
    { label: 'Edit Customer ID', value: 'edit' },
    { label: 'Archive', value: 'archive' },
  ];
  const [archive, setArchive] = useState(false);
  const [editCustomer, setEditCustomer] = useState(false);

  const onChangeDropdown = (value: DropdownOption) => {
    if (value.value === 'archive') {
      setArchive(true);
    } else if (value.value === 'edit') {
      setEditCustomer(true);
    }
  };
  return (
    <>
      <div className='mt-4 flex'>
        <div className='flex break-all text-[21px] text-gray-800'>
          {customer.customer_id}
          <CopyToClipboardBtn text={customer.customer_id} className='ml-1' />
        </div>
      </div>
      <div className='mt-4'>
        <AddToCase customer={customer} refCase={{ case: Number(refCase), entity: Number(entityId) }} />
        <ExportReport id={customer.customer_id} type='customer' />

        <SimpleDropdown
          value={null}
          onChange={onChangeDropdown}
          placeholder={
            <Button variant='tertiary' className='my-1 ml-2 mr-1 px-3'>
              <DotsThreeOutline size={16} weight='fill' />
            </Button>
          }
          options={moreDropdownOptions}
        />
        <ArchiveButton id={customer.customer_id} type='customer' open={archive} setOpen={setArchive} />
        <EditCustomerModal customer_id={customer.customer_id} open={editCustomer} setOpen={setEditCustomer} />
      </div>
    </>
  );
};

export default CustomerMetadataHeader;
