import { FC, useState } from 'react';
import { Button } from '../../../../../ui';
import { Check } from '@phosphor-icons/react';
import { alertApi } from '../../../../../api/alert';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from '../../../../../ui/components/Toast';
// import { useParams } from 'react-router-dom';

import { IAlert, IAlertItem } from '../../../../../api/dtos/alert';
import ResolveAlertModal from './ResolveAlertModal';
import { IPostCommentsContext } from '../../../../../api/dtos/comment';
import { getDetailApiKey } from '../../../../../utils/helpers/apiHelpers';

interface IResolveAlertButtonProps {
  alert: IAlert;
  alertItem?: IAlertItem;
  type: 'address' | 'transaction' | 'customer';
  id: string | number;
}

const ResolveAlertButton: FC<IResolveAlertButtonProps> = ({ alert, alertItem, type, id }) => {
  const queryClient = useQueryClient();
  const [resolveDisabled, setResolveDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSuccessMutate = () => {
    toast.success('Alert status has been updated');
    const key = ['alertApi.getAlerts', id];
    queryClient.invalidateQueries(key);
    queryClient.invalidateQueries(['alertApi.getAlert', id, alert.id]);
    queryClient.invalidateQueries(getDetailApiKey(type, id));
  };

  const { mutate: mutateAlert } = useMutation(alertApi.updateAlert, {
    onSuccess: onSuccessMutate,
    onSettled: () => setResolveDisabled(false),
  });

  const { mutate: mutateAlertItem } = useMutation(alertApi.updateAlertItem, {
    onSuccess: onSuccessMutate,
    onSettled: () => setResolveDisabled(false),
  });

  const onResolve = (data: { comment: string; context: IPostCommentsContext }) => {
    if (!data) return;
    const { comment, context } = data;
    if (alertItem) {
      setResolveDisabled(true);
      return mutateAlertItem({
        id,
        alertId: alert.id,
        itemId: alertItem.identifier,
        status: 1,
        type: type,
        comment,
        context: {
          ...context,
          alerts: [{ rule_name: alert.alert_body, id: alert.id, start_position: 0, length: 0 }],
          alert_items: [{ id: alertItem.identifier, start_position: 0, length: 0 }],
        },
      });
    }
    setResolveDisabled(true);
    mutateAlert({
      id,
      alertId: alert.id,
      status: 1,
      type: type,
      comment,
      context: {
        ...context,
        alerts: [{ rule_name: alert.alert_body, id: alert.id, start_position: 0, length: 0 }],
      },
    });
  };
  return (
    <>
      <ResolveAlertModal
        id={id}
        open={isModalOpen}
        onResolve={(data) => {
          setIsModalOpen(false);
          onResolve(data);
        }}
        type={type}
      />
      <Button
        onClick={() => setIsModalOpen(true)}
        disabled={resolveDisabled}
        variant='primary'
        className=''
        buttonSize='sm'>
        <Check className='mr-1 rounded-full bg-white p-px text-blue-600' weight='bold' /> Resolve
      </Button>
    </>
  );
};

export default ResolveAlertButton;
