import React from 'react';
import { Info } from '@phosphor-icons/react';

interface AlgoTooltipProps {
  type: 'sensitivity' | 'time' | 'attribution';
}

const AlgoTooltip: React.FC<AlgoTooltipProps> = ({ type }) => {
  // Average Time Detection: Refers to how sensitive the algorithm is in terms of exposure values when compared to the older verisons.
  // Attritbution: Refers to how sensitive the algorithm is in terms of exposure values when compared to the older verisons.

  const data = {
    sensitivity: {
      title: 'Sensitivity',
      description:
        'Refers to how sensitive the algorithm is in terms of exposure values when compared to the older versions.',
    },
    time: {
      title: 'Average Time Detection',
      description:
        'Refers to how sensitive the algorithm is in terms of exposure values when compared to the older versions.',
    },
    attribution: {
      title: 'Attribution',
      description:
        'Refers to how sensitive the algorithm is in terms of exposure values when compared to the older versions.',
    },
  };
  return (
    <div className='w-80'>
      <div className='flex'>
        <div className='my-auto mr-2 rounded-full bg-blue-100 p-2'>
          <Info size={25} className='my-auto rounded-full bg-blue-100 text-blue-600' />
        </div>
        <div className='text-sm text-gray-500'>
          <strong>{data[type].title}: </strong>
          {data[type].description}
        </div>
      </div>
    </div>
  );
};

export default AlgoTooltip;
