import { Api } from '../api';
import { IAlgoVersionSummaryResponse } from '../dtos/settings/algoSettings';

export class AlgoSettingsApi extends Api {
  readonly baseUrl: string = '/profile/api/v2/algo-summary';

  readonly getAlgoVersionSummary = () => {
    return this.http.get<IAlgoVersionSummaryResponse>(this.route(''));
  };

  readonly updateAlgoVersion = (version: number) => {
    return this.http.put(this.route(''), { algo_version: version });
  };
}

export const algoSettingsApi = new AlgoSettingsApi();
