import CounterpartySummary from '../../AddressTransaction/CounterpartySummary';
import Alerts from '../../common/Alerts';
import { ICustomerResponse } from '../../../api/dtos/customer';
import CustomerSummary from './CustomerSummary';
import StageAndAssignedWorkSpace from '../../common/StageAndAssignedWorkspace';

interface Props {
  customer: ICustomerResponse;
  setAlerts: (alerts: number) => void;
  customerId: string;
}

const CustomerOverview: React.FC<Props> = ({ customer, setAlerts, customerId }) => {
  return (
    <div className='flex flex-col gap-3'>
      <CustomerSummary customer={customer} />
      <StageAndAssignedWorkSpace
        type='customers'
        id={customerId}
        assigned_to_name={customer?.assigned_to}
        stage_name={customer?.workflow_stage?.name}
        next_stages={customer?.workflow_stage?.next_stages}
        stage_id={customer?.workflow_stage?.id}
        is_final_stage={customer?.workflow_stage?.is_final_stage}
      />
      <Alerts
        id={encodeURIComponent(customer.customer_id)}
        custId={customer.id}
        type='customer'
        setAlerts={setAlerts}
      />
      <CounterpartySummary customer={customer} type='customer' />
    </div>
  );
};

export default CustomerOverview;
