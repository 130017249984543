import { FC, useState } from 'react';
import { Button } from '../../../../../ui';
import { Flag } from '@phosphor-icons/react';
import AlertCommentModal from './AlertCommentModal';
import { IAlert, IAlertItem } from '../../../../../api/dtos/alert';

interface IEscalateAlertButtonProps {
  alert: IAlert;
  alertItem?: IAlertItem;
  type: 'address' | 'transaction' | 'customer';
  id: string | number;
}

const EscalateAlertButton: FC<IEscalateAlertButtonProps> = ({ alert, alertItem, type, id }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button variant='tertiary' className='ml-2' onClick={() => setOpen(true)}>
        <Flag />
      </Button>
      <AlertCommentModal
        alert={alert}
        id={id}
        alertItem={alertItem}
        alertType={type}
        open={open}
        onClose={() => setOpen(false)}
        type='escalate'
      />
    </>
  );
};

export default EscalateAlertButton;
