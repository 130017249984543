import * as React from 'react';

import { BasicModal, Button } from '../../ui';
import { addressApi } from '../../api/address';
import { transactionApi } from '../../api/transaction';
import { customerApi } from '../../api/customer';
import { caseApi } from '../../api/case';
import { useAuth } from '../../modules/auth';
import { toast } from '../../ui/components/Toast';
import { IStageListLite } from '../../api/dtos/stages';

interface Props {
  type: 'address' | 'transaction' | 'customer' | 'case';
  isOpen: boolean;
  isAllEntitiesSelected: boolean;
  onClose: () => void;
  selectedEntities: number[];
  refetchList: () => void;
  selectedTab?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?: any;
  closedStage?: IStageListLite;
}

const ResolveMultipleAlertsModal: React.FC<Props> = ({
  type,
  isOpen,
  onClose,
  selectedEntities,
  isAllEntitiesSelected,
  refetchList,
  selectedTab = -1,
  ...props
}) => {
  const [alertComment, setAlertComment] = React.useState('');
  const { state } = useAuth();

  const resolveOpenAlerts = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any =
      selectedTab === 0 && isAllEntitiesSelected
        ? { filters: props.filters, assigned_to: state.userProfile?.id }
        : selectedTab === 1 && isAllEntitiesSelected
          ? { filters: props.filters, assigned_to: 'None' }
          : selectedTab === 2 && isAllEntitiesSelected
            ? { filters: props.filters, workflow_stage: props.closedStage?.id, closed: true }
            : selectedTab === 3 && isAllEntitiesSelected
              ? { filters: props.filters }
              : {};
    params.ids = isAllEntitiesSelected ? [] : selectedEntities;
    params.comment = alertComment;
    let res;
    let case_params;
    const filters = { ...props.filters };
    switch (type) {
      case 'address':
        res = await addressApi.updateAddressAlerts(params);
        break;
      case 'transaction':
        res = await transactionApi.updateTransactionAlerts(params);
        break;
      case 'customer':
        res = await customerApi.updateCustomerAlerts(params);
        break;
      case 'case':
        if (selectedTab === 0) {
          filters.assigned_to = [state?.userProfile?.id];
        } else if (selectedTab === 1) {
          filters.assigned_to = ['None'];
        }
        case_params =
          selectedTab === 0 && isAllEntitiesSelected
            ? { filters }
            : selectedTab === 1 && isAllEntitiesSelected
              ? { filters }
              : selectedTab === 2 && isAllEntitiesSelected
                ? { status: 2, closed: true, filters }
                : selectedTab === 3 && isAllEntitiesSelected
                  ? { filters }
                  : {};
        case_params.ids = isAllEntitiesSelected ? [] : selectedEntities;
        case_params.comment = alertComment;
        res = await caseApi.updateCaseAlerts(case_params);
        break;
    }

    toast.success(res.data.message);
    setAlertComment('');

    refetchList();
    onClose();
  };

  return (
    <BasicModal
      size='sm'
      open={isOpen}
      onClose={onClose}
      modalTitle='Resolve open alerts'
      modalBody={
        <div className='z-[100000]'>
          <form
            className='flex flex-col'
            onSubmit={(e) => {
              e.preventDefault();
              resolveOpenAlerts();
            }}>
            <label htmlFor='case-name'>
              <div className='mb-1.5 text-sm font-medium text-gray-700'>
                <span>Add Comment</span>
                <span className='text-red-500'>*</span>
              </div>
              <textarea
                name='case-name'
                id='case-name'
                placeholder='Add your comment here...'
                className='w-full rounded-md border-gray-400 text-sm text-gray-600'
                value={alertComment}
                onChange={(e) => setAlertComment(e.target.value)}
                required
              />
            </label>
            <div className='mt-6 flex justify-between gap-2'>
              <Button variant='tertiary' onClick={onClose}>
                Cancel
              </Button>
              <Button type='submit'>Resolve</Button>
            </div>
          </form>
        </div>
      }
    />
  );
};

export default ResolveMultipleAlertsModal;
