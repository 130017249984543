import { useState } from 'react';
import { Button } from '../../../ui';
import Table from '../../../ui/components/Table/Table';
import { formatDateTime } from '../../../utils/helpers/date';

import IdentifierEllipsis from '../../ui/components/Currency/IdentifierEllipsis';
import { Search } from '../../ui/components/Search';
import AddMonitoringAddressModal from './AddMonitoringAddressModal';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import { getCurrencyFromCode } from '../../../utils/helpers/currency';
import { useMonitoredAddressesGet } from '../../../api/settings/monitoringAddresses';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import MonitoringTableFilter, { IFilters, defaultStateFilters } from './MonitoringTableFilter';
import MonitoringTableAppliedFilters from './MonitoringTableAppliedFilter';
import { useAuth } from '../../../modules/auth';

interface IMonitoringTableProps {
  monitoringLevel: number;
}

export interface IMonitoringAddress {
  is_active?: boolean;
  identifier?: string;
  currency?: number;
  remaining_days?: number;
}

const MonitoringTable: React.FC<IMonitoringTableProps> = ({ monitoringLevel }) => {
  const [search, setSearch] = useState('');
  const [modalAddress, setModalAddress] = useState<IMonitoringAddress>(null);
  const [modal, setModal] = useState(false);

  const [filters, setFilters] = useState<IFilters>(defaultStateFilters);
  const timezone = useAuth()?.state?.userProfile?.timezone;

  const headerData = [
    `${monitoringLevel === 1 ? 'Deactivated Addresses' : 'Monitored Addresses'}`,
    'Added By',
    'Date Added',
    'Blockchain',
    'Action',
  ];

  const monitoringAddressesQuery = useMonitoredAddressesGet({
    q: search,
    ...filters,
    is_ignored: monitoringLevel === 1,
  });
  const [, data] = flattenInfiniteQueryResult(monitoringAddressesQuery?.data);
  const rows = data.map((row) => {
    return {
      id: row.id,
      data: [
        <IdentifierEllipsis
          key={row.id}
          identifier={row.identifier}
          currency={row.currency}
          type='addresses'
          copyable
          clickable
        />,
        row.added_by,
        formatDateTime(row.created_at, timezone),
        // <DigitalAssets key={row.id} digitalAssets={row.blockchains} />,
        <CurrencyBadge
          key={row.id}
          currency={row.currency}
          label={getCurrencyFromCode(row.currency).label}
        />,
        <Button
          key={row.id}
          className='mr-2'
          variant='tertiary'
          onClick={() => {
            setModal(true);
            setModalAddress(row);
          }}>
          Remove
        </Button>,
      ],
    };
  });

  return (
    <div>
      <Table
        className='mt-6'
        headerData={headerData}
        rows={rows}
        title={monitoringLevel === 1 ? 'Monitoring deactivated addresses' : 'Monitored Addresses'}
        headerActionsLeft={
          <Search
            className='ml-4 w-96'
            placeholder='Search for Address'
            value={search}
            setValue={setSearch}
          />
        }
        headerActions={
          <>
            <MonitoringTableFilter filters={filters} onApply={setFilters} />
            <Button
              variant='primary'
              onClick={() => {
                setModal(true);
                setModalAddress(null);
              }}>
              Add Address
            </Button>
          </>
        }
        border
        height={400}
        appliedFilters={<MonitoringTableAppliedFilters filters={filters} setFilters={setFilters} />}
        {...buildInfiniteQueryTableProps(monitoringAddressesQuery)}
      />
      <AddMonitoringAddressModal
        type={modalAddress ? 'remove' : 'add'}
        show={!!modal}
        onClose={() => setModal(false)}
        isIgnored={monitoringLevel === 1}
        {...modalAddress}
      />
    </div>
  );
};

export default MonitoringTable;
