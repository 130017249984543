import React, { FC, ReactNode, useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import useIntersectionObserver from '../../../hooks/useInteractionObserver';
import ExportBtn from '../../../components/ui/components/Table/Export';
import Loader from '@/assets/loader.gif';
import { calculateVh } from '../../../utils/helpers/common';
import EmptyState from '../States/Empty';

export interface ITableRow {
  id: number | string;
  currency?: number;
  onClick?: () => void;
  customerId?: string;
  data: (number | string | React.ReactNode)[];
  extraRowContent?: ReactNode;
  className?: string;
}
export interface TableProps {
  title?: string;
  headerData?: ReactNode[];
  rows?: ITableRow[];
  isLoading?: boolean;
  count?: number | string;
  skeletonRows?: number;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  height?: number;
  heightOffset?: number;
  onClickRow?: (id: string | number, currency?: number, customerId?: string) => void;
  onApply?: (filters: object) => void;
  onReset?: () => void;
  onExport?: () => void;
  filterComponent?: ReactNode;
  isHeaderVisible?: boolean;
  headerActions?: ReactNode;
  headerActionsLeft?: ReactNode;
  appliedFilters?: ReactNode;
  className?: string;
  border?: boolean;
  headerWidths?: number[];
  isSelectable?: boolean;
  isCountVerbose?: boolean;
  fontClass?: string;
  isSearchPage?: boolean;
  errorMessage?: string;
  disableTitleUpperCase?: boolean;
  titleComponent?: ReactNode;
  tab?: number | string;
  isError?: boolean;
}

const Table: FC<TableProps> = (props) => {
  const {
    title,
    headerData,
    rows = [],
    isLoading,
    count,
    skeletonRows = 10,
    isFetchingNextPage,
    onClickRow,
    onExport,
    filterComponent,
    isHeaderVisible = true,
    headerActions,
    headerActionsLeft,
    appliedFilters,
    className,
    border = false,
    isSelectable = false,
    isCountVerbose = false,
    fontClass,
    isSearchPage = false,
    errorMessage,
    disableTitleUpperCase = false,
    titleComponent,
    tab,
    isError,
  } = props;

  const tableRef = useRef(null);
  const paginationID = useRef<HTMLDivElement>();
  useIntersectionObserver({
    target: paginationID,
    onIntersect: props.fetchNextPage || null,
    enabled: !!props.hasNextPage,
  });

  const handleOnClick = (
    k: number,
    id: string | number,
    currency: number,
    customerId: string,
    onClick: () => void
  ) => {
    const onClickFn = onClick || onClickRow;
    if (onClickFn != null) {
      if (isSelectable) {
        if (k > 0) {
          onClickFn(id, currency, customerId);
        }
      } else {
        onClickFn(id, currency, customerId);
      }
    }
  };

  const getMaxHeight = () => {
    if (props.height) {
      return `${props.height}px`;
    }
    let heightOffset = props.heightOffset || 44;
    if (isHeaderVisible) {
      heightOffset += 36;
    }
    return calculateVh(heightOffset);
  };

  useEffect(() => {
    if (tab) {
      if (tableRef?.current) {
        tableRef.current.scrollTop = 0;
      }
    }
  }, [tab]);

  return (
    <div
      className={classNames(`flex flex-col ${className}`, { 'rounded-md border border-gray-200': border })}
      style={{
        maxHeight: getMaxHeight(),
      }}>
      {(isSearchPage ? rows.length > 0 : isHeaderVisible) && (
        <div className='my-3 flex flex-initial flex-row items-center justify-between'>
          {title || count || headerActionsLeft || titleComponent ? (
            <div className='flex flex-row items-center gap-0.5'>
              {titleComponent && <>{titleComponent}</>}
              {title != null && (
                <h4
                  className={`px-3 text-xs font-medium ${disableTitleUpperCase ? '' : 'uppercase'} text-gray-500`}>
                  {title}
                </h4>
              )}
              {count != null && (
                <div
                  className={classNames(
                    'mr-2 rounded-xl bg-gray-100 px-[8px] py-[2px] font-medium text-gray-800',
                    fontClass ?? 'text-2xs'
                  )}>
                  {isCountVerbose ? `Showing ${rows?.length} of ${count ?? 0} results` : count}
                </div>
              )}
              {headerActionsLeft && <>{headerActionsLeft}</>}
            </div>
          ) : (
            <div></div>
          )}
          <div className='mr-3 flex flex-row items-center gap-2'>
            {onExport && <ExportBtn onExport={onExport} type={title.toLowerCase()} />}
            {headerActions}
            {filterComponent}
          </div>
        </div>
      )}
      {appliedFilters}
      {/* Topbar height: 54.6px, tabs height: 62px */}
      <div ref={tableRef} className={`flex flex-auto flex-col overflow-y-auto`}>
        <table className='table-auto text-gray-800'>
          {!isLoading && (isSearchPage ? rows.length > 0 : headerData) && (
            <thead className='bg-gray-100'>
              <tr className='sticky top-0 z-[1] bg-gray-100'>
                {headerData.map((item, i) => {
                  return (
                    <th
                      key={i}
                      style={{ width: props.headerWidths ? `${props.headerWidths[i]}%` : undefined }}
                      className={classNames(
                        'items-center p-2.5 pl-3 text-left font-medium uppercase text-gray-500',
                        fontClass ?? 'text-2xs'
                      )}>
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}
          <tbody>
            {!isLoading && rows.length === 0 && (
              <tr>
                <td colSpan={headerData?.length || 10}>
                  <EmptyState message={errorMessage || (isError ? 'Error Loading Data' : null)} />
                </td>
              </tr>
            )}
            {!isLoading &&
              rows?.map((row, i) => {
                return (
                  <>
                    <tr
                      key={i}
                      className={classNames(
                        'font-medium odd:bg-white even:bg-gray-50',
                        fontClass ?? 'text-2xs',
                        {
                          'cursor-pointer': row.onClick || onClickRow != null,
                        },
                        row.className
                      )}>
                      {row.data.map((item, k) => {
                        return (
                          <td
                            key={k}
                            className='align-center p-2 py-2.5 pl-3 text-left'
                            onClick={() =>
                              handleOnClick(k, row.id, row.currency, row.customerId, row.onClick)
                            }>
                            {item}
                          </td>
                        );
                      })}
                    </tr>
                    {row.extraRowContent && (
                      <tr
                        className={classNames(
                          'font-medium odd:bg-white even:bg-gray-50',
                          fontClass ?? 'text-2xs'
                        )}>
                        <td colSpan={100} className='p-2 py-2.5 pl-3 text-left align-top'>
                          {row.extraRowContent}
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            {isLoading &&
              Array(skeletonRows)
                .fill(true)
                .map((_a, i) => {
                  return (
                    <tr key={i} className='odd:bg-white even:bg-gray-50'>
                      {Array(headerData.length)
                        .fill(true)
                        .map((_a, j) => {
                          return (
                            <td key={j} className='items-center p-2 py-2.5 text-left'>
                              <Skeleton />
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
            {!isFetchingNextPage && !isLoading && (
              <tr>
                <td colSpan={100}>
                  <div ref={paginationID} className={`p-2 ${!props.hasNextPage ? 'hidden' : ''}`}></div>
                </td>
              </tr>
            )}
            {isFetchingNextPage && (
              <tr>
                <td colSpan={100}>
                  <img src={Loader} alt='Loader' className='mx-auto h-16' />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
