import { FC } from 'react';
import { CommentResult } from '../../api/dtos/comment';
import { formatDateTime } from '../../utils/helpers/date';
import { convertCommentToHTML } from '../../utils/helpers/comment';
import startCase from 'lodash/startCase';
import { useRouter } from '../../modules/router/RouterProvider';
import { sanitizeHtml } from '../../utils/helpers/common';

interface IAddressCommentsReportProps {
  comments: CommentResult[];
  identifier: string;
  part?: number;
  type: 'address' | 'transaction' | 'customer';
}

const AddressCommentsReport: FC<IAddressCommentsReportProps> = ({ comments, identifier, part, type }) => {
  const { getQueryParams } = useRouter();
  const timezone = getQueryParams().tz;
  return (
    <div className='mb-4 rounded-b-sm'>
      <div className='rounded-t-sm border border-gray-200 bg-gray-200 px-3 py-2.5 text-xs font-bold'>
        Part {part}: {startCase(type)} Comments
        <div className='text-2xs font-normal text-gray-500'>
          Comments associated with {type} {identifier}
        </div>
      </div>
      <table className='w-full table-fixed text-2xs font-bold'>
        <tbody>
          {comments.map((comment) => (
            <tr key={comment.id} className='border'>
              <td className='w-36 p-2'>{comment.commenter}</td>
              <td className='w-auto break-words p-2 text-sm font-semibold'>
                <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(convertCommentToHTML(comment)) }} />
              </td>
              <td className='w-40 p-2 font-normal'>{formatDateTime(comment.created_at, timezone)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AddressCommentsReport;
