import { FC, useMemo, useState } from 'react';
import { TabHeader } from '../../../../components/ui/components/Header';
import { formatDateShort } from '../../../../utils/helpers/date';
import { entityOptions } from '../../../../utils/constants/constants';
import CurrencyValue from '../../../../components/ui/components/Currency/CurrencyValue';
import classNames from 'classnames';
import { Tooltip } from '../../../../ui/components/Tooltip';
import AlgoTooltip from '../../../../components/Settings/AlgoSettings/AlgoTooltip';
import { ConfirmationModal } from '../../../../ui';
import { algoSettingsApi } from '../../../../api/settings/algoSettings';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import CompassLoader from '../../../../components/ui/components/Loader/CompassLoader';
import { IAlgoVersionSummary } from '../../../../api/dtos/settings/algoSettings';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../modules/auth';

const AlgoSettings: FC = () => {
  const [showModal, setShowModal] = useState<'3.5' | '4' | null>(null);

  const queryClient = useQueryClient();
  const timezone = useAuth()?.state?.userProfile?.timezone;

  const { data: algoSettings, isLoading } = useQuery(
    ['settings.algoSettings'],
    () => algoSettingsApi.getAlgoVersionSummary(),
    {
      enabled: true,
    }
  );

  const entities = entityOptions.map((entity) => entity.label).sort();

  const currentAlgo = (() => {
    if (algoSettings?.data?.algo_version === 0) {
      return 'Algo v3.5';
    } else if (algoSettings?.data?.algo_version === 1) {
      return 'Algo v4';
    }
    return 'Algo v3.0';
  })();

  const entitiesWithValues = useMemo(() => {
    const uniqueEntitiesSet = new Set<string>();

    algoSettings?.data?.summary.forEach((summary) => {
      summary.exposures.forEach((exposure) => {
        if (exposure.directValue !== 0 || exposure.indirectValue !== 0) {
          uniqueEntitiesSet.add(exposure.entity);
        }
      });
    });

    return entities.filter((entity) => uniqueEntitiesSet.has(entity));
  }, [algoSettings?.data?.summary, entities]);

  const { mutate: updateAlgo } = useMutation(algoSettingsApi.updateAlgoVersion, {
    onSuccess: () => {
      setShowModal(null);
      queryClient.invalidateQueries(['settings.algoSettings']);
      toast.success('Updated algo version successfully');
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onClickAlgo = (algo: IAlgoVersionSummary) => {
    // if (algo.isDeprecated || currentAlgo === algo.title) return;
    // setShowModal(algo.title === 'Algo v3.5' ? '3.5' : '4');
  };

  const onConfirmModal = () => {
    updateAlgo(showModal === '3.5' ? 0 : 1);
  };

  const getEntity = (entity: string, type: string) => {
    const entityObj = algoSettings.data.summary
      ?.find((algo) => algo.type === type)
      ?.exposures?.find((e) => e?.entity === entity);
    return entityObj;
  };

  const summaries = algoSettings?.data?.summary.sort((a, b) => a.title.localeCompare(b.title));

  return (
    <div className='h-full max-w-6xl text-xs leading-normal'>
      <TabHeader heading='Algo Settings' />
      {/* <div className='mt-6 text-xs text-gray-500'>Last Changed: {formatDateShort(algoSettings.data.lastChanged)}</div> */}
      <div className='mb-6 mt-4 bg-blue-50 p-3 text-xs text-blue-500'>
        Disclaimer: Changing the Algo will not close any previously open alerts. The new model will take
        effect immediately. The comparison below was run on a sample of 10000 transaction on BTC, ETH and
        Tron.
      </div>
      {!isLoading ? (
        <div className='grid grid-cols-4 gap-6'>
          <div className='mb-[44px] mt-auto'>
            <div className='p-3 text-sm font-semibold'>Metric</div>
            <div className='mb-4 border'>
              <div className='p-3'>
                <div>
                  Sensitivity
                  <Tooltip className='bg-white text-gray-500' id='sensitivity' label='i' variant='light'>
                    <AlgoTooltip type='sensitivity' />
                  </Tooltip>
                </div>
                (Tested with 1000 addresses)
              </div>
              <div className='bg-gray-50 p-3'>
                <div>
                  Average time to detection
                  <Tooltip className='text-gray-500' id='time' label='i' variant='light'>
                    <AlgoTooltip type='time' />
                  </Tooltip>
                </div>
                (Mean detection time) P99
              </div>
              <div className='p-3'>
                <div>
                  Attribution
                  <Tooltip className='text-gray-500' id='attribution' label='i' variant='light'>
                    <AlgoTooltip type='attribution' />
                  </Tooltip>
                </div>
                (Average number of addresses)
              </div>
            </div>
            <div className='border'>
              <div className='bg-gray-100 p-3 text-base font-semibold'>
                Exposure Detected
                <div>(By Type)</div>
              </div>
              {entitiesWithValues.map((entity) => (
                <div key={entity} className='p-3 odd:bg-gray-50'>
                  {entity}
                </div>
              ))}
              <div className='border-t p-3'>
                <div className='font-semibold'>Total</div> (Total dollar amount of exposure detected)
              </div>
            </div>
          </div>
          {summaries.map((algo) => (
            <div
              id={algo.title}
              key={algo.title}
              className={classNames('relative', {
                'rounded-md outline outline-offset-1 outline-blue-600': currentAlgo === algo.title,
                'rounded-md outline-2 outline-offset-1 outline-blue-200':
                  !algo.isDeprecated && currentAlgo !== algo.title,
                // 'cursor-pointer': !algo.isDeprecated && currentAlgo !== algo.title,
              })}
              onClick={() => onClickAlgo(algo)}>
              <div
                className={classNames('mb-4 rounded-t-lg border', {
                  'pointer-events-none bg-gray-100 opacity-60': algo.isDeprecated,
                })}>
                <div className='p-3 text-center text-base font-semibold'>{algo.type}</div>
                <div className='text-center font-medium'>{algo.title}</div>
                <div className='mb-2 border-b p-3 text-center text-gray-800'>
                  Release date ({formatDateShort(algo.releaseDate, timezone)})
                </div>
                <div className='flex h-[52px] items-center justify-center p-3'>{algo.sensitivity}</div>
                <div className='flex h-[60px] items-center justify-center bg-gray-50 p-3 text-blue-600'>
                  {(algo.averageTime / 1000).toFixed(2)} Seconds
                </div>
                <div className='flex h-[60px] items-center justify-center p-3'>
                  {Math.round(algo.attribution)} Addresses
                </div>
              </div>
              <div
                className={classNames('border', {
                  'bg-gray-200 opacity-50': algo.isDeprecated,
                })}>
                <div className='flex h-[72px] justify-around bg-gray-100 text-base font-semibold'>
                  <div className='my-auto p-3'>Direct</div>
                  <div className='my-auto p-3'>Indirect</div>
                </div>
                {entitiesWithValues.map((entity) => (
                  <div key={entity} className='flex justify-around odd:bg-gray-50'>
                    <CurrencyValue
                      className='truncate p-3'
                      value={getEntity(entity, algo.type)?.directValue || 0}
                      currency='USD'
                    />
                    <CurrencyValue
                      className='truncate p-3'
                      value={getEntity(entity, algo.type)?.indirectValue || 0}
                      currency='USD'
                    />
                  </div>
                ))}
                <div className='flex h-20 justify-around odd:bg-gray-50'>
                  <CurrencyValue
                    className='my-auto flex truncate p-3'
                    value={algo.totalDirectExposure}
                    currency='USD'
                  />
                  <CurrencyValue
                    className='my-auto flex truncate p-3'
                    value={algo.totalIndirectExposure}
                    currency='USD'
                  />
                </div>
              </div>
              {algo.title === currentAlgo && (
                <div className='-mx-px flex items-center justify-center border-t border-blue-600 bg-blue-50 p-3 font-semibold'>
                  Selected
                </div>
              )}
              {algo.isDeprecated && (
                <div className='pointer-events-none absolute left-0 top-0 flex size-full rotate-180 items-center justify-center opacity-50 [writing-mode:vertical-lr]'>
                  <div className='mt-10 flex text-[128px] font-semibold text-gray-400'>DEPRECATED</div>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <CompassLoader />
      )}
      <ConfirmationModal
        open={!!showModal}
        data={showModal || null}
        onCancel={() => {
          setShowModal(null);
        }}
        onConfirm={onConfirmModal}
        title='CONFIRMATION'
        body={`You are about to change your Algo from ${currentAlgo} to v${showModal}, do you want to proceed?`}
        confirmButton={'Proceed'}
      />{' '}
    </div>
  );
};

export default AlgoSettings;
