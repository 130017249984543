import { FC } from 'react';
import { BasicModal } from '../../../../../ui';
import { capitalize } from 'lodash';
import Quill from '../../../../ui/components/Quill';
import { useMutation, useQueryClient } from 'react-query';
// import { useParams } from 'react-router-dom';
import { IAlert, IAlertItem } from '../../../../../api/dtos/alert';
import { alertApi } from '../../../../../api/alert';
import { toast } from '../../../../../ui/components/Toast';
import { formatOnlyDate } from '../../../../../utils/helpers/date';
import { commentApi } from '../../../../../api/comment';
import { useAuth } from '../../../../../modules/auth';

interface IAlertCommentModalProps {
  alert: IAlert;
  open: boolean;
  onClose: () => void;
  type: 'escalate' | 'mute';
  alertItem?: IAlertItem;
  days?: number;
  isMuted?: boolean;
  alertType: 'address' | 'transaction' | 'customer';
  id: string | number;
}

const AlertCommentModal: FC<IAlertCommentModalProps> = (props) => {
  const { alert, open, onClose, type, days, isMuted, alertItem, alertType, id } = props;
  const queryClient = useQueryClient();

  const alertKey = ['alertApi.getAlerts', Number(id)];
  const { mutate: addCommentMutate } = useMutation(commentApi.addComment, {
    onSuccess: () => {
      toast.success('Comment added successfully');
    },
  });
  const timezone = useAuth()?.state?.userProfile?.timezone;
  const { mutate: alertMonitoringMutate } = useMutation(alertApi.updateAlertMonitoring, {
    onSuccess: (_data, variables) => {
      const days = variables.days;
      if (days) {
        const date = new Date();
        date.setDate(date.getDate() + days);
        toast.success(`Alert muted till ${formatOnlyDate(date.toDateString(), timezone)}`);
      } else {
        toast.success(`Alert muted forever`);
      }
      queryClient.invalidateQueries(alertKey);
    },
  });
  const onSuccessAlertUpdate = () => {
    // toast.success(`Alert escalated successfully`);
    toast.success('Alert status has been updated successfully');
    queryClient.invalidateQueries(alertKey);
    queryClient.invalidateQueries(['alertApi.getAlert', id, alert.id]);
  };
  const { mutate: alertUpdateMutate } = useMutation(alertApi.updateAlert, {
    onSuccess: onSuccessAlertUpdate,
  });

  const { mutate: alertItemUpdateMutate } = useMutation(alertApi.updateAlertItem, {
    onSuccess: onSuccessAlertUpdate,
  });

  const onSubmit = (body: string, files: FileList) => {
    const alertContext = { id: alert.id, rule_name: alert.alert_body, start_position: 0, length: 0 };
    const alertItemContext = alertItem
      ? [
          {
            id: alertItem?.identifier,
            start_position: 0,
            length: 0,
          },
        ]
      : [];
    addCommentMutate({
      id,
      body,
      files,
      context: { alerts: [alertContext], alert_items: alertItemContext },
      type: alertType,
    });
    if (type === 'mute') {
      alertMonitoringMutate({
        id,
        alertId: alert.id,
        days,
        isMuted,
        type: alertType,
      });
    } else {
      if (alertItem) {
        alertItemUpdateMutate({
          id,
          alertId: alert.id,
          itemId: alertItem.identifier,
          status: 2,
          type: alertType,
        });
      } else {
        alertUpdateMutate({
          id,
          alertId: alert.id,
          status: 2,
          type: alertType,
        });
      }
    }
    onClose();
  };

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      modalTitle={`Comment required to ${capitalize(type)} alert`}
      modalBody={<Quill id={id} onSubmit={onSubmit} type={alertType} />}
    />
  );
};

export default AlertCommentModal;
