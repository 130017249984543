import React, { FC, ReactNode } from 'react';
import { BasicModal, Button } from '../../ui';
import SingleSelect from '../ui/components/Select/SingleSelect';
import { IOption } from '../Filters/CustomSelect';
import Quill from '../ui/components/Quill';
import UserBadge from '../../ui/components/Badges/UserBadge/UserBadge';
import { UseInfiniteQueryResult } from 'react-query';
import { buildInfiniteQueryTableProps } from '../../utils/helpers/react-query.helper';

interface UpdateStageorAssigneeModalProps {
  id: number | string;
  open: boolean;
  setOpen: (val: boolean) => void;
  optionsData: IOption[];
  selectedOption: IOption;
  setSelectedOption: (value: IOption) => void;
  handleSubmit: () => void;
  isLoading: boolean;
  currentOption: IOption;
  setComment: (value: string) => void;
  isStageorAssignee: boolean;
  comment: string;
  getCustomOptionLabel: (value: IOption) => ReactNode;
  setSearch?: (value: string) => void;
  filterOptions?: (value: IOption, input: string) => boolean;
  assignedToOptionsQuery?: UseInfiniteQueryResult;
}

const UpdateStageorAssigneeModal: FC<UpdateStageorAssigneeModalProps> = (props) => {
  const {
    id,
    open,
    setOpen,
    optionsData,
    selectedOption,
    setSelectedOption,
    handleSubmit,
    isLoading,
    currentOption,
    setComment,
    isStageorAssignee,
    comment,
    getCustomOptionLabel,
  } = props;

  const handleChange = (stage: IOption) => {
    setSelectedOption(stage);
  };

  const handleClose = () => {
    setComment('');
    setOpen(false);
  };

  return (
    <BasicModal
      open={open}
      size='md'
      onClose={() => {
        setOpen(false);
        setComment('');
      }}
      modalTitle={`Update ${isStageorAssignee ? 'Status' : 'Assignee'}`}
      modalBody={
        <div className='flex flex-col gap-5 px-4 py-3 text-sm'>
          <div className='w-full gap-6'>
            <div className='mb-2 flex w-full items-center gap-6'>
              <p className='w-1/5 text-gray-500'>Current {`${isStageorAssignee ? 'Status' : 'Assignee'}`}</p>
              <div className='mt-1 flex grow items-center justify-between rounded-md border border-gray-200 bg-gray-50 p-2'>
                {isStageorAssignee ? (
                  getCustomOptionLabel(currentOption)
                ) : (
                  <UserBadge
                    className='bg-gray-200 text-gray-800'
                    label={currentOption?.label ? currentOption?.label : 'Unassigned'}
                  />
                )}
                {/* TODO: add a tooltip here according to the design */}
                {/* <Tooltip
                  className='flex align-middle'
                  contentTitle='Monitoring'
                  content='If enabled, risk checks are performed on this address every time it receives a new transaction'
                  id='monitoring-label'
                  label={<Question size={15} className='align-middle text-gray-500' />}
                /> */}
              </div>
            </div>
            <div className='flex w-full items-center gap-6'>
              <p className='w-1/5 text-gray-500'>Updated {`${isStageorAssignee ? 'Status' : 'Assignee'}`}</p>
              <SingleSelect
                options={optionsData}
                handleChange={handleChange}
                value={selectedOption}
                isCloseHidden={true}
                showRadioButton={true}
                isSearchable={!isStageorAssignee}
                getCustomLabel={getCustomOptionLabel}
                getCustomOptionLabel={isStageorAssignee && getCustomOptionLabel}
                parentClassName='flex-grow'
                {...buildInfiniteQueryTableProps(props?.assignedToOptionsQuery)}
                setSearch={props?.setSearch}
                filterOptions={props?.filterOptions}
                asyncPaginate={!isStageorAssignee}
              />
            </div>
          </div>
          <div className='flex flex-col gap-2'>
            <p className='text-sm font-medium leading-5 text-gray-500'>Comment</p>
            <Quill id={id} showBottomButtons={false} type='address' setComment={setComment} />
          </div>
        </div>
      }
      modalFooter={
        <div className='flex justify-end gap-4'>
          <Button variant='tertiary' disabled={isLoading || comment?.length === 0} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={isLoading || comment?.length === 0} onClick={handleSubmit}>
            Confirm
          </Button>
        </div>
      }
    />
  );
};

export default UpdateStageorAssigneeModal;
