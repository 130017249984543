import { every, isEmpty } from 'lodash';
import { IFilters, policyTypeCategoryOptions } from './AlertListFilter';
import AppliedFilters, { BasicAppliedFilter } from '../AppliedFilters';
import { defaultAlertListFilters } from './AlertListFilter';
import DateAppliedFilter from '../AppliedFilters/DateAppliedFilter';
import RiskAppliedFilter from '../AppliedFilters/RiskAppliedFilter';

interface ICaseDistributionWidgetProps {
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
}

const AlertListAppliedFilter: React.FC<ICaseDistributionWidgetProps> = (props) => {
  const { filters, setFilters } = props;

  if (every(filters, (v) => isEmpty(v))) return null;

  return (
    <AppliedFilters
      onResetAll={() => {
        setFilters(defaultAlertListFilters);
      }}>
      <RiskAppliedFilter risks={filters.level} setRisks={(r) => setFilters({ ...filters, level: r })} />
      <DateAppliedFilter
        label='Created Date'
        from={filters.start_time}
        to={filters.end_time}
        onRemove={() => setFilters({ ...filters, start_time: '', end_time: '' })}
      />
      <BasicAppliedFilter
        setValue={() => setFilters({ ...filters, rule_name: [] })}
        label='Rule Name'
        value={filters.rule_name}
      />
      <BasicAppliedFilter
        label='Policy Type & Category'
        single
        value={[
          policyTypeCategoryOptions.find(
            (o) =>
              o.value.split(',')[0] === filters.policy_category &&
              Number(o.value.split(',')[1]) === filters.policy_type
          )?.label,
        ]}
        setValue={() => {
          setFilters({ ...filters, policy_category: null, policy_type: null });
        }}
      />
    </AppliedFilters>
  );
};

export default AlertListAppliedFilter;
