import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { AxiosError } from 'axios';

import { useAuth } from '../../modules/auth';
import { useRouter } from '../../modules/router/RouterProvider';

import { getDigitalAssets } from '../../utils/helpers/currency';
import { useTransactionGetTransactionLite } from '../../api/transactionLite';

import Sidebar from '../ui/components/Sidebar';
import Tab from '../ui/components/Tab/Tab';
import DigitalAssets from '../ui/components/Currency/DigitalAssets';
import TransactionValue from '../Transaction/TransactionValue';
import TransactionSummary from '../Transaction/Overview/TransactionSummary/TransactionSummary';
import CounterpartySummary from './CounterpartySummary';
import InvestigationTree from './InvestigationTree/InvestigationTree';
import Card from '../ui/components/Card/Card';
import CompassLoader from '../ui/components/Loader/CompassLoader';
import IdentifierEllipsis from '../ui/components/Currency/IdentifierEllipsis';
import EmptyState from '../../ui/components/States/Empty';
import { Fee, TransactionTime } from '../Transaction/TransactionMetadata/TransactionUtils';
import { Button } from '../../ui';

interface ITransactionPopoverProps {
  id: string;
  currency: number;
  open: boolean;
  setOpen: (val: boolean) => void;
  handleScreen: (val: string) => void;
}

const TransactionPopover: FC<ITransactionPopoverProps> = ({ id, currency, open, setOpen, handleScreen }) => {
  const { state } = useAuth();
  const { navigate, showErrorPage } = useRouter();
  const tabs = [
    { label: 'Overview' },
    {
      label: 'Transfers',
    },
    {
      label: 'Counterparty Summary',
    },
    {
      label: 'Investigation Mode',
    },
  ];
  const { data: response, isLoading: detailLoading } = useTransactionGetTransactionLite(id, currency, {
    onError: (error: AxiosError) => {
      if (error.response.status === 404) {
        showErrorPage && showErrorPage({ status: 404 });
      }
    },
    enabled: open,
  });
  const transaction = response?.data;
  const metaDetails =
    !detailLoading && transaction && open
      ? [
          {
            label: 'Digital Asset',
            value: <DigitalAssets digitalAssets={getDigitalAssets(transaction)} noBadge />,
          },
          {
            label: 'Transaction Time',
            value: <TransactionTime transaction={transaction} state={state} />,
          },
          {
            label: 'Value',
            value: <TransactionValue transaction={transaction} />,
          },
          {
            label: 'Fee',
            value: <Fee transaction={transaction} />,
          },
          {
            label: 'Customer ID',
            value: (
              <div
                className='cursor-pointer break-all text-blue-600 underline'
                onClick={() => navigate(`/customers/${transaction?.customer_id}`)}>
                {transaction?.customer_id}
              </div>
              // TODO: Use react-router Link
            ),
            isHidden: !transaction?.customer_id,
          },
          {
            label: 'Type',
            value: transaction?.type === 1 ? 'Deposit' : 'Withdrawal',
            isHidden: !transaction?.type,
          },
        ]
      : [];

  useEffect(() => {
    if (state.screeningLoader) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.screeningLoader]);

  return (
    <Sidebar
      title='Transaction Preview'
      isOpen={open}
      onClose={() => setOpen(false)}
      titleClass='!py-2'
      childrenClass='!my-2 !py-0'
      maxWidthClass='max-w-5xl'>
      {detailLoading ? (
        <CompassLoader />
      ) : transaction ? (
        <Tab
          values={tabs}
          type='secondary'
          listClassName={classNames('border-b border-gray-200 pb-2 sticky z-30 top-0 !mt-0')}
          panelClassName='py-2'>
          <div className='flex h-[500px] flex-col justify-between'>
            <div className='bg-gray-50 px-4 py-2'>
              <div className='flex flex-col justify-between'>
                <IdentifierEllipsis
                  identifier={transaction?.identifier}
                  limit={16}
                  className='text-xl text-gray-800'
                  copyable
                />
                <div className='flex text-wrap break-all text-sm text-gray-500'>
                  {transaction?.identifier}
                </div>
              </div>
              <hr className='mt-4' />
              {metaDetails?.map((metaDetail, i) =>
                metaDetail.isHidden ? null : (
                  <div key={i} className='my-5 grid grid-cols-3'>
                    <span className='my-auto text-2xs uppercase text-gray-500'>{metaDetail.label}</span>
                    <span className='col-span-2 my-auto text-xs font-semibold'>{metaDetail.value}</span>
                  </div>
                )
              )}
            </div>
            <div className='flex flex-col items-center text-sm'>
              <Button
                type='submit'
                variant='primary'
                className='w-fit self-center'
                onClick={() => handleScreen(transaction.identifier)}>
                Screen Transaction (Basic)
              </Button>
              <span className='mt-2 text-center text-xs'>
                Note: Basic screening for risk can flag false positive alerts
                <br />
                We recommend Advanced Screening (Workflow). Please refer to{' '}
                <a href='/docs/guide' rel='noreferrer' target='_blank' className='text-blue-600'>
                  Product Guide
                </a>
              </span>
            </div>
          </div>
          <TransactionSummary transaction={transaction} popover={true} />
          <CounterpartySummary
            transaction={transaction}
            type='transaction'
            popover={true}
            showModes={false}
            maxHeightClass='max-h-[450px]'
          />
          <Card title='INVESTIGATION MODE' containerClassName='border border-gray-200'>
            <InvestigationTree
              originType='transaction'
              identifier={transaction?.identifier}
              transaction={transaction}
              popover={true}
            />
          </Card>
        </Tab>
      ) : (
        <EmptyState />
      )}
    </Sidebar>
  );
};

export default TransactionPopover;
