/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useLocalStorage } from 'react-use';
import {
  ArrowSquareOut,
  CaretDown,
  CheckCircle,
  DotsThreeOutlineVertical,
  FolderPlus,
} from '@phosphor-icons/react';
import classNames from 'classnames';

import { useRouter } from '../../../modules/router/RouterProvider';
import { useAuth } from '../../../modules/auth';

import { Button, DropdownOption } from '../../../ui';
import { toast } from '../../../ui/components/Toast';
import Table, { ITableRow } from '../../../ui/components/Table/Table';
import AddToCaseModal from '../../../components/common/AddToCaseModal';
import ResolveMultipleAlertsModal from '../../../components/common/ResolveMultipleAlertsModal';
import TransactionListFilter, {
  IFilters,
  defaultTransactionListFilters,
} from '../../../components/Transaction/TransactionList/TransactionListFilter';
import TransactionListAppliedFilter from '../../../components/Transaction/TransactionList/TransactionListAppliedFilter';
import Tab from '../../../../src/components/ui/components/Tab/Tab';
import SimpleDropdown from '../../../components/ui/components/Dropdown/SimpleDropdown';

import { transactionApi, useTransactionGetTransactionList } from '../../../api/transaction';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import { useStagesListLiteGet } from '../../../api/stages';
import { useWorkspaceAssignmentCountGet } from '../../../api/workflows';

import { IGetWorkspaceAssignmentCountsResponse } from '../../../api/dtos/workflows';
import { IStageListLite } from '../../../api/dtos/stages';
import { ITransactionResponse } from '../../../api/dtos/transaction';
import { useWorkspace } from '../../../utils/helpers/common';
import { useGetTransactionData } from './TransactionData';
import { isEqual } from 'lodash';

interface Props {}
const tabData = ['assigned_to_me', 'unassigned', 'closed', 'all_transactions'];

const TransactionList: React.FC<Props> = () => {
  const { state } = useAuth();
  const { getQueryParams, navigate } = useRouter();
  const {
    risk_level,
    currency,
    created_start_date,
    created_end_date,
    start_date,
    end_date,
    transaction_start_date,
    transaction_end_date,
    is_included_in_case,
    rule_name,
    workflow_stage,
    tab,
  } = getQueryParams();

  const workspace = useWorkspace();
  const [filterStorage, setFilterStorage] = useLocalStorage(
    `compass.storage.transactionFilters.${workspace.slug}`,
    null
  );
  const [filters, setFilters] = React.useState<IFilters>({
    risk_level: risk_level ? risk_level.split(',').map(Number) : [],
    currency: currency ? currency.split(',').map(Number) : [],
    created_start_date: created_start_date && created_start_date !== 'null' ? created_start_date : null,
    created_end_date: created_end_date && created_end_date !== 'null' ? created_end_date : null,
    start_date: start_date && start_date !== 'null' ? start_date : null,
    end_date: end_date && end_date !== 'null' ? end_date : null,
    transaction_start_date:
      transaction_start_date && transaction_start_date !== 'null' ? transaction_start_date : null,
    transaction_end_date:
      transaction_end_date && transaction_end_date !== 'null' ? transaction_end_date : null,
    is_included_in_case:
      is_included_in_case === null || is_included_in_case === undefined ? null : Boolean(is_included_in_case),
    rule_name: rule_name ? rule_name.split(',') : [],
    workflow_stage: workflow_stage
      ? workflow_stage.split(',').map((val) => (val === 'None' ? val : Number(val)))
      : [],
  });
  const selectedTab = tabData.findIndex((item) => item === tab);
  const [currentTab, setTab] = React.useState<number>(selectedTab);
  const [isAllTransactionSelected, setIsAllTransactionSelected] = React.useState(false);
  const [selectedTransactions, setSelectedTransactions] = React.useState<number[]>([]);
  const [isResolveAlertsModalOpen, setIsResolveAlertsModalOpen] = React.useState(false);
  const [isAddToCaseModalOpen, setIsAddToCaseModalOpen] = React.useState(false);
  const [closedStage, setClosedStage] = React.useState<IStageListLite>();
  const [disableAllTabs, setDisableAllTabs] = React.useState(true);
  const selectedDropdownOptions = [
    {
      label: 'Resolve open alerts',
      value: 'resolve',
      iconStart: <CheckCircle size={17} />,
    },
    {
      label: 'Add to Case',
      value: 'case',
      iconStart: <FolderPlus size={17} />,
    },
  ];

  const stagesListLiteQuery = useStagesListLiteGet();

  const transactionQueryAll = useTransactionGetTransactionList({ offset: 0, filters });

  const transactionQueryAssigned = useTransactionGetTransactionList({
    offset: 0,
    filters,
    assigned_to: state.userProfile?.id,
  });

  const transactionQueryUnassigned = useTransactionGetTransactionList({
    offset: 0,
    filters,
    assigned_to: 'None',
  });

  const transactionQueryClosed = useTransactionGetTransactionList(
    { offset: 0, filters, workflow_stage: closedStage?.id, closed: true },
    { enabled: !!closedStage }
  );

  const getAssignmentCountOptions = (workspaces: IGetWorkspaceAssignmentCountsResponse) => {
    if (workspaces?.total === 0) {
      return [];
    }
    return workspaces?.workspaces?.map((r) => {
      return {
        label: r.name,
        value: r.slug,
        iconEnd: (
          <div className='ml-2 flex items-center gap-2'>
            <div className='whitespace-nowrap'>{`${r.count} assigned ${
              r.count > 1 ? 'transactions' : 'transaction'
            }`}</div>
            <div className='rounded border border-gray-200 p-1'>
              <ArrowSquareOut size={22} />
            </div>
          </div>
        ),
      };
    });
  };

  const workspaceAssignmentCount = useWorkspaceAssignmentCountGet({ type: 'transactions' });
  const crossWorkspaceOptions = getAssignmentCountOptions(workspaceAssignmentCount?.data?.data);

  const isLoading =
    transactionQueryAll.isLoading &&
    transactionQueryAssigned.isLoading &&
    transactionQueryUnassigned.isLoading &&
    transactionQueryClosed.isLoading &&
    stagesListLiteQuery.isLoading;

  const [allTransactionsCount, allTransactionsData] = flattenInfiniteQueryResult(transactionQueryAll?.data);
  const [assignedTransactionsCount, assignedTransactionsData] = flattenInfiniteQueryResult(
    transactionQueryAssigned?.data
  );
  const [unAssignedTransactionsCount, unAssignedTransactionsData] = flattenInfiniteQueryResult(
    transactionQueryUnassigned?.data
  );
  const [closedTransactionsCount, closedTransactionsData] = flattenInfiniteQueryResult(
    transactionQueryClosed?.data
  );

  const tabs = [
    { label: 'Assigned to me', count: assignedTransactionsCount, slug: 'assigned_to_me' },
    {
      label: 'Unassigned',
      count: unAssignedTransactionsCount,
      slug: 'unassigned',
    },
    {
      label: 'Closed',
      count: stagesListLiteQuery?.data?.data?.count && closedTransactionsCount,
      slug: 'closed',
    },
    {
      label: 'All Transactions',
      count: allTransactionsCount,
      slug: 'all_transactions',
    },
  ];

  function getTransactionData(tab: number) {
    //TODO: need to add a interface for selectedQuery
    let transactions: ITransactionResponse[], count: number, selectedQuery: any;

    switch (tab) {
      case 0:
        transactions = assignedTransactionsData;
        count = assignedTransactionsCount;
        selectedQuery = transactionQueryAssigned;
        break;
      case 1:
        transactions = unAssignedTransactionsData;
        count = unAssignedTransactionsCount;
        selectedQuery = transactionQueryUnassigned;
        break;
      case 2:
        transactions = closedTransactionsData;
        count = closedTransactionsCount;
        selectedQuery = transactionQueryClosed;
        break;
      default:
        transactions = allTransactionsData;
        count = allTransactionsCount;
        selectedQuery = transactionQueryAll;
    }

    return { transactions, count, selectedQuery };
  }

  const { transactions, count, selectedQuery } = getTransactionData(currentTab);

  React.useEffect(() => {
    const localStorageFilters = filterStorage
      ? formatLocalStorageValue(filterStorage)
      : defaultTransactionListFilters;

    workspace.workspaces.forEach((w) => {
      if (!localStorage.getItem(`compass.storage.transactionFilters.${w.slug}`)) {
        localStorage.setItem(
          `compass.storage.transactionFilters.${w.slug}`,
          JSON.stringify(defaultTransactionListFilters)
        );
      }
    });

    if (isEqual(filters, defaultTransactionListFilters)) {
      setFilters(localStorageFilters);
      syncFilters(localStorageFilters, currentTab);
    } else {
      setFilterStorage(filters);
      setDisableAllTabs(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (selectedTab < 0 && assignedTransactionsCount !== 0 && !transactionQueryAssigned.isLoading) {
      const localStorageFilters = filterStorage
        ? formatLocalStorageValue(filterStorage)
        : defaultTransactionListFilters;
      setFilters(localStorageFilters);
      onChange(0, localStorageFilters);
    } else if (assignedTransactionsCount === 0 && currentTab === -1 && !transactionQueryAssigned.isLoading) {
      const localStorageFilters = filterStorage
        ? formatLocalStorageValue(filterStorage)
        : defaultTransactionListFilters;
      setFilters(localStorageFilters);
      onChange(3, localStorageFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionQueryAssigned.isLoading]);

  React.useEffect(() => {
    setClosedStage(stagesListLiteQuery?.data?.data.results.find((item) => item.is_final_stage));
  }, [stagesListLiteQuery]);

  React.useEffect(() => {
    if (state?.userProfile.email) {
      const newSearchParams = new URLSearchParams();
      if (filterStorage) {
        setFilters(filterStorage);
        Object.keys(filterStorage).forEach((key) => {
          if (
            filterStorage[key] !== null &&
            (!Array.isArray(filterStorage[key]) || filterStorage[key].length !== 0)
          ) {
            newSearchParams.set(key, filterStorage[key].toString());
          }
        });
      }
      setTimeout(() => {
        const { tab } = getQueryParams();
        if (tab) newSearchParams.set('tab', tab);
        navigate(`/transactions`, Object.fromEntries(newSearchParams));
      }, 100);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStorage]);

  const syncFilters = (newFilters: IFilters, tab_id?: number) => {
    if (state?.userProfile.email) {
      if (tab_id !== -1 || tab_id == null) {
        const newSearchParams = new URLSearchParams();
        newSearchParams.set('tab', tabs[tab_id || tab_id === 0 ? tab_id : currentTab ? currentTab : 0]?.slug);
        Object.keys(newFilters).forEach((key) => {
          if (newFilters[key] && Array.isArray(newFilters[key]) && newFilters[key].length !== 0) {
            newSearchParams.set(key, newFilters[key].toString());
          }
        });
        setFilterStorage(newFilters);
        navigate(`/transactions`, Object.fromEntries(newSearchParams));
        setDisableAllTabs(false);
      }
    }
  };

  const formatLocalStorageValue = (unformattedFilter: IFilters): IFilters => {
    const val = {};
    Object.keys(unformattedFilter).forEach((key) => {
      if (unformattedFilter[key] === 'null') {
        val[key] = null;
      } else {
        val[key] = unformattedFilter[key];
      }
    });
    return val as IFilters;
  };

  const handleApply = (newFilters: IFilters) => {
    setFilters(newFilters);
    syncFilters(newFilters);
  };

  const onReset = () => {
    setFilters(defaultTransactionListFilters);
    syncFilters(defaultTransactionListFilters);
  };

  const selectTransaction = (id) => {
    if (selectedTransactions?.includes(id)) {
      setSelectedTransactions(selectedTransactions.filter((i) => i !== id));
    } else {
      setSelectedTransactions([...selectedTransactions, id]);
    }
  };

  const selectAllTransactions = (force = false) => {
    if (force) {
      setSelectedTransactions(transactions.map((transaction) => transaction.id));
    } else {
      if (selectedTransactions.length === transactions.length) {
        setSelectedTransactions([]);
        selectAllTransactions(false);
      } else {
        setSelectedTransactions(transactions.map((transaction) => transaction.id));
      }
    }
  };

  const downloadSelectedTransactions = async () => {
    if (isAllTransactionSelected) {
      if (selectedTab === 3) {
        const res = await transactionApi.exportTransactionList({
          ids: isAllTransactionSelected ? [] : selectedTransactions,
          filters,
        });
        toast.success(res.data.message);
      } else if (selectedTab === 0) {
        const res = await transactionApi.exportTransactionList({
          ids: isAllTransactionSelected ? [] : selectedTransactions,
          filters,
          assigned_to: state.userProfile?.id,
        });
        toast.success(res.data.message);
      } else if (selectedTab === 1) {
        const res = await transactionApi.exportTransactionList({
          ids: isAllTransactionSelected ? [] : selectedTransactions,
          filters,
          assigned_to: 'None',
        });
        toast.success(res.data.message);
      } else if (selectedTab === 2) {
        const res = await transactionApi.exportTransactionList({
          ids: isAllTransactionSelected ? [] : selectedTransactions,
          filters,
          workflow_stage: closedStage?.id,
          closed: true,
        });
        toast.success(res.data.message);
      }
    } else {
      const res = await transactionApi.exportTransactionList({
        ids: selectedTransactions,
      });
      toast.success(res.data.message);
    }
  };

  const onChange = (tab: number, newFilters?: IFilters) => {
    setTab(tab);
    setSelectedTransactions([]);
    setIsAllTransactionSelected(false);
    if (newFilters) {
      syncFilters(newFilters, tab);
    } else {
      syncFilters(filters, tab);
    }
  };

  const onChangeSelectedDropdown = (value: DropdownOption) => {
    if (value.value === 'resolve') {
      setIsResolveAlertsModalOpen(true);
    } else if (value.value === 'case') {
      setIsAddToCaseModalOpen(true);
    }
  };

  const onChangeWorkspacesDropdown = (value: DropdownOption) => {
    const currentUrl = new URL(window.location.href);
    currentUrl.search = '';
    currentUrl.searchParams.set('workspace', value.value.toString());
    window.open(currentUrl.toString(), '_blank');
  };

  const transactionData = useGetTransactionData({
    selectAllTransactions,
    selectedTransactions,
    transactions,
    selectTransaction,
  });

  const headerData: (string | JSX.Element)[] = transactionData.headerData;
  const rows: ITableRow[] = transactionData.rowData;

  return (
    <>
      <Table
        title='Transactions'
        headerData={headerData}
        rows={rows}
        count={`Showing ${rows?.length} of ${count ?? 0} results`}
        tab={tab}
        headerActionsLeft={
          <Tab
            values={tabs}
            defaultIndex={currentTab ?? null}
            type='primary'
            listClassName='rounded-t-md'
            inactiveClassName='text-gray-800/30 border-gray-200 cursor-not-allowed'
            panelClassName='px-4'
            isLoading={isLoading}
            onChange={onChange}
            changeTab={currentTab ?? null}
            disableAllTabs={disableAllTabs}
          />
        }
        filterComponent={
          <>
            {workspaceAssignmentCount?.data?.data.total !== 0 && !workspaceAssignmentCount.isLoading && (
              <SimpleDropdown
                value={null}
                onChange={onChangeWorkspacesDropdown}
                placeholder={
                  <Button variant='tertiary' className='m-1 px-3'>
                    <div className='pr-2 text-2xs font-medium text-gray-800'>
                      {`${
                        workspaceAssignmentCount?.data?.data.total
                          ? workspaceAssignmentCount?.data?.data.total === 1
                            ? `${workspaceAssignmentCount?.data?.data.total} Cross-Workspace Transaction`
                            : `${workspaceAssignmentCount?.data?.data.total} Cross-Workspace Transactions`
                          : '0 Cross-Workspace Transaction'
                      }`}
                    </div>
                    <CaretDown weight='bold' fill='fill' size={16} className={classNames('mr-0.5')} />
                  </Button>
                }
                options={crossWorkspaceOptions}
              />
            )}
            {selectedTransactions.length > 0 && (
              <SimpleDropdown
                value={null}
                onChange={onChangeSelectedDropdown}
                placeholder={
                  <Button variant='tertiary' className='my-1 px-3'>
                    <DotsThreeOutlineVertical size={16} weight='fill' />
                  </Button>
                }
                options={selectedDropdownOptions}
              />
            )}
            <TransactionListFilter
              filters={filters}
              onApply={handleApply}
              onReset={onReset}
              disabled={selectedTransactions.length > 0}
              tab={currentTab}
            />
          </>
        }
        appliedFilters={
          <TransactionListAppliedFilter
            count={count}
            filters={filters}
            setFilters={handleApply}
            selectedTransactions={selectedTransactions}
            setSelectedTransactions={setSelectedTransactions}
            isAllTransactionSelected={isAllTransactionSelected}
            setIsAllTransactionSelected={setIsAllTransactionSelected}
            selectAllTransactions={selectAllTransactions}
            downloadSelectedTransactions={downloadSelectedTransactions}
            stageOptions={stagesListLiteQuery?.data?.data?.results}
            tab={currentTab}
            selectedText={
              isAllTransactionSelected
                ? `${count} transactions selected.`
                : selectedTransactions.length === transactions.length
                  ? `All ${selectedTransactions.length} transactions in this page are selected.`
                  : `${selectedTransactions.length} ${
                      selectedTransactions.length > 1 ? 'transactions' : 'transaction'
                    } selected.`
            }
          />
        }
        heightOffset={25.5}
        isSelectable
        {...buildInfiniteQueryTableProps(selectedQuery)}
      />

      <ResolveMultipleAlertsModal
        type='transaction'
        isOpen={isResolveAlertsModalOpen}
        onClose={() => setIsResolveAlertsModalOpen(false)}
        selectedEntities={selectedTransactions}
        isAllEntitiesSelected={isAllTransactionSelected}
        refetchList={() => selectedQuery.refetch()}
        selectedTab={selectedTab}
        filters={filters}
        closedStage={closedStage}
      />
      <AddToCaseModal
        type='transaction'
        isOpen={isAddToCaseModalOpen}
        onClose={() => setIsAddToCaseModalOpen(false)}
        entities={selectedTransactions.map((transaction) => {
          let data;
          transactions.forEach((txn) => {
            if (txn.id === transaction) {
              data = {
                identifier: txn.identifier,
                currency: txn.currency,
              };
            }
          });
          return data;
        })}
        isAllEntitiesSelected={isAllTransactionSelected}
        selectedTab={selectedTab}
        filters={filters}
        closedStage={closedStage}
      />
    </>
  );
};

export default TransactionList;
