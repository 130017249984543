import { FC } from 'react';
import { useRouter } from '../../modules/router/RouterProvider';
import {
  transactionApi,
  useTransactionGetAggregatedInputs,
  useTransactionGetTransaction,
} from '../../api/transaction';
import { useAlertGetAlerts } from '../../api/alert';
import { useCommentGetComments } from '../../api/comment';
import { classifierApi } from '../../api/classifier';
import { useQuery } from 'react-query';
import { flattenInfiniteQueryResult } from '../../utils/helpers/react-query.helper';
import Page from '../../components/pdf/Page';
import TransactionDetailsReport from '../../components/pdf/Transaction/TransactionDetailsReport';
import { isTokenCurrency } from '../../utils/helpers/currency';
import EntityDetailsReport from '../../components/pdf/Transaction/EntityDetailsReport';
import InternalTransactionsReport from '../../components/pdf/Transaction/InternalTransactionsReport';
import TokenTransfersReport from '../../components/pdf/Transaction/TokenTransfersReport';
import FlowAnalysis from '../../components/pdf/Address/FlowAnalysis';
import CommentsReport from '../../components/pdf/CommentsReport';
import CounterpartySummaryReport from '../../components/pdf/Address/CounterpartySummaryReport';
import AlertsReport from '../../components/pdf/Alerts/AlertsReport';
import ClassifiersReport from '../../components/pdf/ClassifiersReport';

const TransactionReport: FC = () => {
  const { getParams, getQueryParams } = useRouter();
  const transactionId = Number(getParams().identifier || getParams().transactionId);
  const currency = Number(getQueryParams().currency);
  const entities = getQueryParams().entities?.split(',');
  const noEntity = !entities || entities.length === 0;

  const shouldShowSection = (entityName: string): boolean => {
    return noEntity || entities.includes(entityName);
  };

  const { data: transactionData } = useTransactionGetTransaction(transactionId);
  const isRipple = currency === 4;

  const isToken = isTokenCurrency(currency);

  const externalTransactionQuery = useQuery(
    ['transactionApi.getExternalTransaction', transactionId],
    () => transactionApi.getExternalTransaction({ id: transactionId }),
    {
      enabled: (isRipple || isTokenCurrency(currency)) && shouldShowSection('transactionDetails'),
    }
  );
  const externalTransactions = externalTransactionQuery?.data;
  const { data: tokenTransfers } = useQuery(
    ['transactionApi.getTokenTransfers', transactionId],
    () => transactionApi.getTokenTransfers({ id: transactionId }),
    {
      enabled: isToken && shouldShowSection('transactionDetails'),
    }
  );
  const { data: internalTransactions } = useQuery(
    ['transactionApi.getInternalTransactions', transactionId],
    () => transactionApi.getInternalTransactions({ id: transactionId }),
    {
      enabled: isToken && shouldShowSection('transactionDetails'),
    }
  );
  const { data: transactionInputs } = useQuery(
    ['transactionApi.getTransactionInputs', transactionId],
    () => transactionApi.getInputs({ id: transactionId, limit: 100 }),
    {
      enabled: !isToken && shouldShowSection('transactionDetails'),
    }
  );

  const { data: transactionOutputs } = useQuery(
    ['transactionApi.getTransactionOutputs', transactionId],
    () => transactionApi.getOutputs({ id: transactionId, limit: 100 }),
    {
      enabled: !isToken && shouldShowSection('transactionDetails'),
    }
  );

  const { data: aggregatedInputs } = useTransactionGetAggregatedInputs(transactionId, {
    enabled: shouldShowSection('flowAnalysis') || shouldShowSection('counterpartySummary'),
  });
  const { data: aggregatedOutputs } = useTransactionGetAggregatedInputs(transactionId, {
    enabled: shouldShowSection('flowAnalysis') || shouldShowSection('counterpartySummary'),
  });
  const { data: openAlertsData } = useAlertGetAlerts(
    { id: transactionId, type: 'transaction', status: [0, 2] },
    {
      enabled: shouldShowSection('openAlerts'),
    }
  );
  const { data: resolvedAlertsData } = useAlertGetAlerts(
    {
      id: transactionId,
      type: 'transaction',
      status: [1, 3],
    },
    {
      enabled: shouldShowSection('resolvedAlerts'),
    }
  );
  const { data: commentsData } = useCommentGetComments(
    { id: transactionId, type: 'transaction' },
    {
      enabled: shouldShowSection('transactionComments'),
    }
  );
  const { data: classifiers } = useQuery(['classifierApi.getClassifiers'], () =>
    classifierApi.getClassifiers({ limit: 100, rule_type: 0 })
  );

  const [, openAlerts] = flattenInfiniteQueryResult(openAlertsData);
  const [, resolvedAlerts] = flattenInfiniteQueryResult(resolvedAlertsData);
  const [, comments] = flattenInfiniteQueryResult(commentsData);

  const showCurrencyTransaction = (): boolean => {
    const hasTransactionDetails = shouldShowSection('transactionDetails');
    const hasEthExternalTransactions =
      externalTransactions?.data?.input?.address || externalTransactions?.data?.output?.address;
    const hasNonTokenInputs = transactionInputs?.data?.results?.length;
    const hasNonTokenOutputs = transactionOutputs?.data?.results?.length;

    if (noEntity || hasTransactionDetails) {
      return !!(hasEthExternalTransactions || hasNonTokenInputs || hasNonTokenOutputs);
    }

    return false;
  };

  const TransactionFooter: FC = () => {
    return (
      <div className='text-3xs text-gray-500'>Transaction Report: {transactionData?.data?.identifier}</div>
    );
  };

  const isCounterpartyVisible = (): boolean => {
    return (
      (aggregatedInputs?.data?.counter_party_summary?.length &&
        aggregatedInputs?.data?.counter_party_summary.length > 0) ||
      (aggregatedOutputs?.data?.counter_party_summary &&
        aggregatedOutputs?.data?.counter_party_summary.length > 0)
    );
  };
  let part = 1;

  return (
    <div>
      {transactionData?.data && shouldShowSection('transactionDetails') && (
        <Page footer={<TransactionFooter />}>
          <TransactionDetailsReport transactionData={transactionData.data} part={part++} />
        </Page>
      )}
      {showCurrencyTransaction() && (
        <Page footer={<TransactionFooter />}>
          <EntityDetailsReport
            externalTransactions={externalTransactions?.data}
            nonTokenInputs={transactionInputs?.data?.results}
            nonTokenOutputs={transactionOutputs?.data?.results}
            transaction={transactionData?.data}
            part={part++}
          />
        </Page>
      )}
      {internalTransactions?.data?.results?.length > 0 && (
        <Page footer={<TransactionFooter />}>
          <InternalTransactionsReport
            internalTransactions={internalTransactions?.data?.results}
            transaction={transactionData?.data}
            part={part++}
          />
        </Page>
      )}
      {tokenTransfers?.data?.results?.length > 0 && (
        <Page footer={<TransactionFooter />}>
          <TokenTransfersReport
            tokenTransfers={tokenTransfers?.data?.results}
            transaction={transactionData?.data}
            part={part++}
          />
        </Page>
      )}
      {aggregatedInputs?.data?.counter_party_summary?.length &&
        aggregatedOutputs?.data?.counter_party_summary?.length && (
          <Page footer={<TransactionFooter />}>
            <FlowAnalysis
              inputs={aggregatedInputs?.data?.counter_party_summary}
              outputs={aggregatedOutputs?.data?.counter_party_summary}
              part={part++}
            />
          </Page>
        )}
      {isCounterpartyVisible() && (
        <Page footer={<TransactionFooter />}>
          <CounterpartySummaryReport
            incoming={aggregatedInputs?.data?.counter_party_summary}
            outgoing={aggregatedOutputs?.data?.counter_party_summary}
            part={part++}
          />
        </Page>
      )}
      {openAlerts?.length > 0 && (
        <Page footer={<TransactionFooter />}>
          <AlertsReport
            type='transaction'
            alerts={openAlerts}
            title='Open Alerts'
            identifier={transactionData?.data?.identifier}
            part={part++}
          />
        </Page>
      )}
      {resolvedAlerts?.length > 0 && (
        <Page footer={<TransactionFooter />}>
          <AlertsReport
            type='transaction'
            alerts={resolvedAlerts}
            title='Resolved Alerts'
            identifier={transactionData?.data?.identifier}
            part={part++}
          />
        </Page>
      )}
      {comments?.length > 0 && (
        <Page footer={<TransactionFooter />}>
          <CommentsReport
            type='transaction'
            comments={comments}
            identifier={transactionData?.data?.identifier}
            part={part++}
          />
        </Page>
      )}
      {classifiers?.data.results && (
        <Page footer={<TransactionFooter />}>
          <ClassifiersReport classifiers={classifiers?.data.results} type='transaction' />
        </Page>
      )}
    </div>
  );
};

export default TransactionReport;
