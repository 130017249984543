import classNames from 'classnames';
import React, { FC } from 'react';
import ReactDOMServer from 'react-dom/server';
import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip';

interface ITooltipProps {
  label: React.ReactNode;
  contentTitle?: string;
  content?: string;
  className?: string;
  id?: string;
  place?: PlacesType;
  children?: React.ReactNode;
  variant?: 'dark' | 'light';
}

const Tooltip: FC<ITooltipProps> = (props) => {
  const { label, contentTitle, content, id, className, place, children, variant } = props;
  return (
    <span className='mx-1 normal-case'>
      <span
        className={classNames(
          'pl-auto m-px my-auto inline-flex h-3.5 w-3.5 justify-center rounded-full text-center font-mono text-3xs font-semibold',
          className
        )}
        data-tooltip-id={`${id}-tooltip`}
        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
          children ? (
            <>{children}</>
          ) : (
            <div className='w-52'>
              {contentTitle && <strong>{contentTitle}</strong>}
              <p>{content}</p>
            </div>
          )
        )}>
        {label}
      </span>
      <ReactTooltip
        variant={variant || 'dark'}
        place={place || 'right'}
        id={`${id}-tooltip`}
        className='z-10 shadow'
      />
    </span>
  );
};

export default Tooltip;
