import classNames from 'classnames';
import { BasicBadge, RiskBadge } from '../../../ui/components/Badges';
import Popover from '../../../ui/components/Popover/Popover';
import { IFilesStats, IUploadFilesResponse } from '../../../api/dtos/upload';
import { Checks, DownloadSimple, Hourglass, SpinnerGap, Warning, XCircle } from '@phosphor-icons/react';
import { formatOnlyDate } from '../../../utils/helpers/date';
import { Button } from '../../../ui/components/Button';
import { useAuth } from '../../../modules/auth';
import { toast } from '../../../ui/components/Toast';
import { useRef } from 'react';
import { useMutation } from 'react-query';
import { uploadApi } from '../../../api/upload';
import saveBlobToFile from '../../../utils/helpers/saveBlobToFile';

interface IUploadHistoryData {
  files: IUploadFilesResponse[];
}

export const useGetUploadHistoryRowData = ({ files }: IUploadHistoryData) => {
  const { state } = useAuth();
  const toastId = useRef(null);

  const { mutate } = useMutation(uploadApi.exportUploadedFile, {
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Exported transactions CSV successfully',
        type: 'success',
        autoClose: 3000,
      });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'Transactions CSV export failed. Please try again later.',
        type: 'error',
        autoClose: 3000,
      });
    },
  });

  const onClickDownload = (fileId, type, name = '') => {
    toastId.current = toast.success('Your file is being prepared. Please wait for a few seconds', {
      autoClose: false,
    });
    mutate(
      { fileId: fileId, download_file_type: type },
      { onSuccess: (data) => saveBlobToFile(data.data, type === 'crypto' ? name : 'download.csv') }
    );
  };
  const getRiskBadges = (stats: IFilesStats[]) => {
    stats.sort((a, b) => b.risk_level - a.risk_level);

    const AssetsDisplayed = 1;
    return (
      <div className='flex items-center'>
        <div className='flex'>
          {stats &&
            stats
              .slice(0, AssetsDisplayed)
              .map((stat) => (
                <RiskBadge
                  key={1}
                  className='mx-0 my-2 rounded-[10rem] px-1 py-0 !text-2xs font-semibold'
                  risk={stat.risk_level}
                  label={stat.risk_level_verbose === 'No Risk Detected' ? 'No Risk' : stat.risk_level_verbose}
                  count={stat.count}
                />
              ))}
          {stats && stats.length - AssetsDisplayed > 0 && (
            <Popover
              placement='bottom'
              referenceContent={
                <BasicBadge className='mx-1 my-2 bg-gray-100 px-2.5 py-1'>{`+${
                  stats.length - AssetsDisplayed
                }`}</BasicBadge>
              }
              popoverContent={
                <div
                  className={classNames('flex max-w-sm flex-wrap rounded-md bg-white px-4 py-2 shadow-lg')}>
                  {stats.slice(AssetsDisplayed).map((stat) => (
                    <RiskBadge
                      key={1}
                      className='mx-1 my-2 rounded-[10rem] px-2.5 py-1 text-[11px] font-semibold'
                      risk={stat.risk_level}
                      label={
                        stat.risk_level_verbose === 'No Risk Detected' ? 'No Risk' : stat.risk_level_verbose
                      }
                      count={stat.count}
                    />
                  ))}
                </div>
              }
            />
          )}
        </div>
      </div>
    );
  };
  return files.map((fileItems: IUploadFilesResponse) => {
    return {
      id: fileItems.uuid,
      data: [
        <div
          key={fileItems.uuid}
          className='cursor-pointer leading-6 text-blue-500'
          onClick={() => onClickDownload(fileItems.uuid, 'crypto', fileItems.crypto_file_name)}>
          {fileItems.crypto_file_name}
        </div>,
        <div key={fileItems.uuid}>
          {fileItems.status === 0 && (
            <div className='mt-1 w-fit rounded-[10rem] bg-gray-200 px-2 py-1'>
              <Hourglass className='mx-auto' size={16} color='#6B7280' weight='light' />
            </div>
          )}
          {fileItems.status === 1 && (
            <div className='mt-1 w-fit rounded-[10rem] bg-blue-200 px-2 py-1'>
              <SpinnerGap className='mx-auto' size={10} color='#3B82F6' weight='light' />
            </div>
          )}
          {fileItems.status === 2 && (
            <div className='mt-1 w-fit rounded-[10rem] bg-yellow-200 px-2 py-1'>
              <Warning className='mx-auto' size={10} color='#F59E0B' weight='light' />
            </div>
          )}
          {fileItems.status === 3 && (
            <div className='mt-1 w-fit rounded-[10rem] bg-red-200 px-2 py-1'>
              <XCircle className='mx-auto' size={10} color='#EF4444' weight='light' />
            </div>
          )}
          {fileItems.status === 4 && (
            <div className='mt-1 w-fit rounded-[10rem] bg-green-200 px-2 py-1'>
              <Checks className='mx-auto' size={10} color='#065F46' weight='light' />
            </div>
          )}
        </div>,
        <div key={fileItems.uuid}>
          {fileItems.status === 4 ? (
            <>
              <p className='my-1 text-2xs font-normal'>Succeeded: {fileItems.successful_row_count}</p>
              <p className='my-1 text-2xs font-normal'>Failed: {fileItems.failed_row_count}</p>
            </>
          ) : (
            <p className='my-1 text-2xs font-normal'>
              {fileItems.successful_row_count + fileItems.failed_row_count}/{fileItems.total_row_count}
            </p>
          )}
        </div>,
        getRiskBadges(fileItems.stats),
        <div key={fileItems.uuid} className='mt-3'>
          {fileItems.created_at ? formatOnlyDate(fileItems.created_at, state.userProfile.timezone) : ''}
        </div>,
        <>
          {(fileItems.status === 4 || fileItems.status === 2) && (
            <Button
              key={fileItems.uuid}
              variant='tertiary'
              className='ml-2 mt-2 items-center bg-white px-2 py-1.5 hover:bg-gray-300'
              onClick={() => onClickDownload(fileItems.uuid, 'response')}>
              <DownloadSimple size={12} />
            </Button>
          )}
        </>,
      ],
    };
  });
};
