import { FC, useState } from 'react';
import { BasicModal, Button, Textarea } from '../../../../ui';
import { useMutation, useQueryClient } from 'react-query';
import { alertApi } from '../../../../api/alert';
import { toast } from 'react-toastify';

interface ResolveMultipleAlertsModalProps {
  open: boolean;
  onClose: () => void;
  type: 'address' | 'transaction' | 'customer' | 'case';
  ids: number[];
}

const ResolveMultipleAlertsModal: FC<ResolveMultipleAlertsModalProps> = (props) => {
  const { open, onClose, type, ids } = props;
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(alertApi.updateAlerts, {
    onSuccess: () => {
      toast.success('Alerts resolved');
      if (ids.length === 1 && type === 'case') {
        queryClient.invalidateQueries(['caseApi', ids[0]]);
      }
      onClose();
    },
  });

  const [comment, setComment] = useState('');

  const onSubmit = () => {
    mutate({
      ids,
      status: 1,
      type,
      comment,
    });
  };

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      size='sm'
      modalTitle='Resolve open alerts'
      modalBody={
        <>
          <div className='mx-4 mb-4 text-sm font-medium'>Add Comment (optional)</div>
          <Textarea
            id='resolve-comment'
            value={comment}
            className='mx-4'
            onChange={setComment}
            placeholder='Add your comment here...'
          />
          <div className='mt-3 flex flex-row rounded-lg border-t bg-gray-50'>
            <Button
              variant='primary'
              className='m-2 ml-auto p-1 px-2 text-sm'
              disabled={isLoading}
              onClick={onSubmit}>
              Resolve
            </Button>
          </div>
        </>
      }
      customBodyClass='pt-6'
    />
  );
};

export default ResolveMultipleAlertsModal;
