import { Api } from './api';
import { useQuery } from 'react-query';
import { IStageListLiteResponse, IStageListResponse } from './dtos/stages';

export class StagesApi extends Api {
  readonly baseUrl: string = '/profile/api/v2/';

  readonly getStageListLite = () => {
    return this.http.get<IStageListLiteResponse>(this.route('workflow-stages/lite/'));
  };

  readonly getStageList = () => {
    return this.http.get<IStageListResponse>(this.route('workflow-stages/'));
  };
}

export const stagesApi = new StagesApi();

export const useStagesListLiteGet = () => {
  const key = 'StagesApi.getStageListLite';
  const result = useQuery(key, () => stagesApi.getStageListLite());

  return {
    key,
    ...result,
  };
};

export const useStagesListGet = () => {
  const key = 'StagesApi.getStageList';
  const result = useQuery(key, () => stagesApi.getStageList());

  return {
    key,
    ...result,
  };
};
