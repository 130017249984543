import { FC } from 'react';
import BasicBadge from '../../../../ui/components/Badges/BasicBadge/BasicBadge';

export interface AlertStatusBadgeProps {
  type: number;
  className?: string;
  label?: string;
  hideStatusLabel?: boolean;
}

export const alertData = {
  0: {
    className: 'bg-orange-500 text-white',
    label: 'Open',
  },
  1: {
    className: 'bg-gray-200 text-gray-800',
    label: 'Resolved',
  },
  2: {
    className: 'bg-orange-200 text-gray-800',
    label: 'Escalated',
  },
  // Inactive
  3: {
    className: 'bg-gray-200',
    label: 'Inactive',
  },
};

const AlertStatusBadge: FC<AlertStatusBadgeProps> = ({ type, className, label, hideStatusLabel }) => {
  return (
    <BasicBadge className={`${alertData[type].className} ${className} px-2 py-px text-2xs leading-4`}>
      <div className='mx-1'>{label || (!hideStatusLabel ? 'Status: ' : '') + alertData[type].label}</div>
    </BasicBadge>
  );
};

export default AlertStatusBadge;
