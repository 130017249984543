import { useState } from 'react';
import { customerApi, useCustomerGetDeposits, useCustomerGetWithdrawals } from '../../../api/customer';
import Table from '../../../ui/components/Table/Table';
import IncomingOutgoingSwitch from '../../ui/components/Table/IncomingOutgoingSwitch';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import IdentifierEllipsis from '../../ui/components/Currency/IdentifierEllipsis';
import DigitalAssets from '../../ui/components/Currency/DigitalAssets';
import { formatDateTime } from '../../../utils/helpers/date';
import RisksDetected from '../../common/RisksDetected';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import AddDepositWithdrawal from './AddDepositWithdrawal';
import { ICustomerResponse } from '../../../api/dtos/customer';
import CustomerTransactionsFilter, { IFilters, defaultStateFilters } from './CustomerTransactionsFilter';
import CustomerTransactionsAppliedFilters from './CustomerTransactionsAppliedFilters';
import CurrenciesButtons from './CurrenciesButtons';
import { CaretUp, CaretDown } from '@phosphor-icons/react';
import OriginatorBeneficiary from '../../common/OriginatorBeneficiary';
import TransactionValue from '../../Transaction/TransactionValue';
import { useRouter } from '../../../modules/router/RouterProvider';
import { useAuth } from '../../../modules/auth';

interface ICustomerTransactionsProps {
  customerId: string;
  customer: ICustomerResponse;
}

const CustomerTransactions: React.FC<ICustomerTransactionsProps> = ({ customerId, customer }) => {
  const [transactionType, setTransactionType] = useState<'incoming' | 'outgoing'>('incoming');
  const [filters, setFilters] = useState(defaultStateFilters as IFilters);
  const [currency, setCurrency] = useState<number>(null);
  const [dateOrdering, setDateOrdering] = useState<'transaction_time' | '-transaction_time'>(
    '-transaction_time'
  );
  const { navigate } = useRouter();
  const { state } = useAuth();
  const queryParams = {
    customerId,
    currency,
    ordering: dateOrdering,
    ...filters,
  };
  const depositsQuery = useCustomerGetDeposits(queryParams);

  const withdrawalsQuery = useCustomerGetWithdrawals(queryParams);

  const transactionQuery = transactionType === 'incoming' ? depositsQuery : withdrawalsQuery;

  const [, transactions] = flattenInfiniteQueryResult(transactionQuery.data);

  const onSwitchChange = (value: 'incoming' | 'outgoing') => {
    window.scrollTo(0, 0);
    setTransactionType(value);
  };

  const headerData = [
    'Transaction',
    'Deposit Address',
    'Digital Assets',
    <div
      className='inline-flex cursor-pointer'
      key='date'
      onClick={() =>
        setDateOrdering(dateOrdering === 'transaction_time' ? '-transaction_time' : 'transaction_time')
      }>
      Date{' '}
      {dateOrdering === 'transaction_time' ? (
        <CaretUp weight='bold' className='ml-2 text-gray-900' />
      ) : (
        <CaretDown weight='bold' className='ml-2 text-gray-900' />
      )}
    </div>,
    'Entities Detected',
    'Risk Level',
    'Volume',
    'Value (in US$)',
  ];

  const rows = transactions.map((transaction) => {
    const key = transaction.id + transaction.address;
    // let entities = transactionType === 'incoming' ? transaction.from_addresses : transaction.to_addresses;
    // entities = entities.filter((e) => e.exposure_type !== 'indirect');
    return {
      id: transaction.id,
      currency: transaction.currency,
      data: [
        <IdentifierEllipsis key={key} identifier={transaction.identifier} clickable copyable />,
        <IdentifierEllipsis
          key={key}
          type='addresses'
          identifier={transaction.address}
          currency={transaction.currency}
          customerId={transaction.customer_id}
          customerType={transactionType === 'incoming' ? 1 : 2}
          isCreateCaseDisabled
          clickable
          copyable
        />,
        <DigitalAssets key={key} digitalAssets={transaction.digital_assets} />,
        formatDateTime(transaction.transaction_time, state?.userProfile?.timezone),
        <OriginatorBeneficiary
          key={key}
          transaction={transaction}
          isInCustomerDetails
          type={transactionType === 'incoming' ? 'originator' : 'beneficiary'}
        />,
        <RisksDetected key={key} risk={transaction.risk_level} risksDetected={transaction.risks_detected} />,
        <TransactionValue key={key} transaction={transaction} type='value' />,
        <TransactionValue key={key} transaction={transaction} type='value_usd' />,
      ],
    };
  });

  const { mutate } = useMutation(customerApi.exportDepositWithdrawal, {
    onSuccess: (res) => {
      toast.success(res.data.message);
    },
  });
  const onExport = () => {
    mutate({ customerId, type: transactionType === 'incoming' ? 'deposits' : 'withdrawals' });
  };

  const onClickRow = (id: string) => {
    navigate(`/transactions/${id}`);
  };

  return (
    <div>
      <CurrenciesButtons
        currency={currency}
        setCurrency={setCurrency}
        digitalAssets={customer.digital_assets}
      />
      <Table
        title={transactionType === 'incoming' ? 'Deposits' : 'Withdrawals'}
        rows={rows}
        headerData={headerData}
        headerActionsLeft={<IncomingOutgoingSwitch onChange={onSwitchChange} />}
        border
        onExport={onExport}
        appliedFilters={<CustomerTransactionsAppliedFilters filters={filters} setFilters={setFilters} />}
        onClickRow={onClickRow}
        headerActions={
          <>
            <CustomerTransactionsFilter filters={filters} onApply={setFilters} />
            {!state.userProfile?.skip_alert_creation_modes?.includes(1) && (
              <AddDepositWithdrawal
                customerId={customerId}
                type={transactionType === 'incoming' ? 'deposit' : 'withdrawal'}
                addresses={customer?.customer_crypto_addresses}
              />
            )}
          </>
        }
        {...buildInfiniteQueryTableProps(transactionQuery)}
      />
    </div>
  );
};

export default CustomerTransactions;
