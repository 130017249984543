import React, { FC } from 'react';
import { useWorkSpaceGetWorkFlowMembers } from '../../api/workspaces';
import SingleSelect, { IOption } from '../ui/components/Select/SingleSelect';
import UpdateStageorAssigneeModal from './UpdateStageorAssigneeModal';
import { IWorkSpaceMembers } from '../../api/dtos/workspaces';
import { useMutation, useQueryClient } from 'react-query';
import { workflowsApi } from '../../api/workflows';
import { toast } from 'react-toastify';
import { commentApi } from '../../api/comment';
import { IAutoAssignWorkflowStageResponse } from 'src/api/dtos/settings/workflowSettings';
import { Stage } from '../../ui/utils/constants';
import StageBadge from '../../ui/components/Badges/StageBadge/StageBadge';
import UserBadge from '../../ui/components/Badges/UserBadge/UserBadge';
import { UserCircle } from '@phosphor-icons/react';
import { useStagesListLiteGet } from '../../api/stages';
import { getColorMappingStageOptions } from '../../utils/helpers/stageOptionsColor';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../utils/helpers/react-query.helper';
import { useDebounce } from 'use-debounce';
import { AxiosError } from 'axios';
import { getErrorMessage } from '../../utils/helpers/helperFunctions';

interface StageAndAssignedProps {
  type: string;
  id: number | string;
  assigned_to_name: string;
  stage_name: string;
  stage_id: Stage;
  next_stages?: IAutoAssignWorkflowStageResponse[];
  is_final_stage?: boolean;
}

const StageAndAssignedWorkSpace: FC<StageAndAssignedProps> = ({
  type,
  id,
  assigned_to_name,
  stage_name,
  next_stages,
  stage_id,
  is_final_stage,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const queryClient = useQueryClient();
  interface IStageOptions extends IOption {
    id?: Stage;
  }
  const stage = stage_id
    ? {
        id: stage_id,
        label: stage_name,
        value: stage_name,
      }
    : null;
  const assigned_to = assigned_to_name
    ? {
        label: assigned_to_name,
        value: assigned_to_name,
      }
    : null;
  const [selectedOption, setSelectedOption] = React.useState<IOption>();
  const [selectedOptionId, setSelectedOptionId] = React.useState<number>();
  const [isStageorAssignee, setIsStageorAssignee] = React.useState<boolean>(true);
  const [comment, setComment] = React.useState('');
  const [search, setSearch] = React.useState<string>('');
  const [debouncedSearch] = useDebounce(search, 500);
  const assignedToOptionsQuery = useWorkSpaceGetWorkFlowMembers({
    q: debouncedSearch,
    is_active: true,
  });
  const [, assignedToOptions]: [number, IWorkSpaceMembers[]] = flattenInfiniteQueryResult(
    assignedToOptionsQuery?.data
  );

  const getStageOptions = (stageData: IAutoAssignWorkflowStageResponse[]) => {
    return stageData?.map((r) => {
      return {
        id: r.id,
        value: r.name,
        label: r.name,
      };
    });
  };

  const getAssignedToOptions = (assignedToData: IWorkSpaceMembers[]) => {
    return assignedToData?.map((r) => {
      return {
        value: r.email,
        label: r.email,
      };
    });
  };

  const stageOptionsData = getStageOptions(next_stages);
  const assignedToOptionsData = getAssignedToOptions(assignedToOptions);

  const handleChangeStage = (stage: IOption) => {
    setSelectedOption(stage);
    const foundStage = next_stages.find((r) => r.name === stage.label);
    setSelectedOptionId(foundStage.id);
    setOpen(true);
    setIsStageorAssignee(true);
  };
  const handleChangeAssignee = (assignee: IOption) => {
    setSelectedOption(assignee);
    const foundAssignee = assignedToOptions.find((r) => r.email === assignee.label);
    setSelectedOptionId(foundAssignee.user_id);
    setOpen(true);
    setIsStageorAssignee(false);
  };

  const { mutate: updateStageorAssignee, isLoading: isLoadingUpdateStageorAssignee } = useMutation(
    workflowsApi.updateStageorAssignee,
    {
      onSuccess: () => {
        toast.success(`${isStageorAssignee ? 'Stage' : 'Assignee'} has been updated`);
        setComment('');

        if (type === 'addresses') {
          queryClient.invalidateQueries(['addressApi.getAddress', id]);
        } else if (type === 'transactions') {
          queryClient.invalidateQueries(['transactionApi.getTransaction', id]);
        } else if (type === 'customers') {
          queryClient.invalidateQueries(['customerApi', id, 'customerApi.getCustomer']);
        }
        setOpen(false);
      },
      onError: (d: AxiosError) => {
        if (isStageorAssignee) {
          toast.error(getErrorMessage(d));
        } else {
          toast.error(`Unable to update ${isStageorAssignee ? 'Stage' : 'Assignee'}`);
        }
      },
    }
  );

  const { mutate: addComment, isLoading: isLoadingAddComment } = useMutation(commentApi.addComment, {
    onSuccess: () => {
      setComment('');
    },
    onError: () => {
      toast.error('Unable to add the comment');
    },
  });

  const handleSubmit = () => {
    if (comment) {
      addComment({
        id: id,
        type: type === 'addresses' ? 'address' : type === 'transactions' ? 'transaction' : 'customer',
        body: comment,
      });
      updateStageorAssignee({
        type,
        id,
        payload: isStageorAssignee ? { workflow_stage: selectedOptionId } : { assigned_to: selectedOptionId },
      });
    } else {
      toast.error('Enter a comment');
    }
  };

  const stagesListLiteQuery = useStagesListLiteGet();

  const stageColorMap = getColorMappingStageOptions(stagesListLiteQuery?.data?.data?.results);

  const getCustomOptionLabel = (option: IStageOptions) => {
    if (stage === null) {
      return <StageBadge role={7} label='-NA-' />;
    }
    return <StageBadge role={stageColorMap[option?.id]} label={option?.label} />;
  };

  const getCustomOptionLabelAssignee = (option: IOption) => {
    return (
      <UserBadge
        label={option?.label}
        className='bg-blue-100 text-blue-800'
        iconStart={<UserCircle size={17} weight='fill' />}
      />
    );
  };

  const getCustomLabelAssignee = (option: IOption) => {
    return <UserBadge className='bg-blue-100 text-blue-800' label={option?.label} />;
  };

  const customFilterOptions = () => {
    return true;
  };

  return (
    <div className='flex gap-8 rounded-md border border-blue-200 bg-blue-50 p-4'>
      <div className='flex w-1/2 items-center gap-4'>
        <p className='mt-1 text-xs uppercase leading-3 text-gray-500'>Stage</p>
        {stage === null || is_final_stage ? (
          <div className='mt-1 w-full rounded-md border border-gray-200 bg-gray-50 p-2'>
            {getCustomOptionLabel(stage)}
          </div>
        ) : (
          <SingleSelect
            placeholder='Select Stage'
            options={stageOptionsData}
            handleChange={handleChangeStage}
            value={stage}
            isCloseHidden={true}
            showRadioButton={true}
            parentClassName='grow'
            getCustomLabel={getCustomOptionLabel}
            getCustomOptionLabel={getCustomOptionLabel}
          />
        )}
      </div>
      <div className='flex w-1/2 items-center gap-4'>
        <p className='mt-1 whitespace-nowrap text-xs uppercase leading-3 text-gray-500'>Assigned To</p>
        {is_final_stage ? (
          <div className='mt-1 w-full rounded-md border border-gray-200 bg-gray-50 p-2'>
            {getCustomOptionLabelAssignee(assigned_to)}
          </div>
        ) : (
          <SingleSelect
            placeholder='Select Assignee'
            options={assignedToOptionsData}
            handleChange={handleChangeAssignee}
            isLoading={assignedToOptionsQuery.isLoading}
            value={assigned_to}
            isCloseHidden={true}
            parentClassName='grow'
            isSearchable={true}
            getCustomLabel={getCustomOptionLabelAssignee}
            {...buildInfiniteQueryTableProps(assignedToOptionsQuery)}
            setSearch={setSearch}
            filterOptions={customFilterOptions}
            asyncPaginate={true}
          />
        )}
      </div>
      <UpdateStageorAssigneeModal
        open={open}
        setOpen={setOpen}
        optionsData={isStageorAssignee ? stageOptionsData : assignedToOptionsData}
        currentOption={isStageorAssignee ? stage : assigned_to}
        selectedOption={selectedOption}
        setSelectedOption={isStageorAssignee ? handleChangeStage : handleChangeAssignee}
        handleSubmit={handleSubmit}
        id={id}
        setComment={setComment}
        isStageorAssignee={isStageorAssignee}
        comment={comment}
        getCustomOptionLabel={isStageorAssignee ? getCustomOptionLabel : getCustomLabelAssignee}
        setSearch={setSearch}
        filterOptions={customFilterOptions}
        isLoading={isLoadingUpdateStageorAssignee || isLoadingAddComment}
        assignedToOptionsQuery={assignedToOptionsQuery}
      />
    </div>
  );
};

export default StageAndAssignedWorkSpace;
