import { Chats } from '@phosphor-icons/react';
import { FC } from 'react';
import { IAlert, IAlertItem } from '../../../../../api/dtos/alert';
import AuditTrail from '../../../AuditTrail/AuditTrail';
// import { useParams } from 'react-router-dom';

interface IAlertCommentButtonProps {
  alert: IAlert;
  alertItem?: IAlertItem;
  type: 'address' | 'transaction' | 'customer';
  id: string | number;
}

const AlertCommentButton: FC<IAlertCommentButtonProps> = ({ alert, alertItem, type, id }) => {
  const commentsCount = alertItem ? alertItem.total_comments : alert.total_comments;
  return (
    <AuditTrail
      id={id}
      type={type}
      alert={alert}
      alertItem={alertItem}
      buttonIcon={
        <>
          <Chats className='mr-1' />
          {commentsCount}
        </>
      }
    />
  );
};

export default AlertCommentButton;
