import { FC, useMemo, useState } from 'react';
import { ILevels } from '../../api/dtos/dashboard';
import DashboardWidget from './DashboardWidget';
import { Currencies } from '../../utils/constants/constants';
import CurrencyBadge from '../ui/components/Badge/CurrencyBadge';
import { Dropdown, DropdownOption } from '../../ui';
import classNames from 'classnames';

interface ICurrencyDistributionWidgetProps {
  addressData: ILevels;
  transactionData: ILevels;
  isLoading?: boolean;
  isPdf?: boolean;
}

const CurrencyDistributionWidget: FC<ICurrencyDistributionWidgetProps> = ({
  addressData,
  transactionData,
  isLoading,
  isPdf,
}) => {
  const options: DropdownOption[] = [
    {
      label: 'All screenings',
      value: 'all',
    },
    {
      label: 'Transactions',
      value: 'transactions',
    },
    {
      label: 'Addresses',
      value: 'addresses',
    },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleDropdownChange = (option: DropdownOption) => {
    setSelectedOption(option);
  };

  const currencyDataArray = useMemo(() => {
    return Currencies.map((currency) => {
      let count = 0;

      if (selectedOption.value === 'transactions') {
        count = transactionData?.[currency.value] ?? 0;
      } else if (selectedOption.value === 'addresses') {
        count = addressData?.[currency.value] ?? 0;
      } else {
        count = (transactionData?.[currency.value] ?? 0) + (addressData?.[currency.value] ?? 0);
      }
      return { ...currency, count };
    }).sort((a, b) => b.count - a.count);
  }, [selectedOption, transactionData, addressData]);

  const totalCount = currencyDataArray.reduce((sum, row) => sum + row.count, 0);

  const noData = !(addressData || transactionData);

  return (
    <DashboardWidget
      title={'SCREENING BY CURRENCY'}
      subtitle='In Selected Period'
      containerClassName={!isPdf ? 'col-span-4' : 'col-span-12'}
      widgetHeader={
        <Dropdown
          widthClass='w-[115px]'
          options={options}
          value={selectedOption}
          onChange={handleDropdownChange}
        />
      }
      isLoading={isLoading}
      noData={noData}>
      <div className=''>
        <div className='flex border-b pb-4 text-sm uppercase tracking-wider'>
          <div className='w-1/2'>Currency</div>
          <div className=''>Screened ({totalCount})</div>
        </div>
        <div className={classNames('overflow-auto', !isPdf ? 'max-h-[330px]' : 'max-h-[535px]')}>
          {currencyDataArray.map((c) => (
            <div className='flex border-b py-2 text-sm' key={c.value}>
              <CurrencyBadge
                currency={c.value}
                label={c.currency_short}
                className='w-1/2 font-semibold tracking-wider'
                transparent
              />
              <div className='ml-2'>{c.count.toLocaleString()}</div>
            </div>
          ))}
        </div>
      </div>
    </DashboardWidget>
  );
};

export default CurrencyDistributionWidget;
