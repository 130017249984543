import { FC } from 'react';
import ResolveAlertButton from './ResolveAlertButton';
import { IAlert, IAlertItem } from '../../../../../api/dtos/alert';
import EscalateAlertButton from './EscalateAlertButton';
import AlertCommentButton from './AlertCommentsButton';
import ReopenAlertButton from './ReopenAlertButton';

interface IAlertButtonPanelProps {
  alert: IAlert;
  alertItem?: IAlertItem;
  type: 'address' | 'transaction' | 'customer';
  id: string | number;
  isStale?: boolean;
}

const AlertButtonPanel: FC<IAlertButtonPanelProps> = ({ alert, alertItem, type, id, isStale }) => {
  const currentAlert = alertItem || alert;
  const isOpenAlertsTab = currentAlert.status === 0 || currentAlert.status === 2;
  return (
    <div className='mb-auto flex'>
      {!isStale && (
        <>
          {isOpenAlertsTab ? (
            <>
              {currentAlert.status !== 1 && (
                <ResolveAlertButton id={id} alert={alert} alertItem={alertItem} type={type} />
              )}

              {currentAlert.status !== 2 && (
                <EscalateAlertButton id={id} alert={alert} alertItem={alertItem} type={type} />
              )}
            </>
          ) : (
            <ReopenAlertButton id={id} alert={alert} type={type} />
          )}

          {alertItem && alertItem.status === 1 && <ReopenAlertButton id={id} alert={alert} type={type} />}
        </>
      )}

      <AlertCommentButton id={id} alert={alert} alertItem={alertItem} type={type} />
    </div>
  );
};

export default AlertButtonPanel;
