import { FC } from 'react';
import AppliedFilters, { BasicAppliedFilter } from '../../../AppliedFilters';
import { defaultStateFilters } from './AlertFilter';
import RiskAppliedFilter from '../../../AppliedFilters/RiskAppliedFilter';
import { IAlertFilters } from '../../../../api/dtos/alert';
import { every, isEmpty } from 'lodash';
import DateAppliedFilter from '../../../AppliedFilters/DateAppliedFilter';
import RiskTypeAppliedFilter from '../../../AppliedFilters/RiskTypeAppliedFilter';

interface AlertAppliedFiltersProps {
  filters: IAlertFilters;
  setFilters: (filters: IAlertFilters) => void;
}

const AlertAppliedFilters: FC<AlertAppliedFiltersProps> = (props) => {
  const { filters, setFilters } = props;
  if (every(filters, (v) => isEmpty(v))) return null;
  return (
    <AppliedFilters
      onResetAll={() => {
        setFilters(defaultStateFilters);
      }}
      className='-mx-4 -mb-2 mt-1 border-t border-gray-200 pt-2'>
      <RiskAppliedFilter risks={filters.level} setRisks={(r) => setFilters({ ...filters, level: r })} />
      <BasicAppliedFilter
        label='Rule Name'
        value={filters.rule_name}
        setValue={(v) => {
          setFilters({ ...filters, rule_name: v });
        }}
      />
      <RiskTypeAppliedFilter
        riskTypes={filters.risk_type}
        setRiskTypes={(r) => setFilters({ ...filters, risk_type: r })}
      />
      <BasicAppliedFilter
        label='Exposure Type'
        value={filters.rule_condition_type}
        setValue={(v) => {
          setFilters({ ...filters, rule_condition_type: v });
        }}
      />
      <DateAppliedFilter
        label='Transaction Date'
        from={filters.transaction_start_date}
        to={filters.transaction_end_date}
        onRemove={() => setFilters({ ...filters, transaction_start_date: '', transaction_end_date: '' })}
      />
    </AppliedFilters>
  );
};

export default AlertAppliedFilters;
